interface TimeFormatedProps {
  total: number;
  unit: string;
  description: string;
}

export function formatTimeBySeconds(totalSecondsInput: number | string): TimeFormatedProps {

  const totalSeconds = Number(totalSecondsInput);

  const SECONDS_IN_MINUTE = 60;
  const SECONDS_IN_HOUR = 3600;
  const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24;

  //Less than 1 minute
  if (totalSeconds < SECONDS_IN_MINUTE) {
    return {
      total: totalSeconds,
      unit: 'segundos',
      description: 'menos de 1 minuto'
    }
  }

  //Less than 1 hour
  if (totalSeconds < SECONDS_IN_HOUR) {
    const minutes = Math.floor(totalSeconds / SECONDS_IN_MINUTE);

    if (minutes === 1) {
      return {
        total: minutes,
        unit: 'minuto',
        description: '1 minuto'
      }
    }

    return {
      total: minutes,
      unit: 'minutos',
      description: `${minutes} minutos`
    }
  }

  //Less than 1 day
  if (totalSeconds < SECONDS_IN_DAY) {
    const hours = Math.floor(totalSeconds / SECONDS_IN_HOUR);

    if (hours === 1) {
      return {
        total: hours,
        unit: 'hora',
        description: '1 hora'
      }
    }

    return {
      total: hours,
      unit: 'horas',
      description: `${hours} horas`
    }
  }

  const days = Math.floor(totalSeconds / SECONDS_IN_DAY);

  if (days === 1) {
    return {
      total: days,
      unit: 'dia',
      description: '1 dia'
    }
  }

  return {
    total: days,
    unit: 'dias',
    description: `${days} dias`
  }

}