import styled from "styled-components";

export const Container = styled.div`    
    display: flex;
    justify-content: flex-start;
    align-items: center;    
`;

export const UpgradeMessageContainer = styled.div`    
    width: calc(100% - 170px);

    h1 {
        font-size: 16px;
        color: #f23b5d;
    } 

    span {
        font-size: 12px;
        color: gray;
    }
`;

export const UpgradeButtonContainer = styled.div`    
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
`;

export const UpgradeButton = styled.a`
    width: auto;
    min-width: 150px;
    padding: 5px 10px;
    text-decoration: none;
    color: white;
    font-size: 13px;
    border: none;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    height: 28px;
    background: linear-gradient(90deg,rgb(255,140,47) 25%,rgb(251,50,92) 50%,rgb(147,55,237) 75%);
    display: flex;
    justify-content: center;
    align-items: center;
`;


export const UpgradeButton2 = styled.a` 
    padding: 5px 10px;
    text-decoration: none;
    color: black;
    font-size: 12px;
    border: none;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    height: 28px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 19px;
    width: 126px;
    border-radius: 9px;
`;

export const BackgroundGradient = styled.div`
    height: 23px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: linear-gradient(260deg,rgb(255,140,47) 25%,rgb(251,50,92) 50%,rgb(147,55,237) 75%);
`;