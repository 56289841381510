import React, { useEffect, useRef } from "react";

import ErrorDescription from "../../../../ErrorDescription";
import { MarginTop } from "./styles";
import SwitchBase from "@material-ui/core/Switch";
import { useField } from "@unform/core";

interface SwitchProps {
  name: string;
  checked?: boolean;
  onForceBlur?: () => void;
}

const Switch: React.FC<SwitchProps> = ({ name, checked, onForceBlur }) => {
  const inputRef = useRef<HTMLInputElement>();
  const { fieldName, registerField, defaultValue, error } = useField(name);

  if (checked === undefined) {
    checked = false;
  }

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        getValue: (ref) => ref.checked,
        setValue: (ref, value) => {
          ref.checked = Boolean(value);
        },
        clearValue: (ref) => {
          ref.checked = Boolean(false);
        }
      });
    }
  }, [fieldName, registerField]);

  return (
    <>
      <MarginTop>
        <SwitchBase
          name={name}
          defaultChecked={Boolean(defaultValue)}
          inputRef={inputRef}
          onChange={onForceBlur}
        />
      </MarginTop>
      {error && (<ErrorDescription title={error} />)}
    </>
  );
};

export default Switch;