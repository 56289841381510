import {
    ColumnContainer,
    ColumnDescription,
    ColumnsHeader,
    ColumnsHeaderIcon,
    ColumnsHeaderSpan,
    ColumnsHeaderTitle,
    ColumnsItems,
    Container,
    ContainerBody,
    ContainerContent,
    FlowContainer,
    FlowIcon,
    Animation,
    ContainerLoader,
    ColumnsHeaderRight,
    WidgetTasksResume,
    TaskLabel,
    TaskResumeItem,
    FilterBarTasks,
    FilterBarTasksLeft,
    FilterBarTasksButtonLink,
    BoxContainer,
    BoxItemsContainer,
    NoResults,
    BoxItem,
    BoxSpan,
    BoxMultiple,
    BoxSpanDetail,
    AvatarContainer,
    FilterBarTasksButtonClick,
    ButtonLink,
    SearchInput,
    Input,
    ContainerEmpty,
    ContainerInfo,
    ContainerIlustration,
} from "./style";
import { FaCheck, FaMailBulk } from "react-icons/fa";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import React, { useCallback, useEffect, useState, useRef } from "react";
import lottie from 'lottie-web';
import { Card } from "../../interfaces/Card";
import ContentLoader from "react-content-loader";
import LayoutContainer from '../../components/LayoutContainer';
import { Link, useRouteMatch } from "react-router-dom";
import ListContainer from '../../components/ListContainer';
import Task from "../../components/Kanban/Task";
import api from '../../services/api';
import getKanbanConfig from "../../utils/KanbanConfig/getKanbanConfig";
import { useToast } from '../../hooks/toast';
import CardDialog from "../../dialogs/Card";
import { KanbanConfigProps } from "../../components/Kanban";
import { Flow } from "../../interfaces/Flow";
import AppBarHeader, { NavigationBarItemType } from "../../components/AppBarHeader";
import { RiTableFill } from "react-icons/ri";
import FocusTab from "./FocusTab";
import { FiAtSign, FiBell, FiTarget } from "react-icons/fi";
import { TbCircleFilled } from "react-icons/tb";
import { MdOutlineFilterList } from "react-icons/md";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { ClickAwayListener } from "@material-ui/core";
import { useAuth } from '../../hooks/auth';
import { useHistory } from 'react-router-dom';
import NotificationTab from "./NotificationTab";
import { useMediaQuery } from "react-responsive";
import { AiOutlineSearch } from "react-icons/ai";
import { IoIosCloseCircle } from "react-icons/io";
import AvatarCange from "../../components/AvatarCange";
import myTasksIlustration from '../../assets/empty-state/my-tasks-illustration.png';

interface ColumnsProps {
    index: number;
    name: string;
    color: string;
    icon: string;
    description?: string;
    cards?: Card[];
}

interface FlowFilter extends Flow {
    selected?: boolean;
    totTasks?: number;
}

interface RouteParams {
    tab: string;
}

const columns: ColumnsProps[] = [
    {
        index: 0,
        name: "Vencido",
        color: "#f23c5d",
        icon: "FaRegCalendarTimes",
        description: "Aqui estão os seus cartões que já estão vencidos"
    },
    {
        index: 1,
        name: "Vence Hoje",
        color: "#ffd400",
        icon: "FaRegCalendarAlt",
        description: "Aqui estão os seus cartões que vencem hoje"
    },
    {
        index: 2,
        name: "Vence Amanhã",
        color: "#fe8c2f",
        icon: "FaRegCalendarMinus",
        description: "Aqui estão os seus cartões que vencem amanhã"
    },
    {
        index: 3,
        name: "A Vencer",
        color: "#4681b8",
        icon: "FaRegCalendar",
        description: "Aqui estão os seus cartões com vencimento futuro ou sem data de vencimento"
    }
]

const LoaderCard = () => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={135}
        viewBox="0 0 262 135"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="135" />
    </ContentLoader>
)

const TaskList: React.FC = () => {

    const { user } = useAuth();

    const { params } = useRouteMatch<RouteParams>();
    const [cards, setCards] = useState<Card[]>([]);
    const history = useHistory();
    const [cardsFiltered, setCardsFiltered] = useState<Card[]>([]);
    const [flows, setFlows] = useState<FlowFilter[]>([]);
    const { addToast } = useToast();
    const lottieContainer = useRef<HTMLDivElement>(null);
    const lottieContainerBig = useRef<HTMLDivElement>(null);

    const [loading, setLoading] = React.useState<boolean>(true);
    const [loadingTop, setLoadingTop] = React.useState<boolean>(false);

    const [cardSelected, setCardSelected] = useState<Card>();
    const [flowSelected, setFlowSelected] = useState<Flow>();
    const [kanbanConfig, setKanbanConfig] = useState<KanbanConfigProps>();
    const [openCard, setOpenCard] = useState<boolean>(false);

    const [itemNavBarSelected, setItemNavBarSelected] = useState<number>();

    const [openFilter, setOpenFilter] = useState(false);
    const [conditions, setConditions] = useState<string[]>([]);
    const [filterStateMessage, setFilterStateMessage] = useState<string>();

    const [isNotRead, setIsNotRead] = useState<boolean>(true);

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    const [searchField, setSearchField] = useState<boolean>();
    const [searchText, setSearchText] = useState<string>("");
    const searchInputRef = useRef<HTMLInputElement>(null);

    const navBarItemsDefault: NavigationBarItemType[] = [

        {
            index: 0,
            description: "Foco",
            icon: FiTarget,
            iconSize: '12.5',
            active: itemNavBarSelected === 0,
            onClick: () => setItemNavBarSelected(0)
        },
        {
            index: 1,
            description: "Kanban",
            icon: RiTableFill,
            active: itemNavBarSelected === 1,
            onClick: () => setItemNavBarSelected(1)
        },
        {
            index: 2,
            description: "Menções",
            icon: FiAtSign,
            iconSize: '12.5',
            active: itemNavBarSelected === 2,
            onClick: () => setItemNavBarSelected(2),
            badgeItems: user.notifications !== undefined ? user.notifications.length : 0
        }
    ]

    const onSearch = (text: React.FormEvent<HTMLInputElement>) => {

        setSearchText(text?.currentTarget.value);

    };

    const handleCardFocus = useCallback(async (focus: boolean, id_card?: number) => {

        if (id_card !== undefined) {

            setCardsFiltered(
                cardsFiltered.map((card) => {

                    if (card.id_card === id_card) {
                        card.focus = focus;

                        if (card.card_users !== undefined && card.card_users.length > 0) {
                            card.card_users[0].isFocused = focus ? "S" : "N"
                        }
                    }

                    return card;

                })
            )

            api.post('/card/preferences', {
                card_id: id_card,
                isFocused: focus ? "S" : "N"
            })

        }

    }, [cardsFiltered]);

    const handleSelect = useCallback((flow: FlowFilter) => {

        const newCondition: string[] = [];

        //Update the Options, set selected or no
        setFlows(flows?.map((opt) => {
            if (opt.id_flow === flow.id_flow) {
                if (opt.selected === undefined) {
                    opt.selected = true;
                    newCondition.push(String(opt.id_flow));
                } else if (opt.selected === false) {
                    opt.selected = true;
                    newCondition.push(String(opt.id_flow));
                } else {
                    opt.selected = false;
                }
            } else if (opt.selected) {
                newCondition.push(String(opt.id_flow));
            }
            return opt;
        }));

        setConditions(newCondition);

    }, [flows]);

    function compareItems(a: Card, b: Card): number {
        // Critério 1: Items com o campo completed igual a "S"
        if (a.complete === 'S' && b.complete !== 'S') {
            return 1; // a vem antes de b
        }
        if (a.complete !== 'S' && b.complete === 'S') {
            return -1; // a vem depois de b
        }

        // Critério 3: Items com o campo due_date_completed mais antigos
        if (a.dt_due && b.dt_due) {
            const dateA = new Date(a.dt_due).getTime();
            const dateB = new Date(b.dt_due).getTime();
            return dateA - dateB; // Ordenação ascendente por data
        }

        // Critério 2: Items com o campo due_date_completed igual a null ou undefined
        if (!a.dt_due && b.dt_due) {
            return 1; // a vem antes de b
        }
        if (a.dt_due && !b.dt_due) {
            return -1; // a vem depois de b
        }

        if (a.id_card !== undefined && b.id_card !== undefined) {
            return a.id_card - b.id_card;
        }

        // Se todos os critérios forem iguais, mantém a ordem original
        return 0;

    }

    const getApiFlow = useCallback(async (id_flow: number) => {

        await api.get(`/flow`, {
            params: {
                id_flow: id_flow,
                withSteps: true,
                withCards: false,
                removeStepsCondicionals: true
            }
        }).then(response => {
            if (response.data !== null) {

                const respFlow: Flow = response.data;

                if (respFlow !== undefined) {

                    setFlowSelected(respFlow);
                    setKanbanConfig(getKanbanConfig(respFlow));

                }
            }

        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Erro ao carregar o Fluxo',
                description: 'Ocorreu ao tentar carregar o Fluxo!',
            });
        });

    }, [addToast]);

    const cardOpen = useCallback(async (card: Card) => {
        setCardSelected(card);
        getApiFlow(card.flow_id);
        setOpenCard(true);
    }, [getApiFlow]);

    const cardClose = useCallback(async () => {
        setOpenCard(false);
        setCardSelected(undefined);
        setFlowSelected(undefined);
        setKanbanConfig(undefined);
    }, []);

    const getApiTasks = useCallback(async () => {

        setLoadingTop(true);

        api.get(`/card/my-tasks`, {
        }).then(response => {
            if (response.data !== null) {

                const cardsApi: Card[] = response.data;

                if (cardsApi !== undefined && cardsApi.length > 0) {

                    if (params.tab !== undefined && params.tab.toLowerCase() === "focus") {
                        setItemNavBarSelected(1);
                        history.replace("/task-list");
                    } else if (cardsApi[0].user !== undefined && cardsApi[0].user.task_tab !== undefined) {
                        setItemNavBarSelected(Number(cardsApi[0].user.task_tab));
                    } else {
                        setItemNavBarSelected(0);
                    }

                    cardsApi.map((c) => {

                        //Adjust to show the cards without a date
                        if (c.status_dt_due === 4) {
                            c.status_dt_due = 3;
                        }

                        return c;
                    })

                    setCards(cardsApi);
                    setCardsFiltered(cardsApi);

                } else {
                    setItemNavBarSelected(0);
                }

            }
            setLoading(false);
            setLoadingTop(false);
        }).catch(error => {
            setLoading(false);
            setLoadingTop(false);
            console.log(error)
            addToast({
                type: 'error',
                title: 'Erro ao atualizar o objeto',
                description: 'Ocorreu um erro ao tentar atualizar o objeto!',
            });
        });

    }, [addToast, params.tab, history]);

    const handleClickAway = () => {
        setOpenFilter(false);
    };

    useEffect(() => {

        if (!openCard) {
            getApiTasks();
        }

    }, [openCard, getApiTasks]);

    useEffect(() => {

        let newFlows: Flow[] = []

        cards.map((c) => {

            //Adjust to show the cards without a date
            if (c.status_dt_due === 4) {
                c.status_dt_due = 3;
            }

            //Build the flows array
            const validFlow = newFlows.filter((f) => f.id_flow === c.flow_id);
            if (validFlow !== undefined && validFlow.length === 0) { //There isn't flow 
                if (c.flow !== undefined) {

                    let newFlowFilter: FlowFilter = c.flow;

                    if (conditions !== undefined && conditions.length > 0) {
                        const isSelected = conditions.filter((co) => co === String(c.flow_id));
                        if (isSelected.length > 0) {
                            newFlowFilter.selected = true;
                        }
                    }

                    //Count Cards
                    const totCards = cards.filter((ca) => ca.flow_id === c.flow?.id_flow);
                    newFlowFilter.totTasks = totCards.length;

                    newFlows.push(newFlowFilter);
                }
            }

            return c;
        })

        setFlows(newFlows);

    }, [cards, conditions]);

    useEffect(() => {

        let newCards = cards;
        let message: string = "";
        let isFiltered: boolean = false;

        //Search Bar
        if (searchText !== undefined && searchText !== "") {

            isFiltered = true;

            newCards = newCards.filter((card) => {

                let ret = false;

                card.form_answers?.map((answer) => {

                    answer.form_answer_fields.map((answer_field) => {

                        const answerFieldValue = answer_field.valueString !== undefined && answer_field.valueString !== null ? answer_field.valueString.toLocaleLowerCase() : "";

                        if (answerFieldValue.includes(searchText.toLocaleLowerCase())) {
                            ret = true;
                        }

                        return answer_field;
                    })

                    return answer;
                })

                return ret
            })

        }

        if (conditions !== undefined && conditions.length > 0) {

            isFiltered = true;

            newCards = newCards.filter((c) => {
                const isFiltered = conditions.filter((co) => String(c.flow_id) === co);
                if (isFiltered.length > 0) {
                    return true;
                } else {
                    return false;
                }
            })

            if (conditions.length > 0) {
                message += "Tarefas do "

                const nameFlowOne = flows.filter((fl) => String(fl.id_flow) === conditions[0]);
                if (nameFlowOne.length > 0) {
                    message += nameFlowOne[0].name;

                    if (conditions.length === 2) {
                        message += " e mais " + (conditions.length - 1) + " fluxo";
                    } else if (conditions.length > 2) {
                        message += " e mais " + (conditions.length - 1) + " fluxos";
                    }
                }

            }

            setFilterStateMessage(message);
        }

        if (!isFiltered) {
            setCardsFiltered(cards);
        } else {
            setCardsFiltered(newCards);
        }

    }, [conditions, cards, flows, searchText]);

    useEffect(() => {

        if (itemNavBarSelected !== undefined) {
            //Update the preference from currentTab
            api.post('/user/preferences', {
                task_tab: String(itemNavBarSelected)
            })
        }

    }, [itemNavBarSelected]);

    useEffect(() => {

        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }

    }, [searchField]);

    useEffect(() => {
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setSearchText('');
                setSearchField(false);
            }
        };

        window.addEventListener('keydown', handleEscape);

        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, []);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../assets/lottie/loader-circle-white.json')
            })
        }
    }, [loadingTop]);

    useEffect(() => {
        if (lottieContainerBig.current) {
            lottie.loadAnimation({
                container: lottieContainerBig.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../assets/lottie/loader.json')
            })
        }
    }, []);

    return (
        <LayoutContainer>
            <title>Minhas Tarefas | Cange</title>
            <ListContainer>

                {/* Header Page */}
                <AppBarHeader
                    title={"Minhas Tarefas"}
                    icon={FaCheck}
                    iconDynamicColor={"#f23c5d"}
                    navBarItems={navBarItemsDefault}
                >
                    <AvatarContainer>
                        <AvatarCange user={user} size="35" />
                    </AvatarContainer>

                    {!loading && (
                        <WidgetTasksResume>
                            <TaskLabel>
                                Tarefas
                            </TaskLabel>
                            <TaskResumeItem color="#f23b5d">
                                <TbCircleFilled />
                                {cardsFiltered.filter((c) => c.status_dt_due === 0).length} Em atraso
                            </TaskResumeItem>
                            <TaskResumeItem color="#ffd402">
                                <TbCircleFilled />
                                {cardsFiltered.filter((c) => c.status_dt_due === 1).length} Hoje
                            </TaskResumeItem>
                            <TaskResumeItem color="#4681b8">
                                <TbCircleFilled />
                                {cardsFiltered.filter((c) => c.status_dt_due === 2 || c.status_dt_due === 3).length} Próximo
                            </TaskResumeItem>
                        </WidgetTasksResume>

                    )}
                </AppBarHeader>

                <FilterBarTasks>
                    {itemNavBarSelected !== 2 ?
                        <>
                            <FilterBarTasksLeft>
                                <FilterBarTasksButtonLink type="button"
                                    onClick={() => {
                                        setOpenFilter(!openFilter)

                                        if (searchText === undefined || searchText === "") {
                                            setSearchField(false);
                                        }
                                    }}
                                    active={conditions.length >= 1}
                                >
                                    <MdOutlineFilterList />
                                    {conditions.length >= 1 ? filterStateMessage : "Todos os Fluxos"}

                                    {openFilter ? (
                                        <ClickAwayListener onClickAway={handleClickAway}>
                                            <BoxContainer>
                                                <BoxItemsContainer>
                                                    {flows !== undefined && flows?.length > 0 ?
                                                        flows?.map((flow) => {

                                                            const condFiltered = conditions.filter((co) => co === String(flow.id_flow));
                                                            let isSelected = false;
                                                            if (condFiltered !== undefined && condFiltered.length > 0) {
                                                                isSelected = true;
                                                            }

                                                            return (
                                                                <BoxItem key={flow.id_flow} onClick={() => handleSelect(flow)}>
                                                                    <BoxMultiple>
                                                                        {(flow.selected !== undefined && flow.selected === true) || isSelected ?
                                                                            <BsToggleOn color="green" /> :
                                                                            <BsToggleOff />
                                                                        }
                                                                    </BoxMultiple>
                                                                    <BoxSpan>{flow.name}</BoxSpan>
                                                                    <BoxSpanDetail>{flow.totTasks}</BoxSpanDetail>
                                                                </BoxItem>
                                                            )
                                                        }) :
                                                        <NoResults>Sem registro</NoResults>
                                                    }
                                                </BoxItemsContainer>
                                            </BoxContainer>
                                        </ClickAwayListener>
                                    ) : <></>}
                                </FilterBarTasksButtonLink>

                                {!searchField ?
                                    <ButtonLink type="button"
                                        onClick={() => {
                                            setSearchField(true);
                                            setOpenFilter(false);
                                        }}>
                                        <AiOutlineSearch />
                                        <div>Pesquisar</div>
                                    </ButtonLink> :
                                    <SearchInput>
                                        <AiOutlineSearch />
                                        <Input
                                            ref={searchInputRef}
                                            onChange={(e) => onSearch(e)}
                                            placeholder="Busque aqui..."
                                            value={searchText}
                                        />
                                        {searchText !== undefined && searchText !== "" ?
                                            <IoIosCloseCircle
                                                style={{ marginRight: "9px" }}
                                                onClick={() => {
                                                    setSearchText("");
                                                    setSearchField(false);
                                                }}
                                            /> :
                                            <></>
                                        }
                                    </SearchInput>
                                }
                            </FilterBarTasksLeft>
                        </> :
                        <FilterBarTasksLeft>
                            <FilterBarTasksButtonClick type="button" onClick={() => setIsNotRead(!isNotRead)} active={!isNotRead}>
                                <FiBell />
                                {isNotRead ? "Somente não lidos" : "Todas as menções"}
                            </FilterBarTasksButtonClick>
                        </FilterBarTasksLeft>
                    }
                </FilterBarTasks>

                {!loading && cards.length === 0 &&
                    user.company !== undefined &&
                    user.company.company_plan !== undefined &&
                    user.company.company_plan !== null &&
                    user.company.company_plan[0].plan_id === 6 ?
                    <ContainerEmpty>
                        <ContainerInfo>
                            <h1>Uau! Bem-vindo ao seu local de Trabalho, onde estará todas suas tarefas</h1>
                            <p>Aqui você encontrará todas as suas tarefas em um só lugar. Insira novos cartões nos fluxos, atribua responsáveis e veja suas prioridades de forma clara. Organize sua agenda, concentre-se no que é importante e colabore com seus colegas. Seja mais produtivo do que nunca!</p>
                            <p className="tip">Inicie agora inserindo uma tarefa atribuída à você em algum fluxo 💡</p>
                            {/*
                            <ButtonFirstNewTask
                                icon={FaPlus}
                                className="new-item-btn"
                                type="button"
                                color="#f23b5c"
                                height="36px"
                                margin="0px 15px 0px 0px"
                            >
                                Crie sua primeira tarefa
                            </ButtonFirstNewTask>
                            */}
                        </ContainerInfo>
                        <ContainerIlustration>
                            <img src={myTasksIlustration} alt="Sem Tarefas" />
                        </ContainerIlustration>
                    </ContainerEmpty> :
                    <ContainerBody>
                        <ContainerContent>

                            {itemNavBarSelected === 1 ?
                                <Container>
                                    {columns.map((column) => {

                                        const max = Math.floor(4);
                                        const min = Math.ceil(1);

                                        const random = Math.floor(Math.random() * (max - min)) + min;
                                        let loadingItemsNew: number[] = [];
                                        for (let index = 0; index < random; index++) {
                                            loadingItemsNew.push(index);
                                        }

                                        return (
                                            <ColumnContainer key={column.index}>
                                                <ColumnsHeader color={column.color}>
                                                    <ColumnsHeaderIcon>

                                                        {column.icon !== undefined ?
                                                            <IconPickerItem
                                                                icon={column.icon as IconList}
                                                                color={"white"}
                                                            /> :
                                                            <FaMailBulk />
                                                        }

                                                    </ColumnsHeaderIcon>
                                                    <ColumnsHeaderTitle>{column.name}</ColumnsHeaderTitle>
                                                    <ColumnsHeaderRight>
                                                        {cardsFiltered !== undefined && cardsFiltered.filter((card) => card.status_dt_due === column.index).length > 0 && !loadingTop ?
                                                            <ColumnsHeaderSpan color={column.color}>
                                                                <span>
                                                                    {cardsFiltered.filter((card) => card.status_dt_due === column.index).length}
                                                                </span>
                                                            </ColumnsHeaderSpan> : loadingTop ?
                                                                <ContainerLoader>
                                                                    <Animation className="lottieContainer" ref={lottieContainer} />
                                                                </ContainerLoader> :
                                                                <></>
                                                        }
                                                    </ColumnsHeaderRight>
                                                </ColumnsHeader>
                                                <ColumnsItems>
                                                    {/* Columns Items: Tasks */}
                                                    {loading ?
                                                        loadingItemsNew.map((item) => {
                                                            return (
                                                                <div key={item} style={{ marginBottom: '10px' }}>
                                                                    <LoaderCard key={item} />
                                                                </div>
                                                            )
                                                        }) :
                                                        cardsFiltered?.filter((card) => card.status_dt_due === column.index).sort(compareItems).map((card) => {
                                                            return (
                                                                <div key={card.id_card}>
                                                                    <Link to={"/flow/" + card.flow?.hash + "/card/" + card.id_card} style={{ textDecoration: 'none' }}>
                                                                        <FlowContainer>
                                                                            <FlowIcon color={card.flow?.color}>
                                                                                {card.flow?.icon !== undefined ?
                                                                                    <IconPickerItem
                                                                                        icon={card.flow?.icon as IconList}
                                                                                        color={card.flow?.color}
                                                                                    /> :
                                                                                    <FaMailBulk />
                                                                                }
                                                                            </FlowIcon>
                                                                            <span>{card.flow?.name}</span>
                                                                        </FlowContainer>
                                                                    </Link>
                                                                    <Task
                                                                        card={card}
                                                                        color={column.color}
                                                                        kanban_config={card.flow !== undefined ? getKanbanConfig(card.flow) : undefined}
                                                                        openDetail={cardOpen}
                                                                    />
                                                                </div>
                                                            )
                                                        })}
                                                    <ColumnDescription>
                                                        <p>{column.description}</p>
                                                    </ColumnDescription>
                                                </ColumnsItems>
                                            </ColumnContainer>
                                        );
                                    })}

                                    {openCard && cardSelected !== undefined && flowSelected !== undefined && (
                                        <CardDialog
                                            open={openCard}
                                            isMobile={isMobile}
                                            flow={flowSelected}
                                            kanban_config={kanbanConfig}
                                            selectedValue={cardSelected}
                                            onClose={cardClose}
                                            typeUser={flowSelected.typeUserAccess !== undefined ? flowSelected.typeUserAccess : ""}
                                            showFlowDetail={true}
                                        />
                                    )}
                                </Container>
                                : itemNavBarSelected === 0 ?
                                    <FocusTab
                                        cards={cardsFiltered}
                                        forceUpdate={() => getApiTasks()}
                                        handleCardFocus={handleCardFocus}
                                        expandedAll={searchText !== undefined && searchText !== ""}
                                        conditions={conditions}
                                    />
                                    : itemNavBarSelected === 2 ?
                                        <NotificationTab
                                            isNotRead={isNotRead}
                                        />
                                        : <ContainerLoader>
                                            <Animation className="lottieContainerBig" ref={lottieContainerBig} />
                                        </ContainerLoader>
                            }

                        </ContainerContent>
                    </ContainerBody>
                }

            </ListContainer>

        </LayoutContainer>
    );

}

export default TaskList;