import { BiBlock, BiSave } from "react-icons/bi";
import {
    BtnInsertNewAnswer,
    Card,
    CardButton,
    CardHeader,
    CardHeaderLeft,
    CardHeaderRight,
    CardHeaderTitle,
    CardTitle,
    ConditionalButton,
    ContainerItems,
    Content,
    ContentBody,
    CurrentStep,
    DialogFooter,
    DialogFooterCenter,
    DialogTop,
    DialogTopCenter,
    DialogTopLeft,
    DialogTopRight,
    IconHeader
} from "./styles";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import React, { useCallback, useEffect, useState } from "react";

import { AiOutlineClose } from "react-icons/ai";
import { Dialog } from '@material-ui/core';
import { FaCheck } from "react-icons/fa";
import { IoMdListBox } from "react-icons/io";
import { Step } from "../../interfaces/Step";
import { TiFlowChildren } from "react-icons/ti";
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import AutomationStep from "../AutomationStep";
import LearningBasicVideo from "../../components/Learning/LearningBasicVideo";

export interface ConfigStepsProps {
    open: boolean;
    flow_id?: number;
    flow_step_id?: number;
    onClose: () => void;
}

interface apiRequest {
    flow_id: number;
    flow_step_id: number;
    step_available_id: number;
    isActive: string;
}

const ConfigSteps: React.FC<ConfigStepsProps> = ({ onClose, open, flow_id, flow_step_id }) => {

    const { addToast } = useToast();
    const [steps, setSteps] = useState<Step[]>();
    const [currentStep, setCurrentStep] = useState<Step>();
    const [apiReq, setApiReq] = useState<apiRequest[]>([]);
    const [loadingInsert, setLoadingInsert] = useState<boolean>(false);

    const [selectedStep, setSelectedStep] = useState<Step>();
    const [openConditional, setOpenConditional] = useState<boolean>(false);

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    const handleSave = useCallback(async () => {

        setLoadingInsert(true);
        for (let index = 0; index < apiReq.length; index++) {

            await api.post('/flow-step/relationship', apiReq[index])
                .then(response => {

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar os registros na base de daods!',
                        description: 'Ocorreu um erro ao salvar o registro! :(',
                    });
                });

        }
        setLoadingInsert(false);

        setApiReq([]);
        handleClose();

    }, [addToast, apiReq, handleClose]);

    const handleSetRelationship = useCallback(async (stepUpd: Step) => {

        let apiReqNew = apiReq;

        if (flow_id !== undefined && flow_step_id !== undefined) {

            setSteps(
                steps?.map((step) => {
                    if (step.id_step === stepUpd.id_step) {
                        if (step.step_relationship !== undefined) {
                            if (step.step_relationship.isActive === "1") {
                                step.step_relationship.isActive = "0";
                            } else {
                                step.step_relationship.isActive = "1";
                            }

                            if (step.id_step !== undefined && flow_step_id !== undefined) {

                                //Cria o objeto api
                                const newItem: apiRequest = {
                                    flow_id: flow_id,
                                    flow_step_id: flow_step_id,
                                    step_available_id: step.id_step,
                                    isActive: step.step_relationship.isActive
                                }

                                //Add no objeto para inserir na api
                                apiReqNew.push(newItem);

                            }

                        }
                    }
                    return step;
                })
            );

        }

        setApiReq(apiReqNew);

    }, [steps, apiReq, flow_id, flow_step_id]);

    const getApiFlow = useCallback(async () => {

        api.get(`/flow-step/relationship`, {
            params: {
                id_step: flow_step_id,
                flow_id: flow_id
            }
        }).then(response => {
            if (response.data !== null) {

                const respStep: Step[] = response.data;

                if (respStep !== undefined) {
                    setSteps(respStep);
                }

                const restStepCurrent: Step[] = respStep.filter((step) => step.id_step === flow_step_id);
                if (restStepCurrent.length > 0) {
                    setCurrentStep(restStepCurrent[0]);
                }

            }

        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Erro ao buscar as Etapas',
                description: 'Ocorreu ao tentar abrir as Etapas!',
            });

        });

    }, [addToast, flow_id, flow_step_id]);

    useEffect(() => {

        let isMounted = true;

        if (isMounted && open) {
            getApiFlow();
        }

        return () => {
            isMounted = false;
        };

    }, [getApiFlow, open]);

    useEffect(() => {

        if (!openConditional) {
            getApiFlow();
        }

    }, [openConditional, getApiFlow]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader color={currentStep !== undefined && currentStep?.color !== undefined ? currentStep?.color : "black"}>
                        <div>
                            <TiFlowChildren />
                        </div>
                    </IconHeader>
                    <h1>
                        {"Relacionamento entre etapas"}
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter />

                <DialogTopRight>
                    <button onClick={handleClose}><AiOutlineClose></AiOutlineClose></button>
                </DialogTopRight>
            </DialogTop>

            <Content>
                <ContentBody container>

                    <div style={{ marginRight: '15px', marginLeft: '15px'}}>
                        <LearningBasicVideo
                            link="https://youtu.be/yUqSUOv7mwU?si=zG_FsPHzskrTWeHG"
                            title="Assista e aprenda"
                            buttonTitle="Ver o vídeo"
                            description="Os cartões que estão nesta etapa podem ser movidos para as etapas disponíveis abaixo. Crie também condicionais para permitir ou não permitir a movimentação para uma etapa específica"
                            color={"#00bcd4"}
                        />
                    </div>

                    <ContainerItems>
                        {steps?.map((step) => {
                            return (
                                <Card key={step.id_step} color="#eef2f8">
                                    <CardHeader>
                                        <CardHeaderLeft color={step.color !== undefined ? step.color : "blue"}>
                                            {step !== undefined && step.icon !== undefined ?
                                                <IconPickerItem
                                                    icon={step.icon as IconList}
                                                    color={step.color}
                                                /> :
                                                <IoMdListBox />
                                            }
                                        </CardHeaderLeft>
                                        <CardHeaderTitle>
                                            <CardTitle
                                                title={step.name}
                                                required={false}
                                                font_size="18px"
                                                font_weight="600"
                                            />
                                        </CardHeaderTitle>
                                        <CardHeaderRight>
                                            {step.id_step !== currentStep?.id_step ?
                                                <>
                                                    {step.step_relationship?.isActive === "1" && (
                                                        <ConditionalButton
                                                            style={{ marginRight: '10px' }}
                                                            color={step.conditionals !== undefined && step.conditionals !== null && step.conditionals.length > 0 ? "#61bd4f" : undefined}
                                                            title="Adicionar condicional na etapa"
                                                            onClick={() => {
                                                                setSelectedStep(step);
                                                                setOpenConditional(true);
                                                            }}>
                                                            <TiFlowChildren />
                                                            <span>Condicional</span>
                                                        </ConditionalButton>
                                                    )}
                                                    <CardButton onClick={() => handleSetRelationship(step)}>
                                                        {step.step_relationship?.isActive === "1" ?
                                                            <FaCheck color={"green"} /> :
                                                            <BiBlock color={"red"} />
                                                        }
                                                    </CardButton>
                                                </> :
                                                <CurrentStep color={step.color !== undefined ? step.color : "white"}>
                                                    <h3>Etapa atual</h3>
                                                </CurrentStep>
                                            }
                                        </CardHeaderRight>
                                    </CardHeader>
                                </Card>
                            );
                        })}

                        {openConditional && selectedStep !== undefined && currentStep !== undefined && flow_id !== undefined && (
                            <AutomationStep
                                open={openConditional}
                                flow_id={flow_id}
                                flowStep={selectedStep}
                                flowStepParent={currentStep}
                                onClose={() => setOpenConditional(false)}
                            />
                        )}
                    </ContainerItems>
                </ContentBody>
            </Content>

            <DialogFooter>
                <DialogFooterCenter>
                    <BtnInsertNewAnswer icon={BiSave} onClick={() => handleSave()} isLoading={loadingInsert}>
                        Salvar
                    </BtnInsertNewAnswer>
                </DialogFooterCenter>
            </DialogFooter>
        </Dialog >
    );
};

export default ConfigSteps;

