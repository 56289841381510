import React, { useCallback, useEffect, useState } from "react";

import {
    AddNewFilter,
    BoxContainer,
    CleanFilter,
    DeleteButton,
    FilterBottom,
    FilterContainer,
    GoFilter,
    SelectComparator,
    SelectInput
} from "./style";
import { ClickAwayListener } from "@material-ui/core";
import { FieldOptionsProps, FieldProps, Fields, getComponentComparationType, getFieldObject } from "../../../../../components/Forms/Fields/FieldBuilder";
import FilterInput from "../../../../../components/FilterBar/FilterComponents/FilterInput";
import FilterDate from "../../../../../components/FilterBar/FilterComponents/FilterDate";
import FilterSelect from "../../../../../components/FilterBar/FilterComponents/FilterSelect";
import { Flow } from "../../../../../interfaces/Flow";
import { FaPlus, FaTrash } from "react-icons/fa";
import { AiFillThunderbolt } from "react-icons/ai";

interface ConditionBoxProps {
    flow?: Flow;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    filterSchema?: string;
    setFilterSchema: React.Dispatch<React.SetStateAction<string | undefined>>;
    filterStateMessage?: string;
    setFilterStateMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export interface FilterConditionItem {
    index: number;
    fields: FieldProps[];
    comparators: string[];
    selectedField: FieldProps;
    selectedComparator: string;
    value: string;
    valueOptions?: string[];
    options?: FieldOptionsProps[];
    nameComponent?: string;
}

const ConditionBox: React.FC<ConditionBoxProps> = ({ flow, open, setOpen, filterSchema, setFilterSchema, filterStateMessage, setFilterStateMessage }) => {

    const [fields, setFields] = useState<FieldProps[]>([]);
    const [conditionItems, setConditionItems] = useState<FilterConditionItem[]>([]);

    /* Relationship */
    const ComponentField = {
        TEXT_FILTER: "TEXT_FILTER",
        DATE_FILTER: "DATE_FILTER",
        OPTION_FILTER: "OPTION_FILTER",
    };

    const getFilterComponent = (type: string) => {
        const relationFields = {
            [ComponentField.TEXT_FILTER]: FilterInput,
            [ComponentField.DATE_FILTER]: FilterDate,
            [ComponentField.OPTION_FILTER]: FilterSelect,
        };

        return relationFields[type];
    };

    const getFilterNameComponent = (type: string) => {
        const relationFields = {
            [Fields.TEXT_SHORT_FIELD]: "TEXT_FILTER",
            [Fields.TEXT_LONG_FIELD]: "TEXT_FILTER",
            [Fields.MAIL_FIELD]: "TEXT_FILTER",
            [Fields.COMBO_BOX_FIELD]: "OPTION_FILTER",
            [Fields.RADIO_BOX_FIELD]: "OPTION_FILTER",
            [Fields.CHECK_BOX_FIELD]: "OPTION_FILTER",
            [Fields.DATE_PICKER_FIELD]: "DATE_FILTER",
            [Fields.SWITCH_FIELD]: "OPTION_FILTER",
            [Fields.INPUT_LIST_FIELD]: "OPTION_FILTER",
            [Fields.CHECK_BOX_ONE_FIELD]: "OPTION_FILTER",
            [Fields.COMBO_BOX_USER_FIELD]: "OPTION_FILTER",
            [Fields.DUE_DATE_FIELD]: "DATE_FILTER",
            [Fields.CURRENCY_FIELD]: "TEXT_FILTER",
            [Fields.FORMULA_FIELD]: "TEXT_FILTER",
            [Fields.NUMBER_FIELD]: "TEXT_FILTER",
            [Fields.PHONE_FIELD]: "TEXT_FILTER",
            [Fields.COMBO_BOX_REGISTER_FIELD]: "OPTION_FILTER",
            [Fields.COMBO_BOX_FLOW_FIELD]: "OPTION_FILTER",
            [Fields.INPUT_RICH_TEXT_FIELD]: "TEXT_FILTER",
            [Fields.ID_FIELD]: "TEXT_FILTER",
            [Fields.LINK_FIELD]: "TEXT_FILTER",
            [Fields.DOC_FIELD]: "TEXT_FILTER",
        };

        return relationFields[type];
    }

    const onSelectField = (field_id: string, index: number) => {

        const fieldSelected = fields.filter((field) => String(field.id_field) === field_id);

        if (fieldSelected !== undefined && fieldSelected.length > 0) {

            setConditionItems(conditionItems.map((condition, idx) => {
                if (idx === index) {
                    condition.comparators = getComponentComparationType(fieldSelected[0].type);
                    condition.selectedComparator = getComponentComparationType(fieldSelected[0].type)[0];
                    condition.selectedField = fieldSelected[0];
                    condition.nameComponent = getFilterNameComponent(fieldSelected[0].type)
                    condition.valueOptions = [];
                    condition.value = "";
                }

                return condition;
            }))

        }

    };

    const onSelectComparator = (comparatorName: string, index: number) => {

        setConditionItems(conditionItems.map((condition, idx) => {
            if (idx === index) {
                condition.selectedComparator = comparatorName;

                //Clean the value when is blank
                if (comparatorName === "Está em branco" || comparatorName === "Não está em branco") {
                    condition.value = "";
                }
            }

            return condition;
        }))

    };

    const handleDeleteCondition = useCallback((index: number) => {

        let newConditions = conditionItems.filter((condition) => condition.index !== index);

        //Rebuild the indexes
        newConditions.map((condition, idx) => {
            condition.index = idx;
            return condition;
        })

        if (newConditions.length === 0) {

            const newConditionItem: FilterConditionItem = {
                index: 0,
                fields: fields,
                comparators: getComponentComparationType(fields[0].type),
                selectedField: fields[0],
                selectedComparator: getComponentComparationType(fields[0].type)[0],
                value: "",
                valueOptions: undefined,
                options: fields[0].options,
                nameComponent: getFilterNameComponent(fields[0].type)
            }

            newConditions.push(newConditionItem);

        }

        setConditionItems(newConditions);

    }, [conditionItems, fields, setConditionItems]);

    const onEventFieldComponent = useCallback((index: number, value?: string, conditions?: string[], version?: number) => {

        if (value !== undefined) {

            setConditionItems(conditionItems.map((condition, idx) => {
                if (idx === index) {
                    condition.value = value;
                }

                return condition;
            }))

        } else if (conditions !== undefined && conditions.length > 0) {

            setConditionItems(conditionItems.map((condition, idx) => {
                if (idx === index) {
                    condition.valueOptions = conditions
                }

                return condition;
            }))

        }

    }, [conditionItems, setConditionItems]);

    const addNewCondition = useCallback(() => {

        let newArr = conditionItems;

        const newConditionItem: FilterConditionItem = {
            index: newArr.length,
            fields: fields,
            comparators: getComponentComparationType(fields[0].type),
            selectedField: fields[0],
            selectedComparator: getComponentComparationType(fields[0].type)[0],
            value: "",
            options: fields[0].options,
            nameComponent: getFilterNameComponent(fields[0].type)
        }

        newArr.push(newConditionItem);

        setConditionItems(newArr.map((condition) => {
            return condition;
        }));

    }, [fields, conditionItems, setConditionItems]);

    const addDefaultItems = useCallback(() => {

        if (fields !== undefined && fields.length > 0 && conditionItems.length === 0) {

            const newConditionItem: FilterConditionItem = {
                index: 0,
                fields: fields,
                comparators: getComponentComparationType(fields[0].type),
                selectedField: fields[0],
                selectedComparator: getComponentComparationType(fields[0].type)[0],
                value: "",
                options: fields[0].options,
                nameComponent: getFilterNameComponent(fields[0].type)
            }

            setConditionItems([newConditionItem]);

        }

    }, [fields, conditionItems.length, setConditionItems]);

    const handleDeleteConditional = useCallback(async () => {

        setFilterSchema(undefined);
        setFilterStateMessage(undefined);

        setOpen(false);

    }, [setFilterSchema, setFilterStateMessage, setOpen]);

    const handleAddNewConditional = useCallback(async () => {

        let message = "Se o campo ";

        //Create the name
        if (conditionItems.length !== undefined && conditionItems.length >= 1) {

            let ret = false;
            let fieldsOn: string[] = [];

            conditionItems.map((cond) => {
                if (cond.value !== "" || (cond.valueOptions !== undefined && cond.valueOptions.length >= 1) || cond.selectedComparator === "Está em branco" || cond.selectedComparator === "Não está em branco") {
                    ret = true;
                    fieldsOn.push(cond.selectedField.title);
                }

                return cond;
            })

            if (ret) {

                fieldsOn.map((fil, index) => {

                    const containsInMessage = message.includes(fil);

                    if (index === 0) {
                        message += "[" + fil + " for " + conditionItems[0].selectedComparator.toLowerCase() + "] ";
                    } else if (index === 1 && fieldsOn.length > 2) {
                        message += " + " + (fieldsOn.length - 1) + " campos";
                    } else if (index === 1 && fieldsOn.length > 1 && !containsInMessage) {
                        message += " e  [" + fil + " for " + conditionItems[1].selectedComparator.toLowerCase() + "] ";
                    }
                    return fil;
                });

                //Limit message to 150 characters
                if (message.length > 130) {
                    message = message.substring(0, 130) + "...";
                }
            }

            let newFilterSchema = conditionItems;

            //Remove the fields
            newFilterSchema.map((condition) => {
                condition.fields = [];
                condition.comparators = [];
                return condition;
            });

            if (ret) {
                setFilterStateMessage(message);
                setFilterSchema(JSON.stringify(conditionItems));
            }

            setOpen(false);

        }

    }, [conditionItems, setFilterSchema, setOpen, setFilterStateMessage]);

    const handleClickAway = () => {
        handleAddNewConditional();

        setOpen && setOpen(false);
    };

    useEffect(() => {

        let newField: FieldProps[] = [];

        //Get all fields 
        if (flow !== undefined && flow !== null) {

            //Prepare standard fields
            //Data de Vencimento
            newField.push({
                id_field: -1,
                name: "-1",
                type: "DATE_PICKER_FIELD",
                title: "Data de Vencimento",
                description: "Data de Vencimento",
                index: -1
            });

            //Data de Criação
            newField.push({
                id_field: -2,
                name: "-2",
                type: "DATE_PICKER_FIELD",
                title: "Data de Criação",
                description: "Data de Criação",
                index: -2
            });

            //Responsável
            newField.push({
                id_field: -3,
                name: "-3",
                type: "COMBO_BOX_USER_FIELD",
                title: "Responsável",
                description: "Responsável",
                index: -3,
                form_id: flow.form_init?.id_form
            });

            //Etapa
            newField.push({
                id_field: -4,
                name: "-4",
                type: "COMBO_BOX_FIELD",
                title: "Etapa",
                description: "Etapa",
                index: -4,
                options: flow.flow_steps ? flow.flow_steps.map((step, index) => {
                    return {
                        id_field_option: step.id_step,
                        field_id: -4,
                        value: String(step.id_step),
                        label: step.name,
                        order: String(index)
                    } as FieldOptionsProps
                }) : undefined
            });

            if (flow.form_init !== undefined && flow.form_init !== null) {
                if (flow.form_init.fields !== undefined && flow.form_init.fields !== null && flow.form_init.fields.length > 0) {
                    newField.push(...flow.form_init.fields);
                }
            }

            if (flow.flow_steps !== undefined) {
                for (let index = 0; index < flow.flow_steps.length; index++) {
                    const step = flow.flow_steps[index];

                    if (step.form !== undefined) {
                        if (step.form.fields !== undefined && step.form.fields.length > 0) {
                            newField.push(...step.form.fields);
                        }
                    }

                }
            }

            setFields(newField.filter((f) => f.index < 0 || getFieldObject(f.type).onFilter));

        }

    }, [flow]);

    useEffect(() => {

        //Valid if has any init
        addDefaultItems();

    }, [addDefaultItems, fields]);

    useEffect(() => {

        if (filterSchema !== undefined && filterSchema !== null && filterSchema !== "") {

            let newConditionalItems = JSON.parse(filterSchema) as FilterConditionItem[];

            //Update the fields
            newConditionalItems.map((condition) => {
                condition.fields = fields;
                condition.comparators = getComponentComparationType(condition.selectedField.type);
                return condition;
            });

            setConditionItems(newConditionalItems);

        }

    }, [fields, filterSchema]);

    return (
        open ?
            <ClickAwayListener onClickAway={handleClickAway}>
                <BoxContainer>

                    {conditionItems.map((condition, index) => {

                        const nameComponent: string = getFilterNameComponent(condition.selectedField.type);

                        let FilterComponent = getFilterComponent(nameComponent);

                        return (

                            <FilterContainer key={condition.index}>

                                <SelectInput value={condition.selectedField.id_field} onChange={(e) => onSelectField(e.currentTarget.value, index)}>
                                    {flow !== undefined ?
                                        <optgroup label="Campos Padrões">
                                            <option key={-1} value={-1}>{"Data de Vencimento"}</option>
                                            <option key={-2} value={-2}>{"Data de Criação"}</option>
                                            <option key={-3} value={-3}>{"Responsável"}</option>
                                            <option key={-4} value={-4}>{"Etapa"}</option>
                                        </optgroup> :
                                        <></>
                                    }
                                    {fields && fields.filter((f) => f.index >= 0).length > 0 ?
                                        <optgroup label="Campos Customizáveis">
                                            {fields.filter((f) => f.index >= 0).map((field) => {
                                                return (
                                                    <option key={field.id_field} value={field.id_field}>{field.title}</option>
                                                )
                                            })}
                                        </optgroup> :
                                        <></>}
                                </SelectInput>

                                <SelectComparator value={condition.selectedComparator} onChange={(e) => onSelectComparator(e.currentTarget.value, index)}>
                                    {condition.comparators.map((comparator) => {
                                        return (
                                            <option key={comparator} value={comparator}>{comparator}</option>
                                        )
                                    })}
                                </SelectComparator>

                                <FilterComponent
                                    comparator={condition.selectedComparator}
                                    onEvent={(e, cond) => onEventFieldComponent(index, e?.currentTarget.value, cond)}
                                    value={condition.value}
                                    valueOptions={condition.valueOptions}
                                    field={condition.selectedField}
                                    options={condition.selectedField.options}
                                />

                                <DeleteButton onClick={() => handleDeleteCondition(index)}>
                                    <FaTrash />
                                </DeleteButton>

                            </FilterContainer>

                        )

                    })}

                    {(conditionItems === undefined || conditionItems.length <= 0) ? (
                        <div style={{ padding: '10px', fontSize: '14px' }}>Você não possui campos para filtrar ainda, adicione novos campos para usar esta funcionalidade ;)</div>
                    ) : (

                        <FilterBottom>

                            <AddNewFilter onClick={() => addNewCondition()}>
                                <FaPlus />
                                Adicionar condição
                            </AddNewFilter>

                            <CleanFilter onClick={() => handleDeleteConditional()}>
                                <FaTrash />
                                Limpar Tudo
                            </CleanFilter>

                            <GoFilter onClick={() => handleAddNewConditional()}>
                                <AiFillThunderbolt />
                                Filtrar
                            </GoFilter>

                        </FilterBottom>

                    )}

                </BoxContainer>
            </ClickAwayListener> :
            <></>
    );

}

export default ConditionBox;