import styled, { css } from "styled-components";

interface LogHeaderIconProps {
    color?: string;
}

export const Container = styled.div`
    padding: 10px;
    padding-top: 0px;
`;

export const ContainerBox = styled.div`
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid #eef2f8;
`;

export const ContainerBoxTitle = styled.div`

    display: flex;
    justify-content: flex-start;
    align-items: center;

    h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 10px;
        margin-top: 2px;
        font-size: 12px;
        color: #84939f;
        font-weight: 500;
        text-transform: uppercase;
    }

    svg {
        min-width: 15px;
        font-size: 15px;
        font-weight: 600;
        color: #84939f;
    }

`;

export const ContainerBoxBody = styled.div`
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
`;

export const ContainerBoxBodyKpis = styled.div`    
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    gap: 10px;
`;

export const KpiCardComplementBackground = styled.div`
    width: 50%;
    background: linear-gradient(45deg,rgba(255,140,47,1) 25%,rgba(251,50,92,1) 50%,rgba(147,55,237,1) 75%);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
`;

export const KpiCardContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
`;

export const KpiCardHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
`;

export const KipCardTitle = styled.div`    
    color: black;
    font-weight: 400;
    font-size: 13px;
    width: 100%;
`;

export const KpiCardComplement = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #70ff5426;
    padding: 4px 5px;
    font-size: 12px;
    color: #67ff49;
    font-weight: 500;
    border-radius: 5px;
`;

export const KpiCardBody = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 2px;
`;

export const KpiCardFooter = styled.div`
    color: gray;
    font-size: 12px;    
`;

export const KpiCardBodyValue = styled.h3`
    font-size: 22px;
    font-weight: 500;
    margin-right: 5px;
`;

export const KpiCardBodyValueComplement = styled.div`
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ContainerLoader = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 200px;
`;

export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        height: 30% !important;
        max-width: 75px;
    }
    
`;

export const LogGroup = styled.div`
    width: 100%;
    margin-top: 10px;
`;

export const LogGroupItem = styled.div`
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    padding: 10px;
    padding-right: 10px;
    padding-top: 0px;
    padding-left: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const LogGroupLine = styled.div`
    border-bottom: 1px solid #eef2f8;
    width: calc(100% - 10px);
    position: absolute;
    border-radius: 50%;
    //top: 50%;
`;

export const LogGroupTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #eef2f8;
    color: #84939f;
    z-index: 10;
    padding: 3px 5px;
    font-size: 11px;
`;

export const LogGroupTitleIcon = styled.div`
    margin-right: 5px;
    margin-top: 2px;
    font-size: 12px;
`;

export const LogGroupTitleText = styled.div`
    font-weight: 500;
`;


export const LogArea = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;    
`;

export const LogContainer = styled.div`
    background-color: white; 
    width: 100%;    
`;

export const LogHeader = styled.div`
    border-bottom: 1px solid #f4f5f7;
    padding: 5px;
    margin-bottom: 10px;
    cursor: pointer;
`;

export const LogHeaderIcon = styled.div<LogHeaderIconProps>`
    height: 10px;
    width: 10px;
    border-radius: 3px;
    background-color: #9e37ed;

    ${props => props.color && css`
        background-color: ${props.color};
    `}
`;

export const LogHeaderInfo = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
`;

export const LogHeaderTitle = styled.div`
    color: #252525;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const LogHeaderOrigin = styled.div`
    margin-left: 5px;
    font-size: 12px;
    color: #4d4d4d;
`;


export const LogBody = styled.div`
    color: black;
`;

export const LogHistoryItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
`;

export const LogHistoryAvatar = styled.div`
    margin-right: 5px;
`;

export const LogHistoryUserName = styled.div`
    font-weight: 500;
    color: black;
    margin-right: 5px;
`;

export const LogHistoryDescription = styled.div`

`;

export const LogHistoryChildren = styled.button`
    cursor: pointer;
    font-size: 13px;
    margin-top: 10px;
    font-weight: 400;
    background: transparent;
    border: none;
    color:#84939f;
`;

export const LogHistoryDate = styled.label`
    color: gray;
    font-weight: 500;
    font-size: 12px;
`;

export const LogHistoryExpanded = styled.div`
    margin-top: 5px;
`;
