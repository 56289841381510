import React, { useRef, useState, ChangeEvent, useEffect, useCallback } from "react";
import {
    Container,
    Top,
    LeftPicker,
    RightPicker,
    Body,
    LeftContainer,
    FastPickButtons,
    RecurButton,
    RecurButtonContainer,
    RightContainer,
    FastPickButton,
    FastPickButtonDetail,
    RecurItemBox,
    RecurItemBoxLabel,
    RecurItemBoxSelect,
    RecurItemBoxSelectOpt,
    RecurItemBoxInput,
    RecurContainer,
    BtnConfirm,
    RecurTitle,
    RecurSummaryContainer,
    RecurDescription,
    RecurButtonGroup,
    RecurDescriptionTitle,
    RecurDescriptionText,
    RecurItemDetail,
    RecurItemDetailBtn,
    ClearIcon,
    DialogOverlay,
    Dialog,
    DialogHeader,
    DialogContent
} from "./style";
import { FaRegCalendarAlt } from "react-icons/fa";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
import { TbCalendar, TbRotateClockwise } from "react-icons/tb";
import { RRule, Frequency, Weekday } from "rrule";
import { IoMdCloseCircle } from "react-icons/io";
import { TiTimes } from "react-icons/ti";
import { useField } from "@unform/core";
import ErrorDescription from "../../../../ErrorDescription";
import { styled, Tooltip, TooltipProps } from "@material-ui/core";
import { BsLightningFill } from "react-icons/bs";

registerLocale("pt", pt);

const customLocale: Record<string, string> = {
    every: "A cada",
    day: "dia",
    days: "dias",
    week: "semana",
    weeks: "semanas",
    on: "em",
    the: "o",
    at: "às",
    last: "último",
    first: "primeiro",
    second: "segundo",
    third: "terceiro",
    fourth: "quarto",
    fifth: "quinto",
    monday: "segunda-feira",
    tuesday: "terça-feira",
    wednesday: "quarta-feira",
    thursday: "quinta-feira",
    friday: "sexta-feira",
    saturday: "sábado",
    sunday: "domingo",
    january: "janeiro",
    february: "fevereiro",
    march: "março",
    april: "abril",
    may: "maio",
    june: "junho",
    july: "julho",
    august: "agosto",
    september: "setembro",
    october: "outubro",
    november: "novembro",
    december: "dezembro",
    until: "até",
    times: "vezes",
    time: "vez",
    hour: "hora",
    hours: "horas",
    minute: "minuto",
    minutes: "minutos",
    seconds: "segundos",
    onDay: "no dia",
    and: "e",
    or: "ou",
    month: "mês",
    months: "meses",
    year: "ano",
    years: "anos",
    atHour: "às",
    atMinute: "e",
    onThe: "no",
    ofEvery: "de cada",
    onTheLast: "no último",
    onTheFirst: "no primeiro",
    onTheSecond: "no segundo",
    onTheThird: "no terceiro",
    onTheFourth: "no quarto",
    onTheFifth: "no quinto",

};

const translateToPortuguese = (text: string): string => {
    let translatedText = text;
    Object.keys(customLocale).forEach((key) => {
        translatedText = translatedText.replace(new RegExp(`\\b${key}\\b`, "gi"), customLocale[key]);
    });
    return translatedText;
};

interface DatePickerRecurFieldProps {
    name: string;
    onForceBlur?: () => void;
}

const frequencyMap: { [key: string]: Frequency } = {
    DAILY: RRule.DAILY,
    WEEKLY: RRule.WEEKLY,
    MONTHLY: RRule.MONTHLY,
    YEARLY: RRule.YEARLY
};

const weekdayMap: { [key: string]: Weekday } = {
    MO: RRule.MO,
    TU: RRule.TU,
    WE: RRule.WE,
    TH: RRule.TH,
    FR: RRule.FR,
    SA: RRule.SA,
    SU: RRule.SU
};

export interface DatePickerRecurProps {
    dt_due?: Date;
    dt_start?: Date;
    recurrence?: string;
    next_occurrence?: Date;
    on_recurrence?: string;
    behavior?: string;
}

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    "& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom": {
        backgroundColor: "#1F2D32",
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}));

const DatePickerRecur: React.FC<DatePickerRecurFieldProps> = ({ name, onForceBlur }) => {

    const { fieldName, registerField, error, defaultValue } = useField(name);

    const dueDateInputPickerRef = useRef(null);
    const startDateInputPickerRef = useRef(null);
    const datepickerRef = useRef(null);

    const [dueDate, setDueDate] = useState<Date | null>((() => {
        const d = new Date();
        d.setDate(d.getDate() + 1);
        d.setHours(0, 0, 0, 0);
        return d;
    })());
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [date, setDate] = useState<Date | null>((() => {
        const d = new Date();
        d.setDate(d.getDate() + 1);
        d.setHours(0, 0, 0, 0);
        return d;
    })());

    const [isConfiguringRecur, setIsConfiguringRecur] = useState(false);
    const [selectedFrequency, setSelectedFrequency] = useState<string>("DAILY");
    const [intervalValue, setIntervalValue] = useState<number>(1);
    const [selectedWeekdays, setSelectedWeekdays] = useState<string[]>([]);
    const [monthlyDay, setMonthlyDay] = useState<number>(1);
    const [yearlyMonth, setYearlyMonth] = useState<number>(1);
    const [yearlyDay, setYearlyDay] = useState<number>(1);
    const [onField, setOnField] = useState<'dueDate' | 'startDate'>('dueDate');
    const [endType, setEndType] = useState<"none" | "until" | "count">("none");
    const [behavior, setBehavior] = useState<"new" | "back-step">("new");
    const [untilDate, setUntilDate] = useState<Date | null>(null);
    const [occurrencesCount, setOccurrencesCount] = useState<number>(5);
    const [showInterval, setShowInterval] = useState<boolean>(false);
    const [previewDates, setPreviewDates] = useState<Date[]>([]);
    const [showNextXDates] = useState<number>(100);
    const [hasRecurrence, setHasRecurrence] = useState<boolean>(false);
    const [savedRule, setSavedRule] = useState<RRule | null>(null);
    const [nextOccurrence, setNextOccurrence] = useState<Date | null>(null);
    const [recurDescription, setRecurDescription] = useState<string>('');
    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [showRecurrencesDialog, setShowRecurrencesDialog] = useState<boolean>(false);

    const setDateMainPicker = (date: Date) => {

        if (!isConfiguringRecur) {
            if (onField === 'dueDate') {
                if (startDate && date < startDate) {
                    setStartDate(date);
                    setOnField('dueDate');
                }
                setDueDate(date);
            } else {
                setStartDate(date);
                setOnField('dueDate');
                if (dueDate && date > dueDate) {
                    setDueDate(null);
                }
            }
        }

        setDate(date);

    }

    const handleFastPick = (daysToAdd: number, isNextMonth = false) => {
        const newDate = new Date();
        if (isNextMonth) {
            const currentDay = newDate.getDate();
            newDate.setMonth(newDate.getMonth() + 1);
            if (newDate.getDate() !== currentDay) {
                newDate.setDate(0);
            }
        } else {
            newDate.setDate(newDate.getDate() + daysToAdd);
        }

        // Reset time to 00:00:00
        newDate.setHours(0, 0, 0, 0);

        setDate(newDate);
        setDueDate(newDate);

        // If configuring recurrence, immediately update the rules
        if (isConfiguringRecur) {
            try {
                const rule = generateRRule();
                const nextDates = rule.all((d: Date, i: number) => i < showNextXDates);
                const adjustedDates = nextDates.map(d => adjustToLocalTimezone(d));
                setPreviewDates(adjustedDates);
            } catch (error) {
                console.warn("Erro ao gerar RRule:", error);
            }
        }
    };

    const adjustToLocalTimezone = (date: Date): Date => {
        if (!date) return date;
        const adjustedDate = new Date(date);
        return adjustedDate;
    };

    const formatDate = (date: Date) => {
        const adjustedDate = adjustToLocalTimezone(date);
        return adjustedDate.toLocaleDateString("pt-BR", { day: "2-digit", month: "2-digit" });
    };

    const handleRecurButtonClick = () => {
        // When entering recurrence configuration mode, set the initial date
        if (!isConfiguringRecur) {
            if (dueDate) {
                const newDateBase = new Date(dueDate);
                newDateBase.setHours(0, 0, 0, 0);

                setDate(newDateBase);
            } else {
                const newDateBase = new Date();
                newDateBase.setHours(0, 0, 0, 0);

                setDate(newDateBase);
            }
        }

        setIsConfiguringRecur(!isConfiguringRecur);
    };

    const handleFrequencyChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedFrequency(e.target.value);
    };

    const toggleWeekday = (day: string) => {
        if (selectedWeekdays.includes(day)) {
            setSelectedWeekdays(selectedWeekdays.filter(d => d !== day));
        } else {
            setSelectedWeekdays([...selectedWeekdays, day]);
        }
    };

    const generateRRule = () => {
        const baseDate = date ? new Date(date) : new Date();

        const config: any = {
            freq: frequencyMap[selectedFrequency],
            interval: intervalValue || 1,
            dtstart: baseDate,
        };

        if (selectedFrequency === "WEEKLY" && selectedWeekdays.length > 0) {
            config.byweekday = selectedWeekdays.map(dayStr => weekdayMap[dayStr]);
        }

        if (selectedFrequency === "MONTHLY") {
            config.bymonthday = [monthlyDay];
        }

        if (selectedFrequency === "YEARLY") {
            config.bymonth = yearlyMonth; // 1 até 12
            config.bymonthday = yearlyDay; // 1 até 31
        }

        if (endType === "until" && untilDate) {
            config.until = adjustToLocalTimezone(untilDate);
        } else if (endType === "count") {
            config.count = occurrencesCount || 5;
        }

        return new RRule(config);
    };

    const calculateDateRange = () => {
        if (!startDate || !dueDate) {
            return [];
        }

        const start = new Date(startDate);
        const end = new Date(dueDate);

        // If dates are in the wrong order, swap them
        if (start > end) {
            return [];
        }

        const dates: Date[] = [];
        const currentDate = new Date(start);

        // Add all dates from start to end (inclusive)
        while (currentDate <= end) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    };

    const handleSaveRecurrence = () => {
        try {
            const rule = generateRRule();
            setSavedRule(rule);
            setHasRecurrence(true);

            // Get text description using custom locale
            const description = translateToPortuguese(rule.toText());
            setRecurDescription(description);

            // Calculate next occurrence
            const next = rule.after(new Date(), true);
            setNextOccurrence(next ? adjustToLocalTimezone(next) : null);

            setIsConfiguringRecur(false);
        } catch (error) {
            console.error("Error saving recurrence rule:", error);
        }
    };

    const handleDeleteRecurrence = () => {
        setHasRecurrence(false);
        setSavedRule(null);
        setNextOccurrence(null);
        setRecurDescription('');
    };

    // Update the date setting function to handle the case when dueDate is earlier than startDate
    const handleDueDateChange = (date: Date | null) => {
        if (date) {
            // If startDate exists and the selected dueDate is earlier, update startDate too
            if (startDate && date < startDate) {
                setStartDate(date);
            }
        }
        setDueDate(date);
    };

    // Create a handler for startDate changes that also switches focus to dueDate
    const handleStartDateChange = useCallback((date: Date | null) => {
        setStartDate(date);
        // Automatically switch focus to dueDate after selecting startDate        
        setOnField('dueDate');
    }, []);

    // Add these handler functions
    const clearStartDate = (e: React.MouseEvent) => {
        e.stopPropagation();
        setStartDate(null);
    };

    const clearDueDate = (e: React.MouseEvent) => {
        e.stopPropagation();
        setDueDate(null);
    };

    // Function to format date in Brazilian format with time
    const formatDateWithTime = (date: Date) => {
        return date.toLocaleString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    // Function to open the dialog showing all recurrences
    const handleShowAllRecurrences = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);  // Set to beginning of today

        if (hasRecurrence && savedRule && !isConfiguringRecur) {
            try {
                // Use the saved rule to calculate future occurrences                
                const nextDates = savedRule.all(
                    (d: Date, i: number) => i < showNextXDates
                );
                setPreviewDates(nextDates.filter(d => d >= today));
            } catch (error) {
                console.warn("Erro ao calcular ocorrências futuras:", error);
                setPreviewDates([]);
            }
        } else if (isConfiguringRecur) {
            // If in configuration mode, use the current settings to generate preview
            try {
                const rule = generateRRule();
                const nextDates = rule.all(
                    (d: Date, i: number) => i < showNextXDates
                );

                setPreviewDates(nextDates.filter(d => d >= today));
            } catch (error) {
                console.warn("Erro ao gerar visualização de datas:", error);
                setPreviewDates([]);
            }
        }

        // Open the dialog
        setShowRecurrencesDialog(true);
    };

    // Function to close the dialog
    const handleCloseDialog = () => {
        setShowRecurrencesDialog(false);
    };

    useEffect(() => {
        if (!isConfiguringRecur) {
            setPreviewDates([]);
            return;
        }

        try {
            const rule = generateRRule();
            const nextDates = rule.all((d: Date, i: number) => i < showNextXDates);

            setPreviewDates(nextDates);
        } catch (error) {
            console.warn("Erro ao gerar RRule:", error);
            setPreviewDates([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isConfiguringRecur,
        selectedFrequency,
        intervalValue,
        selectedWeekdays,
        monthlyDay,
        yearlyMonth,
        yearlyDay,
        date,
        endType,
        untilDate,
        occurrencesCount,
        showNextXDates
    ]);

    // Update the date range whenever startDate or dueDate changes
    useEffect(() => {
        if (!isConfiguringRecur) {
            const newDateRange = calculateDateRange();
            setDateRange(newDateRange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, dueDate, isConfiguringRecur]);

    // Clear date range when entering recurrence config mode
    useEffect(() => {
        if (isConfiguringRecur) {
            setDateRange([]);
        } else {
            const newDateRange = calculateDateRange();
            setDateRange(newDateRange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConfiguringRecur]);

    // Add this helper function to extract only valid RRule options
    const extractRRuleOptions = (rule: RRule) => {
        // These are the properties that RRule constructor accepts
        const options = rule.options;
        return {
            freq: options.freq,
            interval: options.interval,
            dtstart: options.dtstart,
            until: options.until,
            count: options.count,
            bymonth: options.bymonth,
            bymonthday: options.bymonthday,
            byyearday: options.byyearday,
            byweekday: options.byweekday,
            byweekno: options.byweekno,
            byhour: options.byhour,
            byminute: options.byminute,
            bysecond: options.bysecond,
            bysetpos: options.bysetpos,
            wkst: options.wkst
        };
    };

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: dueDateInputPickerRef.current,
            getValue: () => {
                const newObj: DatePickerRecurProps = {
                    dt_due: dueDate !== null ? dueDate : undefined,
                    dt_start: startDate !== null ? startDate : undefined,
                    // Only save the valid options, not the entire rule
                    recurrence: savedRule ? JSON.stringify(extractRRuleOptions(savedRule)) : undefined,
                    next_occurrence: nextOccurrence !== null ? nextOccurrence : undefined,
                    on_recurrence: savedRule !== null ? "S" : undefined,
                    behavior: behavior !== undefined ? behavior : undefined
                }

                return newObj;
            },
            setValue: (refs, value) => {
                if (value !== undefined && value !== null) {

                    let newValue: DatePickerRecurProps = value;

                    if (newValue.dt_due !== undefined) {
                        setDueDate(newValue.dt_due);
                        setDate(newValue.dt_due);
                    }

                    if (newValue.dt_start !== undefined) {
                        setStartDate(newValue.dt_start);
                    }

                    // Properly parse and create RRule with only valid options
                    if (newValue.recurrence !== undefined && newValue.recurrence !== null) {
                        try {
                            const options = JSON.parse(newValue.recurrence);
                            // If dtstart is a string, convert it to a Date object
                            if (options.dtstart && typeof options.dtstart === 'string') {
                                options.dtstart = new Date(options.dtstart);
                            }
                            // Same for until if it exists
                            if (options.until && typeof options.until === 'string') {
                                options.until = new Date(options.until);
                            }
                            // Create new RRule with the parsed options
                            setSavedRule(new RRule(options));
                            setHasRecurrence(true);

                            // Generate description text and set other related state
                            const rule = new RRule(options);
                            const description = translateToPortuguese(rule.toText());
                            setRecurDescription(description);

                            // Calculate next occurrence
                            const next = rule.after(new Date(), true);
                            setNextOccurrence(next ? adjustToLocalTimezone(next) : null);
                        } catch (error) {
                            console.error("Error parsing recurrence rule:", error);
                        }
                    }

                    if (newValue.behavior !== undefined) {
                        setBehavior(newValue.behavior as "new" | "back-step");
                    }

                }
            },
            clearValue: () => {
                setDueDate(null);
                setStartDate(null);
                setSavedRule(null);
                setNextOccurrence(null);
                setBehavior("new");

                const newDate = new Date();
                newDate.setHours(0, 0, 0, 0);
                setDate(newDate);
            }
        });
    }, [fieldName, registerField, defaultValue, dueDate, startDate, savedRule, nextOccurrence, behavior]);

    useEffect(() => {

        if (defaultValue !== undefined && defaultValue !== null) {

            let newValue: DatePickerRecurProps = defaultValue;

            if (newValue.dt_due !== undefined) {
                setDueDate(newValue.dt_due);
                setDate(newValue.dt_due);
            }

            if (newValue.dt_start !== undefined) {
                setStartDate(newValue.dt_start);
            }

            // Update this part to properly create RRule from saved options
            if (newValue.recurrence !== undefined && newValue.recurrence !== null) {
                try {
                    const options = JSON.parse(newValue.recurrence);

                    // Convert date strings to Date objects
                    if (options.dtstart && typeof options.dtstart === 'string') {
                        options.dtstart = new Date(options.dtstart);
                    }
                    if (options.until && typeof options.until === 'string') {
                        options.until = new Date(options.until);
                    }

                    const rule = new RRule(options);
                    setSavedRule(rule);
                    setHasRecurrence(true);

                    // Generate description text
                    const description = translateToPortuguese(rule.toText());
                    setRecurDescription(description);
                } catch (error) {
                    console.error("Error parsing recurrence rule:", error);
                }
            }

            if (newValue.next_occurrence !== undefined) {
                setNextOccurrence(newValue.next_occurrence);
            }

            if (newValue.behavior !== undefined) {
                setBehavior(newValue.behavior as "new" | "back-step");
            }

        }

    }, [defaultValue]);

    return (
        <Container>
            <Top>
                <DarkTooltip title={
                    <React.Fragment>
                        <div>Insira uma data de início para este cartão</div>
                    </React.Fragment>
                } disableHoverListener={startDate !== null} disableFocusListener={startDate !== null} disableTouchListener={startDate !== null}>
                    <LeftPicker isSelected={onField === 'startDate' && !isConfiguringRecur}>
                        <FaRegCalendarAlt />
                        <ReactDatePicker
                            ref={startDateInputPickerRef}
                            selected={startDate}
                            onChange={handleStartDateChange}
                            onFocus={() => setOnField('startDate')}
                            placeholderText={"Data de Início"}
                            dateFormat={"dd/MM/yyyy"}
                            locale={pt}
                            showTimeSelect={false}
                            calendarClassName="calendar-picker-start"
                            tabIndex={0}
                        />
                        {startDate && (
                            <DarkTooltip title={
                                <React.Fragment>
                                    <div>Remover a data de vencimento</div>
                                </React.Fragment>
                            }>
                                <ClearIcon className="clear-icon" onClick={clearStartDate}>
                                    <IoMdCloseCircle />
                                </ClearIcon>
                            </DarkTooltip>
                        )}
                    </LeftPicker>
                </DarkTooltip>
                <DarkTooltip title={
                    <React.Fragment>
                        <div>Insira uma data de vencimento para este cartão</div>
                    </React.Fragment>
                } disableHoverListener={dueDate !== null} disableTouchListener={dueDate !== null} disableFocusListener={dueDate !== null}>
                    <RightPicker isSelected={onField === 'dueDate' && !isConfiguringRecur}>
                        <FaRegCalendarAlt />
                        <ReactDatePicker
                            ref={dueDateInputPickerRef}
                            selected={dueDate}
                            onChange={handleDueDateChange}
                            onFocus={() => setOnField('dueDate')}
                            placeholderText={"Data de Vencimento"}
                            dateFormat={"dd/MM/yyyy"}
                            locale={pt}
                            calendarClassName="calendar-picker-due"
                            tabIndex={1}
                        />
                        {dueDate && (
                            <DarkTooltip title={
                                <React.Fragment>
                                    <div>Remover a data de vencimento</div>
                                </React.Fragment>
                            }>
                                <ClearIcon className="clear-icon" onClick={clearDueDate}>
                                    <IoMdCloseCircle />
                                </ClearIcon>
                            </DarkTooltip>
                        )}
                    </RightPicker>
                </DarkTooltip>
            </Top>

            <Body>
                <LeftContainer>
                    <div>
                        {!isConfiguringRecur && !hasRecurrence && (
                            <>
                                <FastPickButtons>
                                    <FastPickButton onClick={() => handleFastPick(0)}>
                                        Hoje
                                        <FastPickButtonDetail>
                                            {formatDate(new Date())}
                                        </FastPickButtonDetail>
                                    </FastPickButton>
                                    <FastPickButton onClick={() => handleFastPick(1)}>
                                        Amanhã
                                        <FastPickButtonDetail>
                                            {formatDate(new Date(new Date().setDate(new Date().getDate() + 1)))}
                                        </FastPickButtonDetail>
                                    </FastPickButton>
                                    <FastPickButton onClick={() => handleFastPick(2)}>
                                        Depois de amanhã
                                        <FastPickButtonDetail>
                                            {formatDate(new Date(new Date().setDate(new Date().getDate() + 2)))}
                                        </FastPickButtonDetail>
                                    </FastPickButton>
                                    <FastPickButton onClick={() => handleFastPick(7)}>
                                        Próxima semana
                                        <FastPickButtonDetail>
                                            {formatDate(new Date(new Date().setDate(new Date().getDate() + 7)))}
                                        </FastPickButtonDetail>
                                    </FastPickButton>
                                    <FastPickButton onClick={() => handleFastPick(0, true)}>
                                        Mês que vem
                                        <FastPickButtonDetail>
                                            {formatDate(
                                                new Date(new Date().setMonth(new Date().getMonth() + 1))
                                            )}
                                        </FastPickButtonDetail>
                                    </FastPickButton>
                                </FastPickButtons>

                                <RecurButtonContainer>
                                    <RecurButton onClick={handleRecurButtonClick}>
                                        <TbRotateClockwise />
                                        Adicionar recorrência
                                    </RecurButton>
                                </RecurButtonContainer>
                            </>
                        )}

                        {!isConfiguringRecur && hasRecurrence && (
                            <RecurContainer>
                                <RecurTitle>Recorrência configurada</RecurTitle>

                                <RecurSummaryContainer>
                                    <RecurDescription>
                                        <RecurDescriptionTitle>
                                            <TbRotateClockwise />
                                            {"Acontece "}
                                        </RecurDescriptionTitle>
                                        <RecurDescriptionText>
                                            {recurDescription}
                                        </RecurDescriptionText>
                                    </RecurDescription>

                                    {nextOccurrence && (
                                        <RecurDescription>
                                            <RecurDescriptionTitle>
                                                <TbCalendar />
                                                {"Próxima execução: "}
                                            </RecurDescriptionTitle>
                                            <RecurDescriptionText>
                                                {nextOccurrence.toLocaleDateString('pt-BR')}{" "}
                                            </RecurDescriptionText>
                                        </RecurDescription>
                                    )}

                                    <RecurDescription>
                                        <RecurDescriptionTitle>
                                            <BsLightningFill size={12} />
                                            {"Comportamento: "}
                                        </RecurDescriptionTitle>
                                        <RecurDescriptionText>
                                            {behavior === "new" ? "Cria um novo cartão" : "Retorna para a etapa inicial"}
                                        </RecurDescriptionText>
                                    </RecurDescription>
                                </RecurSummaryContainer>

                                <RecurItemDetail>
                                    <RecurItemDetailBtn onClick={handleShowAllRecurrences}>
                                        Ver próximas ocorrências
                                    </RecurItemDetailBtn>
                                </RecurItemDetail>

                                <RecurButtonGroup>
                                    <BtnConfirm
                                        style={{ backgroundColor: "gray" }}
                                        onClick={handleDeleteRecurrence}
                                    >
                                        Remover
                                    </BtnConfirm>
                                </RecurButtonGroup>
                            </RecurContainer>
                        )}

                        {isConfiguringRecur && (
                            <RecurContainer>

                                <RecurTitle>
                                    Configuração da recorrência
                                </RecurTitle>

                                <RecurItemBox>
                                    <RecurItemBoxLabel>Repetir</RecurItemBoxLabel>
                                    <RecurItemBoxSelect
                                        value={selectedFrequency}
                                        onChange={handleFrequencyChange}
                                    >
                                        <RecurItemBoxSelectOpt value="DAILY">Diáriamente</RecurItemBoxSelectOpt>
                                        <RecurItemBoxSelectOpt value="WEEKLY">Semanalmente</RecurItemBoxSelectOpt>
                                        <RecurItemBoxSelectOpt value="MONTHLY">Mensalmente</RecurItemBoxSelectOpt>
                                        <RecurItemBoxSelectOpt value="YEARLY">Anualmente</RecurItemBoxSelectOpt>
                                    </RecurItemBoxSelect>
                                </RecurItemBox>

                                {showInterval ? (
                                    <RecurItemBox>
                                        <RecurItemBoxLabel>{"Com intervalo de (em "}
                                            {(() => {
                                                switch (selectedFrequency) {
                                                    case 'DAILY':
                                                        return intervalValue > 1 ? 'dias' : 'dias';
                                                    case 'WEEKLY':
                                                        return intervalValue > 1 ? 'sem.' : 'sem.';
                                                    case 'MONTHLY':
                                                        return intervalValue > 1 ? 'meses' : 'meses';
                                                    case 'YEARLY':
                                                        return intervalValue > 1 ? 'anos' : 'anos';
                                                    default:
                                                        return '';
                                                }
                                            })()}
                                            )
                                        </RecurItemBoxLabel>
                                        <RecurItemBoxInput
                                            type="number"
                                            min={1}
                                            value={intervalValue}
                                            onChange={(e) => setIntervalValue(Number(e.target.value))}
                                        />
                                    </RecurItemBox>) :
                                    (
                                        <RecurItemDetail>
                                            <RecurItemDetailBtn onClick={() => setShowInterval(true)}>Adicionar intervalo</RecurItemDetailBtn>
                                        </RecurItemDetail>
                                    )
                                }

                                {selectedFrequency === "WEEKLY" && (
                                    <RecurItemBox style={{ flexDirection: "column", alignItems: "flex-start" }}>
                                        <RecurItemBoxLabel>Nos dias</RecurItemBoxLabel>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            marginTop: "8px"
                                        }}>
                                            {[
                                                { code: "SU", label: "do" },
                                                { code: "MO", label: "2a" },
                                                { code: "TU", label: "3a" },
                                                { code: "WE", label: "4a" },
                                                { code: "TH", label: "5a" },
                                                { code: "FR", label: "6a" },
                                                { code: "SA", label: "sa" }
                                            ].map((day) => (
                                                <button
                                                    key={day.code}
                                                    type="button"
                                                    onClick={() => toggleWeekday(day.code)}
                                                    style={{
                                                        width: "30px",
                                                        height: "30px",
                                                        borderRadius: "50%",
                                                        backgroundColor: selectedWeekdays.includes(day.code) ? "#9337eda8" : "#e9ecef",
                                                        color: selectedWeekdays.includes(day.code) ? "white" : "#464646",
                                                        border: "none",
                                                        cursor: "pointer",
                                                        fontWeight: "bold",
                                                        fontSize: "12px"
                                                    }}
                                                >
                                                    {day.label}
                                                </button>
                                            ))}
                                        </div>
                                    </RecurItemBox>
                                )}

                                {selectedFrequency === "MONTHLY" && (
                                    <RecurItemBox>
                                        <RecurItemBoxLabel>No dia do mês</RecurItemBoxLabel>
                                        <RecurItemBoxInput
                                            type="number"
                                            min={1}
                                            max={31}
                                            value={monthlyDay}
                                            onChange={(e) => setMonthlyDay(Number(e.target.value))}
                                        />
                                    </RecurItemBox>
                                )}

                                {selectedFrequency === "YEARLY" && (
                                    <>
                                        <RecurItemBox>
                                            <RecurItemBoxLabel>No dia</RecurItemBoxLabel>
                                            <RecurItemBoxInput
                                                type="number"
                                                min={1}
                                                max={31}
                                                value={yearlyDay}
                                                onChange={(e) => setYearlyDay(Number(e.target.value))}
                                            />
                                        </RecurItemBox>
                                        <RecurItemBox>
                                            <RecurItemBoxLabel>Do mês</RecurItemBoxLabel>
                                            <RecurItemBoxInput
                                                type="number"
                                                min={1}
                                                max={12}
                                                value={yearlyMonth}
                                                onChange={(e) => setYearlyMonth(Number(e.target.value))}
                                            />
                                        </RecurItemBox>
                                    </>
                                )}

                                <RecurItemBox>
                                    <RecurItemBoxLabel>Termina</RecurItemBoxLabel>
                                    <RecurItemBoxSelect
                                        value={endType}
                                        onChange={(e) => setEndType(e.target.value as "none" | "until" | "count")}
                                    >
                                        <RecurItemBoxSelectOpt value="none">Repete para sempre</RecurItemBoxSelectOpt>
                                        <RecurItemBoxSelectOpt value="until">Na data</RecurItemBoxSelectOpt>
                                        <RecurItemBoxSelectOpt value="count">Após ocorrer em</RecurItemBoxSelectOpt>
                                    </RecurItemBoxSelect>
                                </RecurItemBox>

                                {endType === "until" && (
                                    <RecurItemBox>
                                        <RecurItemBoxLabel>Data final</RecurItemBoxLabel>
                                        <ReactDatePicker
                                            selected={untilDate}
                                            onChange={(date) => setUntilDate(date)}
                                            placeholderText="Selecione a data final"
                                            dateFormat="dd/MM/yyyy"
                                            locale={pt}
                                        />
                                    </RecurItemBox>
                                )}

                                {endType === "count" && (
                                    <RecurItemBox>
                                        <RecurItemBoxLabel>Número de ocorrências</RecurItemBoxLabel>
                                        <RecurItemBoxInput
                                            type="number"
                                            min={1}
                                            value={occurrencesCount}
                                            onChange={(e) => setOccurrencesCount(Number(e.target.value))}
                                        />
                                    </RecurItemBox>
                                )}

                                <RecurItemDetail>
                                    <RecurItemDetailBtn onClick={handleShowAllRecurrences}>
                                        Ver próximas ocorrências
                                    </RecurItemDetailBtn>
                                </RecurItemDetail>

                                <RecurItemBox>
                                    <RecurItemBoxLabel>Comportamento</RecurItemBoxLabel>
                                    <RecurItemBoxSelect
                                        value={behavior}
                                        onChange={(e) => setBehavior(e.target.value as "new" | "back-step")}
                                    >
                                        <RecurItemBoxSelectOpt value="new">Cria um novo cartão</RecurItemBoxSelectOpt>
                                        <RecurItemBoxSelectOpt value="back-step">Retorna para etapa inicial</RecurItemBoxSelectOpt>
                                    </RecurItemBoxSelect>
                                </RecurItemBox>

                                <div style={{ display: "flex", gap: "10px", marginTop: '15px !important' }}>
                                    <BtnConfirm onClick={handleSaveRecurrence}>
                                        {hasRecurrence ? 'Atualizar' : 'Adicionar'}
                                    </BtnConfirm>
                                    <BtnConfirm style={{ backgroundColor: "gray" }} onClick={() => setIsConfiguringRecur(false)}>Cancelar</BtnConfirm>
                                </div>
                            </RecurContainer>
                        )}
                    </div>
                </LeftContainer>

                <RightContainer>
                    <ReactDatePicker
                        ref={datepickerRef}
                        selected={date}
                        onChange={setDateMainPicker}
                        dateFormat={"dd/MM/yyyy HH:mm"}
                        timeFormat={"HH:mm"}
                        locale={pt}
                        showTimeInput={true}
                        timeInputLabel="Hora"
                        timeCaption="Hora"
                        inline
                        highlightDates={isConfiguringRecur ? previewDates : dateRange}
                        dayClassName={(date) => {
                            if (isConfiguringRecur) {
                                return previewDates.some(d =>
                                    d.getDate() === date.getDate() &&
                                    d.getMonth() === date.getMonth() &&
                                    d.getFullYear() === date.getFullYear()
                                ) ? "recurring-date" : null;
                            } else {
                                return dateRange.some(d =>
                                    d.getDate() === date.getDate() &&
                                    d.getMonth() === date.getMonth() &&
                                    d.getFullYear() === date.getFullYear()
                                ) ? "date-range" : null;
                            }
                        }}
                    />
                </RightContainer>
            </Body>

            {showRecurrencesDialog && (
                <DialogOverlay
                    onClick={handleCloseDialog}
                >
                    <Dialog onClick={(e) => e.stopPropagation()}>
                        <DialogHeader>
                            <h3>Próximas ocorrências</h3>
                            <button onClick={(e) => {
                                e.stopPropagation();
                                handleCloseDialog();
                            }}>
                                <TiTimes />
                            </button>
                        </DialogHeader>
                        <DialogContent>
                            {previewDates.length > 0 ? (
                                <ul>
                                    {previewDates.slice(0, 30).map((date, index) => (
                                        <li key={index}>{formatDateWithTime(date)}</li>
                                    ))}
                                    {previewDates.length > 30 && (
                                        <li style={{ fontStyle: 'italic', color: '#666' }}>
                                            E mais {previewDates.length - 30} ocorrências...
                                        </li>
                                    )}
                                </ul>
                            ) : (
                                <p>Nenhuma ocorrência encontrada.</p>
                            )}
                        </DialogContent>
                    </Dialog>
                </DialogOverlay>
            )}

            {error && (<ErrorDescription title={error} />)}
        </Container>
    );
};

export default DatePickerRecur;