import { FaFileAlt, FaFileAudio, FaFileCode, FaFileExcel, FaFileImage, FaFilePdf, FaFilePowerpoint, FaFileSignature, FaFileVideo, FaFileWord } from "react-icons/fa";
import { IconType } from "react-icons";

export const MAX_SIZE_ATTACH = 700 * 1024 * 1024;

export const allowedMimes = [
    '',
    'text/plain',
    'application/octet-stream',
    'image/jpeg',
    'image/heic',
    'image/pjpeg',
    'image/png',
    'image/gif',
    'image/x-icon',
    'image/vnd.dwg',
    'image/x-dwg',
    'text/plain',
    'text/xml',
    'audio/mpeg3',
    'audio/x-mpeg-3',
    'video/mpeg',
    'video/x-mpeg',
    'application/pdf',
    'application/msword',
    'application/acad',
    'application/mspowerpoint',
    'application/powerpoint',
    'application/vnd.ms-powerpoint',
    'application/x-mspowerpoint',
    'application/excel',
    'application/x-excel',
    'application/x-xls',
    'application/xls',
    'application/vnd.ms-excel.sheet.macroenabled.12',
    'application/octet-stream',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/x-dos_ms_excel',
    'application/x-msexcel',
    'application/x-ms-excel',
    'application/msexcel',
    'application/vnd.ms-excel',
    'application/xml',
    'video/mp4',
    'video/mpeg',
    'video/quicktime',
    'text/csv',
    'audio/aac',
    'audio/mp4',
    'audio/mpeg',
    'audio/amr',
    'audio/ogg',
    'message/rfc822'
];

export default function getIconAttachment(type: string): IconType {

    if (type.substring(0, 6) === "image/") {
        return FaFileImage;
    }

    if (type.substring(0, 6) === "audio/") {
        return FaFileAudio;
    }

    if (type.substring(0, 6) === "video/") {
        return FaFileVideo;
    }

    switch (type) {
        case "text/plain":
            return FaFileSignature;
        case "text/xml":
            return FaFileCode;
        case "application/msword":
            return FaFileWord;
        case "application/mspowerpoint":
            return FaFilePowerpoint;
        case "application/powerpoint":
            return FaFilePowerpoint;
        case "application/vnd.ms-powerpoint":
            return FaFilePowerpoint;
        case "application/x-mspowerpoint":
            return FaFilePowerpoint;
        case "application/excel":
            return FaFileExcel;
        case "application/x-excel":
            return FaFileExcel;
        case "application/x-msexcel":
            return FaFileExcel;
        case "application/vnd.ms-excel":
            return FaFileExcel;
        case "application/xml":
            return FaFileCode;
        case "application/pdf":
            return FaFilePdf;
        default:
            return FaFileAlt;
    }

}

