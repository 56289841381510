import React, { useEffect, useState } from "react";

import {
    Container,
    MiniContainer,
    BigContainer,
    CounterContainer,
    Divider,
    ActionArea,
    UpgradeButton,
    HelpButton,
    BadgeTrial,
    CloseButton
} from "./style";
import TrialCounter from "../TrialCounter";
import { IoRocket } from "react-icons/io5";
import { IoIosCloseCircle, IoIosVideocam, IoLogoWhatsapp } from "react-icons/io";
import { CompanyPlan } from "../../interfaces/CompanyPlan";
import { useMediaQuery } from "react-responsive";
import ChoosePlan from "../../dialogs/Billing/ChoosePlan";

interface TrialAssistantProps {
    companyPlan: CompanyPlan;
}

const linkWhatsapp = "https://api.whatsapp.com/send?phone=551151989897&text=Ol%C3%A1!%20Estou%20fazendo%20o%20Trial%20e%20estou%20com%20d%C3%BAvida,%20pode%20me%20ajudar?";
const linkVideo = "https://www.youtube.com/watch?v=kj6E9lOBw2Y" 

const TrialAssistant: React.FC<TrialAssistantProps> = ({ companyPlan }) => {

    const [isHovered, setIsHovered] = useState(false);
    const [daysLeft, setDaysLeft] = useState<number>(0);
    const [color, setColor] = useState<string>('#2ee31e');
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const [openBilling, setOpenBilling] = useState(false);

    useEffect(() => {

        const today = new Date();
        const trialEnd = new Date(companyPlan.dt_expires);
        const diffTime = trialEnd.getTime() - today.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays > 0) {
            setDaysLeft(diffDays);
        } else {
            setDaysLeft(0);
        }

        //Color rule
        if (diffDays <= 5) {
            setColor('#f23a5d');
        } else if (diffDays <= 10) {
            setColor('#fe8d2a');
        } else {
            setColor('#2ee31e');
        }

    }, [companyPlan, daysLeft]);

    return (
        <Container

        >

            <ChoosePlan
                open={openBilling}
                onClose={() => setOpenBilling(false)}
            />

            {isHovered ?
                <BigContainer
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <CounterContainer>
                        <CloseButton onClick={() => setIsHovered(false)}><IoIosCloseCircle /></CloseButton>
                        <BadgeTrial>Trial</BadgeTrial>
                        <h3 style={{ marginBottom: '13px' }}>Você ainda possui</h3>
                        <TrialCounter
                            days={daysLeft}
                            size={'mid'}
                            color={color}
                        />
                        <h3 style={{ marginTop: '13px' }}>{daysLeft === 1 ? "dia restante" : "dias restantes"}</h3>
                    </CounterContainer>
                    <Divider />
                    <ActionArea>
                        <UpgradeButton onClick={() => setOpenBilling(true)}>
                            <IoRocket />
                            Fazer o upgrade
                        </UpgradeButton>
                    </ActionArea>
                    <Divider />
                    <ActionArea>
                        <HelpButton href={linkVideo} target="_blank" style={{ textDecoration: 'none', marginBottom: '15px' }} rel="noopener noreferrer">
                            <IoIosVideocam />
                            Vídeo aulas
                        </HelpButton>
                        <HelpButton href={linkWhatsapp} target="_blank" style={{ textDecoration: 'none' }} rel="noopener noreferrer">
                            <IoLogoWhatsapp />
                            Preciso de ajuda
                        </HelpButton>
                    </ActionArea>


                </BigContainer> :
                <MiniContainer
                    onMouseEnter={() => isMobile ? {} : setIsHovered(true)}
                    onMouseLeave={() => isMobile ? {} : setIsHovered(false)}
                    onClick={() => setIsHovered(true)}
                >
                    <TrialCounter
                        days={daysLeft}
                        size={'min'}
                        color={color}
                    />
                </MiniContainer>

            }
        </Container>
    );

}

export default TrialAssistant;