import Modal from '@material-ui/core/Modal';
import React, { useEffect } from "react";

import Button from "../../components/Button";
import {
    DialogBody,
    DialogContainer,
    DialogContent,
    DialogFooter,
    DialogHeader
} from "./styles";

interface DialogProps {
    title: string;
    value: string;
    status: boolean;
    handleDialog(): void;
}

const DialogInfo: React.FC<DialogProps> = ({ title, value, status, handleDialog }) => {

    const [open, setOpen] = React.useState(status);

    useEffect(() => {
        setOpen(status);
    }, [status]);

    return (
        <Modal
            open={open}
            onClose={handleDialog}
            style={{ zIndex: '999999' }}
        >
            <DialogContainer>
                <DialogContent>
                    <DialogHeader>
                        <h1>{title}</h1>
                    </DialogHeader>
                    <DialogBody dangerouslySetInnerHTML={{ __html: value.replace(/\r?\n|\r/g, "<br/>") }} />
                    <DialogFooter>
                        <Button type="submit" color="#2390eb" onClick={handleDialog}>Ok</Button>
                    </DialogFooter>
                </DialogContent>
            </DialogContainer>
        </Modal>

    );
};

export default DialogInfo;
