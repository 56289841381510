import Grid from '@material-ui/core/Grid';
import styled, { css } from "styled-components";

interface LogHeaderIconProps {
    color?: string;
}

export const Container = styled.div`
    height: 100%;
    max-height: calc(100vh - 70px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

export const ContainerLeft = styled(Grid)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

export const ContainerRight = styled(Grid)`
    @media(max-width: 959px){
        display: none !important;
    }
    
`;

export const LogGroupItem = styled.div`
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    padding: 15px;
    padding-right: 10px;
    padding-top: 0px;
    padding-left: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const LogGroupLine = styled.div`
    border-bottom: 1px solid #9e37ec;
    width: calc(100% - 10px);
    position: absolute;
    border-radius: 50%;
    //top: 50%;
`;

export const LogGroupTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #9e37ec;
    border-radius: 10px;
    border: 1px solid #9e37ec;
    color: white;
    z-index: 10;
    padding: 5px 15px;
    font-size: 13px;
`;

export const LogGroupTitleIcon = styled.div`
    margin-right: 5px;
    margin-top: 2px;
    font-size: 12px;
`;

export const LogGroupTitleText = styled.div`
    font-weight: 500;
`;

export const LogItems = styled.div`
    width: 100%;
    padding-right: 15px;
    padding-left: 10px;
`;

export const LogArea = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;

    :hover {

        .log-container {
            box-shadow: 0 0 6px rgb(0 0 0 / 20%);
        }

        .log-action {
            box-shadow: 0 0 6px rgb(0 0 0 / 20%);
            background-color: #9e37ed;

            svg {
                color: white;
            }
        }
        
    }

`;

export const LogContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    width: 100%;    
`;

export const LogHeader = styled.div`
    border-bottom: 1px solid #f4f5f7;
    padding: 5px;
    margin-bottom: 10px;
    cursor: pointer;
`;

export const LogHeaderIcon = styled.div<LogHeaderIconProps>`
    height: 10px;
    width: 10px;
    border-radius: 3px;
    background-color: #9e37ed;

    ${props => props.color && css`
        background-color: ${props.color};
    `}
`;

export const LogHeaderInfo = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
`;

export const LogHeaderTitle = styled.div`
    color: #252525;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

export const NotificationHeaderOrigin = styled.div`
    margin-left: 5px;
    font-size: 12px;
    color: #4d4d4d;
`;

export const LogHeaderOrigin = styled.div`
    margin-left: 5px;
    font-size: 12px;
    color: #4d4d4d;
`;

export const LogHeaderCardDeleted = styled.div`
    font-size: 10px;
    padding: 3px 7px;
    background-color: red;
    color: white;
    border-radius: 5px;
    margin-left: 10px;
`;

export const LogHeaderCardLog = styled.div`
    font-size: 10px;
    padding: 3px 7px;
    background-color: gray;
    color: white;
    border-radius: 5px;
    margin-right: 10px;
`;

export const LogBody = styled.div`
    color: black;
`;

export const LogHistoryItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
`;

export const LogHistoryAvatar = styled.div`
    margin-right: 5px;
`;

export const LogHistoryUserName = styled.div`
    font-weight: 500;
    color: black;
    margin-right: 5px;
`;

export const LogHistoryDescription = styled.div`

`;

export const LogHistoryChildren = styled.button`
    cursor: pointer;
    font-size: 13px;
    margin-top: 10px;
    font-weight: 400;
    background: transparent;
    border: none;
    color: #9337ed;
`;

export const LogHistoryDate = styled.label`
    color: gray;
    font-weight: 500;
    font-size: 12px;
`;

export const LogHistoryExpanded = styled.div`
    margin-top: 5px;
`;

export const LogGroup = styled.div`
    width: 100%;
`;

export const ChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const ChartItem = styled.div`
    width: 100%;
    background: white;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
`;

export const ChartItemTitle = styled.div`
    color: black;
    font-weight: 500;
    font-size: 15px;
    border-bottom: 1px solid #f4f5f7;
    padding-bottom: 10px;
`;

export const ChartItemBody = styled.div`
    padding: 10px;
`;

export const HelpText = styled.button`
  margin-left: 5px;
  display: inline-block;
  background-color: transparent;
  border: none;

  svg {
    margin: 0;
    font-size: 13px;
  }
`;

export const ContainerLoader = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 95vh;
`;

export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        height: 30% !important;
        max-width: 150px;
    }
    
`;

export const HelpContainer = styled.div`
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #ff8c2f1e;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const HelpItems = styled.div`
    padding: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    height: 100%;
`;
export const HelpItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
`;
export const HelpIcon = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-right: 15px;

    svg {
        font-size: 25px;
        color: #ff8c2f;
    }
`;

export const HelpDescription = styled.div`
        width: calc(100% - 30px);
    font-size: 13px;
    display: block;
    justify-content: flex-start;
    align-self: center;
`;