import * as Yup from 'yup';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaLock } from 'react-icons/fa';
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { Link, useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

import Button from '../../components/Button';
import Input from '../../components/Forms/Fields/Components/Input';
import LoaderCange from '../Loader/LoaderCange';
import api from '../../services/api';
import checked from '../../assets/checked.png';
import getValidationErrors from '../../utils/getValidationErrors';
import logoWendata from '../../assets/wendata-logo-white.png';
import { AnimationContainer, Container, ContainerSucess, Content, NoAccount } from './styles';
import { useToast } from '../../hooks/toast';

interface RecoveryFormData {
    password: string;
    password_confirm: string;
}

interface RouteParams {
    hash: string;
}

const ChangePassword: React.FC = () => {

    const formRef = useRef<FormHandles>(null);
    const { params } = useRouteMatch<RouteParams>();
    const { addToast } = useToast();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const [sucess, setSucess] = useState<boolean>(false);

    const handleSubmit = useCallback(async (data: RecoveryFormData) => {


        //Check if the password is the same
        if (data.password !== data.password_confirm) {
            formRef.current?.setErrors({ password_confirm: 'As senhas não são iguais!' });
            return;
        }

        try {

            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                password: Yup.string()
                    .required('O campo senha é obrigatório!')
                    .min(8, 'O campo senha deve conter mais de 8 caracteres!'),
                password_confirm: Yup.string()
                    .required('O campo de confirmação de senha é obrigatório!')
                    .min(8, 'O campo de confirmação de senha deve conter mais de 8 caracteres!'),
            });

            await schema.validate(data, {
                abortEarly: false,
            });

            setLoading(true);

            //Recovery api
            await api
                .post('/session/recovery-password', {
                    hash: params.hash,
                    password: data.password
                })
                .then(response => {

                    setSucess(true);

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao recuperar a senha!',
                        description: 'Verifique a senha digitada, caso não tenha sucesso, entre em contato com o suporte!',
                    });
                });

            setLoading(false);
        } catch (err) {
            setLoading(false);
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);
                formRef.current?.setErrors(errors);
            }

            console.log(err);

            addToast({
                type: 'error',
                title: 'Erro no reconhecimento do e-mail',
                description: 'Ocorreu um erro ao encontrar o seu e-mail, verifique o e-mail digitado!',
            });
        }

    }, [addToast, params.hash]);

    useEffect(() => {

        api.get('/session/recovery?hash=' + params.hash).then(response => {

            setLoading(false);

        }).catch(error => {
            history.push('/recovery');
            addToast({
                type: 'error',
                title: 'Erro ao encontrar instruções!',
                description: 'Ocorreu um erro ao tentar buscar as instruções para troca de senha!',
            });
        });


    }, [params.hash, addToast, history]);

    return (
        <>
            <title>Alterar Senha | Cange</title>
            {loading ? <LoaderCange /> :
                <Container>

                    <img src={logoWendata} alt="wendata" />
                    <Content>

                        <AnimationContainer>
                            {!sucess ?
                                <Form ref={formRef} onSubmit={handleSubmit}>

                                    <h1>Esqueceu a sua senha?</h1>
                                    <h3>Fique tranquilo! Isso acontece com todo mundo. Digite abaixo sua nova senha <b>com mais de 8 caracteres</b> e confirme &#x1F600;</h3>

                                    <Input tabIndex={0} name="password" type="password" icon={FaLock} placeholder="Digite sua nova senha" />
                                    <Input tabIndex={0} name="password_confirm" type="password" icon={FaLock} placeholder="Confirme a sua nova senha" />

                                    <Button tabIndex={0} type="submit" height="56px">Salvar minha nova senha</Button>

                                    <NoAccount tabIndex={-1}>Quer voltar para o Login?
                                        <Link to="/" style={{ color: '#14aaf5', paddingLeft: '5px' }}>
                                            Clique aqui!
                                        </Link>
                                    </NoAccount>

                                </Form> :
                                <ContainerSucess>

                                    <h1>Senha alterada! &#x270C;</h1>

                                    <img src={checked} alt="wendata" />

                                    <h3>Feito! Salvamos sua nova senha. Volte para a página de login e aproveite! &#x1F609;</h3>

                                    <NoAccount tabIndex={-1}>Quer voltar para o Login?
                                        <Link to="/" style={{ color: '#14aaf5', paddingLeft: '5px' }}>
                                            Clique aqui!
                                        </Link>
                                    </NoAccount>

                                </ContainerSucess>
                            }
                        </AnimationContainer>
                    </Content>
                </Container>
            }
        </>
    );
}

export default ChangePassword;