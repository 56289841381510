import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    Container,
    NotificationContainer,
    NotificationHeader,
    NotificationHeaderIcon,
    NotificationHeaderInfo,
    NotificationHeaderTitle,
    NotificationHeaderOrigin,
    NotificationBody,
    ContainerNotifications,
    NotificationArea,
    NotificationAction,
    ContainerEmpty,
    Animation
} from "./style";
import api from "../../../services/api";
import EmptyNotification from "../../../components/EmptyState/EmptyNotification";
import { Notification } from "../../../interfaces/Notification";
import { FaCheck, FaLevelUpAlt } from "react-icons/fa";
import HistoryItem from "../../../components/Colaboration/History/HistoryItem";
import { useToast } from "../../../hooks/toast";
import { Card } from "../../../interfaces/Card";
import { Flow } from "../../../interfaces/Flow";
import { KanbanConfigProps } from "../../../components/Kanban";
import { CardDialogContainer } from "../../Flow/styles";
import getKanbanConfig from "../../../utils/KanbanConfig/getKanbanConfig";
import { useMediaQuery } from "react-responsive";
import { ContainerLoader } from "./style";
import Lottie from "lottie-web";

interface NotificationTabProps {
    isNotRead?: boolean;
}

const NotificationTab: React.FC<NotificationTabProps> = ({ isNotRead }) => {

    const { addToast } = useToast();

    const [notifications, setNotifications] = useState<Notification[]>([]);

    const [cardSelected, setCardSelected] = useState<Card>();
    const [flowSelected, setFlowSelected] = useState<Flow>();
    const [kanbanConfig, setKanbanConfig] = useState<KanbanConfigProps>();
    const [openCard, setOpenCard] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const lottieContainerBig = useRef<HTMLDivElement>(null);

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    const getApiFlow = useCallback(async (id_flow: number) => {

        await api.get(`/flow`, {
            params: {
                id_flow: id_flow,
                withSteps: true,
                withCards: false,
                removeStepsCondicionals: true,
            }
        }).then(response => {
            if (response.data !== null) {

                const respFlow: Flow = response.data;

                if (respFlow !== undefined) {

                    setFlowSelected(respFlow);
                    setKanbanConfig(getKanbanConfig(respFlow));

                }
            }

        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Erro ao carregar o Fluxo',
                description: 'Ocorreu ao tentar carregar o Fluxo!',
            });
        });

    }, [addToast]);

    const getNotificationsApi = useCallback(async (isArchived: boolean) => {

        setLoading(true);

        await api.get(`/notification/by-user`, {
            params: {
                isArchived: isArchived ? 'N' : 'S',
            }
        }).then(response => {
            if (response.data !== null) {

                const respNotifications: Notification[] = response.data;

                if (respNotifications !== undefined) {

                    setNotifications(respNotifications);

                }
            }

            setLoading(false);

        }).catch(error => {
            console.log(error);
            addToast({
                type: 'error',
                title: 'Erro ao carregar as notificações',
                description: 'Ocorreu um erro ao carregar as notificações!',
            });
            setLoading(false);
        });

    }, [addToast]);

    const postNotificationsApi = useCallback(async (id_notification: number, archived: string) => {

        await api
            .post('/notification', {
                id_notification,
                archived: archived
            })
            .then(response => {

                if (response.data !== null) {

                    const respNotifications: Notification = response.data;

                    if (respNotifications !== undefined) {

                        const newNotifications = notifications.map((notification) => {
                            if (notification.id_notification === respNotifications.id_notification) {

                                //Update the fields changeds
                                notification.archived = respNotifications.archived;

                            }
                            return notification;
                        });

                        setNotifications(newNotifications);

                    }
                }

            }).catch(error => {
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Erro ao editar a notificação',
                    description: 'Ocorreu um erro ao editar a notificação!',
                });
            });

    }, [addToast, notifications]);

    const cardOpen = useCallback(async (card: Card) => {
        setCardSelected(card);
        getApiFlow(card.flow_id);
        setOpenCard(true);
    }, [getApiFlow]);

    const cardClose = useCallback(async () => {
        setOpenCard(false);
        setCardSelected(undefined);
        setFlowSelected(undefined);
        setKanbanConfig(undefined);

        getNotificationsApi(isNotRead !== undefined ? isNotRead : false);
    }, [getNotificationsApi, isNotRead]);

    useEffect(() => {

        getNotificationsApi(isNotRead !== undefined ? isNotRead : false);

    }, [getNotificationsApi, isNotRead]);

    useEffect(() => {
        if (lottieContainerBig.current) {
            Lottie.loadAnimation({
                container: lottieContainerBig.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../assets/lottie/loader.json')
            })
        }
    }, [loading]);


    return (
        <Container>

            {loading ?
                <ContainerLoader>
                    <Animation className="lottieContainerBig" ref={lottieContainerBig} />
                </ContainerLoader> :
                notifications === undefined || notifications.filter((n) => {

                    if (isNotRead) {
                        return n.archived === undefined || n.archived === "N" || n.archived === null;
                    } else {
                        return true;
                    }

                }).length === 0 ?
                    <ContainerEmpty>
                        <EmptyNotification />
                    </ContainerEmpty> :

                    <ContainerNotifications>
                        {notifications && notifications.filter((n) => {

                            if (isNotRead) {
                                return n.archived === undefined || n.archived === "N" || n.archived === null;
                            } else {
                                return true;
                            }

                        }).map((notification) => {

                            return (
                                <NotificationArea
                                    key={notification.id_notification}
                                >
                                    <NotificationContainer key={notification.id_notification} className="notification-container" onClick={() => cardOpen(notification.card)}>
                                        <NotificationHeader>
                                            <NotificationHeaderInfo>
                                                <NotificationHeaderIcon color={notification.card !== undefined && notification.card.flow !== undefined && notification.card.flow.color !== undefined ? notification.card.flow?.color : "red"}>

                                                </NotificationHeaderIcon>
                                                <NotificationHeaderOrigin>
                                                    {notification.card?.flow?.name}
                                                </NotificationHeaderOrigin>
                                            </NotificationHeaderInfo>
                                            <NotificationHeaderTitle>
                                                {notification.card?.title}
                                            </NotificationHeaderTitle>
                                        </NotificationHeader>
                                        <NotificationBody>

                                            {notification.type === "comment_mention" && notification.card_comment !== undefined ?
                                                <HistoryItem
                                                    key={notification.card_comment.id_card_comment}
                                                    id={0}
                                                    cardComment={notification.card_comment}
                                                    typeUser="V"
                                                /> :
                                                <></>
                                            }

                                        </NotificationBody>
                                    </NotificationContainer>

                                    <NotificationAction
                                        className="notification-action"
                                        onClick={() => postNotificationsApi(notification.id_notification, notification.archived === "N" || notification.archived === undefined || notification.archived === null ? "S" : "N")}
                                    >
                                        {notification.archived === "S" ? <FaLevelUpAlt /> : <FaCheck />}
                                    </NotificationAction>
                                </NotificationArea>
                            );
                        })}
                    </ContainerNotifications>
            }

            {openCard && cardSelected !== undefined && flowSelected !== undefined && (
                <CardDialogContainer
                    open={openCard}
                    isMobile={isMobile}
                    flow={flowSelected}
                    kanban_config={kanbanConfig}
                    selectedValue={cardSelected}
                    onClose={cardClose}
                    typeUser={flowSelected.typeUserAccess !== undefined ? flowSelected.typeUserAccess : ""}
                    showFlowDetail={true}
                />
            )}

        </Container>
    );

}

export default NotificationTab;