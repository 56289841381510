import React from "react";

import {
    BackgroundGradient,
    Container,
    UpgradeButton,
    UpgradeButton2,
} from "./style";
import ChoosePlan from "../../../dialogs/Billing/ChoosePlan";
import { useAuth } from "../../../hooks/auth";

interface ButtonUpgradeProps {
    model?: 'btn1' | 'btn2';
    buttonText: string;
}

// btn1 - Button all gradient
// btn2 - Button with border

const ButtonUpgrade: React.FC<ButtonUpgradeProps> = ({ buttonText, model }) => {

    const { renewUser } = useAuth();

    const [openBilling, setOpenBilling] = React.useState(false);

    return (
        <Container>

            {model === 'btn2' ?
                <BackgroundGradient>
                    <UpgradeButton2 type="button" onClick={() => setOpenBilling(true)}>
                        {buttonText}
                    </UpgradeButton2>
                </BackgroundGradient> :
                <UpgradeButton type="button" onClick={() => setOpenBilling(true)}>
                    {buttonText}
                </UpgradeButton>
            }

            <ChoosePlan
                open={openBilling}
                onClose={() => {
                    renewUser();
                    setOpenBilling(false);
                }}
                isEndTrial={false}
            />

        </Container >
    );

}

export default ButtonUpgrade;