
import { FieldProps } from "../components/Forms/Fields/FieldBuilder";
import { FormAnswer } from "../interfaces/FormAnswer";
import { FormAnswerField } from "../interfaces/FormAnswerField";
import api from "../services/api";
import { deepCopy } from "./deepCopy";

interface FormAnswerByCardApi {
    card_id: number;
    flow_id: number;
    field_id: number;
    child_field_id?: number;
    formAnswer?: FormAnswer;
}

interface AcRequestBodyApi {
    card_id: number;
    field_items: {
        flow_id: number;
        field_id: number;
        child_field_id?: number;
    }[];
}

const getApiAc = async (card_id: number, flow_id: number, field_id: number, ac_child_field_id?: number): Promise<FormAnswer | undefined> => {

    try {
        const responseAnswer = await api.get(`/form/answer/by-card`, {
            params: {
                card_id: card_id,
                flow_id: flow_id,
                field_id: field_id,
                child_field_id: ac_child_field_id
            }
        });

        if (responseAnswer !== undefined && responseAnswer.data !== null) {
            const faApi: FormAnswer = responseAnswer.data;

            return faApi;
        } else {
            return undefined
        }
    } catch (error) {
        return undefined;
    }

};

const getFormAnswerByCardApi = async (requestBody: AcRequestBodyApi): Promise<FormAnswerByCardApi[] | undefined> => {

    try {
        const responseAnswer = await api
            .post(`/form/answers/by-cards`,
                requestBody
            );

        if (responseAnswer !== undefined && responseAnswer.data !== null) {
            const faApi: FormAnswerByCardApi[] = responseAnswer.data;

            return faApi;
        } else {
            return undefined
        }
    } catch (error) {
        return undefined;
    }

};

export default async function getAutoCompleteRule(origem: 'combo' | 'answer', fields: FieldProps[], user_id: number, user_id_creator: number, card_id?: number, flow_id?: number): Promise<FormAnswerField[]> {

    let newFormAnswerField: FormAnswerField[] = [];
    let newItensField: { flow_id: number, field_id: number, child_field_id?: number }[] = [];
    let autoCompleteItems: FormAnswerByCardApi[] | undefined = undefined;

    //Validate the needs to get the auto complete on the backend
    if (card_id !== undefined) {
        if (origem === 'answer') {

            for (let index = 0; index < fields.length; index++) {
                const f = fields[index];

                if (f.ac_type === 0) { //AC - Dynamic
                    if (f.ac_parent_field_id !== undefined) { //If has a parent field
                        if (f.ac_parent_field_id > 0 && card_id !== undefined && flow_id !== undefined) {

                            newItensField.push({
                                flow_id: flow_id,
                                field_id: f.ac_parent_field_id,
                                child_field_id: f.ac_child_field_id !== undefined && f.ac_child_field_id !== null ? f.ac_child_field_id : undefined
                            });

                        }
                    }
                }
            }

            if (newItensField.length > 0) {
                //Get the api items to get the auto complete
                autoCompleteItems = await getFormAnswerByCardApi({
                    card_id: card_id,
                    field_items: newItensField
                });
            }

        } else if (origem === 'combo') {

            for (let index = 0; index < fields.length; index++) {
                const f = fields[index];

                if (f.map_ac !== undefined && f.map_ac !== null && f.map_ac.length > 0) {
                    const mapAc = f.map_ac[0];

                    if (mapAc.ac_type === 0) { //AC - Dynamic
                        if (mapAc.ac_parent_field_id !== undefined) {
                            if (mapAc.ac_parent_field_id > 0 && card_id !== undefined && mapAc.flow_map_id !== undefined) {
                                newItensField.push({
                                    flow_id: mapAc.flow_map_id,
                                    field_id: mapAc.ac_parent_field_id,
                                    child_field_id: mapAc.ac_child_field_id !== undefined && mapAc.ac_child_field_id !== null ? mapAc.ac_child_field_id : undefined
                                });
                            }
                        }
                    }
                }
            }

            if (newItensField.length > 0) {
                //Get the api items to get the auto complete
                autoCompleteItems = await getFormAnswerByCardApi({
                    card_id: card_id,
                    field_items: newItensField
                });
            }

        }
    }

    for (let index = 0; index < fields.length; index++) {
        const f = fields[index];

        if (origem === 'answer') {
            //If has a auto_complete, mount the object
            if (f.ac_type !== undefined) {

                if (f.ac_type === 0) { //AC - Dynamic

                    if (f.ac_parent_field_id !== undefined) { //If has a parent field

                        if (f.ac_parent_field_id < 0) { //Formula Fields

                            if (f.ac_parent_field_id === -1 && f.id_field !== undefined) { //Date Now

                                const faf: FormAnswerField = {
                                    id_form_answer_field: 1,
                                    form_answer_id: 1,
                                    index: 0,
                                    field: f,
                                    field_id: f.id_field,
                                    value: new Date().toISOString(),
                                    valueString: new Date().toISOString(),
                                }

                                newFormAnswerField.push(faf);

                            } else if (f.ac_parent_field_id === -2 && f.id_field !== undefined) { //Current User

                                const faf: FormAnswerField = {
                                    id_form_answer_field: 2,
                                    form_answer_id: 2,
                                    index: 0,
                                    field: f,
                                    field_id: f.id_field,
                                    value: user_id + "",
                                    valueString: user_id + "",
                                }

                                newFormAnswerField.push(faf);

                            } else if (f.ac_parent_field_id === -3 && f.id_field !== undefined) { //Card Creator

                                const faf: FormAnswerField = {
                                    id_form_answer_field: 3,
                                    form_answer_id: 3,
                                    index: 0,
                                    field: f,
                                    field_id: f.id_field,
                                    value: user_id_creator + "",
                                    valueString: user_id_creator + "",
                                }

                                newFormAnswerField.push(faf);

                            }


                        } else if (f.ac_parent_field_id > 0 && card_id !== undefined && flow_id !== undefined) {

                            let faApi: FormAnswer | undefined = undefined;

                            //Check if has the auto complete items, if has, get the right one, else get from the api
                            if (autoCompleteItems !== undefined) {
                                const findAcItems = autoCompleteItems.filter((item) => item.card_id === card_id && item.flow_id === flow_id && item.field_id === f.ac_parent_field_id);

                                //Check if found more than one
                                if (findAcItems !== undefined && findAcItems.length > 1) {
                                    //Get the right one if has a child field
                                    if (f.ac_child_field_id !== undefined && f.ac_child_field_id !== null) {
                                        const findChild = findAcItems.filter((item) => item.child_field_id === f.ac_child_field_id);
                                        if (findChild !== undefined && findChild.length > 0) {
                                            faApi = findChild[0].formAnswer;
                                        }
                                    } else { //When has no child field, get without child field
                                        const findChild = findAcItems.filter((item) => item.child_field_id === undefined || item.child_field_id === null);
                                        if (findChild !== undefined && findChild.length > 0) {
                                            faApi = findChild[0].formAnswer;
                                        }
                                    }
                                } else if (findAcItems !== undefined && findAcItems.length === 1) {
                                    faApi = findAcItems[0].formAnswer;
                                }
                            } else {
                                faApi = await getApiAc(card_id, flow_id, f.ac_parent_field_id, f.ac_child_field_id);
                            }

                            if (faApi !== undefined && faApi.form_answer_fields !== undefined) {
                                for (let idxB = 0; idxB < faApi.form_answer_fields.length; idxB++) {
                                    const newFaf = faApi.form_answer_fields[idxB];

                                    if (f.type === "COMBO_BOX_FIELD" || f.type === "RADIO_BOX_FIELD" || f.type === "CHECK_BOX_FIELD") {

                                        //Find the same label to put the value right
                                        const target = newFaf.valueString !== undefined ? newFaf.valueString : "";
                                        const optTarget = f.options?.filter((opt) => opt.label !== undefined && opt.label.toLocaleLowerCase() === target.toLocaleLowerCase());

                                        if (optTarget !== undefined && optTarget.length > 0) {
                                            newFaf.field = f;
                                            newFaf.field_id = f.id_field !== undefined ? f.id_field : newFaf.field_id;
                                            newFaf.value = optTarget[0].value;

                                            newFormAnswerField.push(deepCopy(newFaf));
                                        }

                                    } else {

                                        newFaf.field = f;
                                        newFaf.field_id = f.id_field !== undefined ? f.id_field : newFaf.field_id;

                                        newFormAnswerField.push(deepCopy(newFaf));

                                    }
                                }
                            }

                        }

                    }

                } else if (f.ac_type === 1 && f.auto_complete !== undefined) { //AC - Static

                    const newFafs: FormAnswerField[] = f.auto_complete.form_answer_fields;
                    if (newFafs !== undefined) {
                        for (let idxB = 0; idxB < newFafs.length; idxB++) {
                            const faf = newFafs[idxB];

                            faf.field = f;
                            faf.field_id = f.id_field !== undefined ? f.id_field : faf.field_id;

                            newFormAnswerField.push(faf);
                        }
                    }

                }

            }
        } else if (origem === 'combo') {

            //Map Ac - Auto Complete
            if (f.map_ac !== undefined && f.map_ac !== null && f.map_ac.length > 0) {
                const mapAc = f.map_ac[0];

                if (mapAc.ac_type === 0) { //AC - Dynamic

                    if (mapAc.ac_parent_field_id !== undefined) {

                        if (mapAc.ac_parent_field_id < 0) { //Formula Fields

                            if (mapAc.ac_parent_field_id === -1 && f.id_field !== undefined) { //Date Now

                                const faf: FormAnswerField = {
                                    id_form_answer_field: 1,
                                    form_answer_id: 1,
                                    index: 0,
                                    field: f,
                                    field_id: f.id_field,
                                    value: new Date().toISOString(),
                                    valueString: new Date().toISOString(),
                                }

                                newFormAnswerField.push(faf);

                            } else if (mapAc.ac_parent_field_id === -2 && f.id_field !== undefined) { //Current User

                                const faf: FormAnswerField = {
                                    id_form_answer_field: 2,
                                    form_answer_id: 2,
                                    index: 0,
                                    field: f,
                                    field_id: f.id_field,
                                    value: user_id + "",
                                    valueString: user_id + "",
                                }

                                newFormAnswerField.push(faf);

                            } else if (mapAc.ac_parent_field_id === -3 && f.id_field !== undefined) { //Card Creator

                                const faf: FormAnswerField = {
                                    id_form_answer_field: 3,
                                    form_answer_id: 3,
                                    index: 0,
                                    field: f,
                                    field_id: f.id_field,
                                    value: user_id_creator + "",
                                    valueString: user_id_creator + "",
                                }

                                newFormAnswerField.push(faf);

                            }

                        } else if (card_id !== undefined && mapAc.flow_map_id !== undefined) {

                            let faApi: FormAnswer | undefined = undefined;

                            //Check if has the auto complete items, if has, get the right one, else get from the api
                            if (autoCompleteItems !== undefined) {
                                const findAcItems = autoCompleteItems.filter((item) => item.card_id === card_id && item.flow_id === mapAc.flow_map_id && item.field_id === mapAc.ac_parent_field_id);

                                //Check if found more than one
                                if (findAcItems !== undefined && findAcItems.length > 1) {
                                    //Get the right one if has a child field
                                    if (mapAc.ac_child_field_id !== undefined && mapAc.ac_child_field_id !== null) {
                                        const findChild = findAcItems.filter((item) => item.child_field_id === mapAc.ac_child_field_id);
                                        if (findChild !== undefined && findChild.length > 0) {
                                            faApi = findChild[0].formAnswer;
                                        }
                                    } else { //When has no child field, get without child field
                                        const findChild = findAcItems.filter((item) => item.child_field_id === undefined || item.child_field_id === null);
                                        if (findChild !== undefined && findChild.length > 0) {
                                            faApi = findChild[0].formAnswer;
                                        }
                                    }
                                } else if (findAcItems !== undefined && findAcItems.length === 1) {
                                    faApi = findAcItems[0].formAnswer;
                                }
                            } else {
                                faApi = await getApiAc(card_id, mapAc.flow_map_id, mapAc.ac_parent_field_id, mapAc.ac_child_field_id);
                            }

                            if (faApi !== undefined && faApi.form_answer_fields !== undefined) {
                                for (let idxB = 0; idxB < faApi.form_answer_fields.length; idxB++) {
                                    const newFaf = faApi.form_answer_fields[idxB];

                                    if (f.type === "COMBO_BOX_FIELD" || f.type === "RADIO_BOX_FIELD" || f.type === "CHECK_BOX_FIELD") {

                                        //Find the same label to put the value right
                                        const target = newFaf.valueString !== undefined ? newFaf.valueString : "";
                                        const optTarget = f.options?.filter((opt) => opt.label !== undefined && opt.label.toLocaleLowerCase() === target.toLocaleLowerCase());

                                        if (optTarget !== undefined && optTarget.length > 0) {
                                            newFaf.field = f;
                                            newFaf.field_id = f.id_field !== undefined ? f.id_field : newFaf.field_id;
                                            newFaf.value = optTarget[0].value;

                                            newFormAnswerField.push(newFaf);
                                        }

                                    } else {

                                        newFaf.field = f;
                                        newFaf.field_id = f.id_field !== undefined ? f.id_field : newFaf.field_id;

                                        newFormAnswerField.push(newFaf);

                                    }

                                }
                            }
                        }

                    }

                } else if (mapAc.ac_type === 1) { //AC - Static

                    if (mapAc.ac_form_answer_id !== undefined && mapAc.ac_form_answer_id !== null && mapAc.auto_complete !== undefined && mapAc.auto_complete !== null) {
                        const newFafs: FormAnswerField[] = mapAc.auto_complete.form_answer_fields !== undefined && mapAc.auto_complete.form_answer_fields !== null ? mapAc.auto_complete.form_answer_fields : [];
                        if (newFafs !== undefined) {
                            for (let idxB = 0; idxB < newFafs.length; idxB++) {
                                const newFaf = newFafs[idxB];

                                newFormAnswerField.push(newFaf);
                            }
                        }
                    }

                }

            }

        }

    }

    return newFormAnswerField;

}
