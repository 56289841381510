import React, { useCallback, useEffect, useRef, useState } from "react";
import api from "../../../../services/api";
import AvatarCange from "../../../../components/AvatarCange";
import { Animation, ContainerLoader, ContainerRank, RankAvatar, RankComments, RankItem, RankName, RankPoints, RankPosition, RankTimeTracking, RankingChildren } from "./style";
import { User } from "../../../../interfaces/User";
import lottie from "lottie-web";
import { TbClock, TbHistory, TbMessageCircle } from "react-icons/tb";
import { styled, Tooltip, TooltipProps } from "@material-ui/core";

interface RankingChartProps {
    flow_id: number;
    card_id: number;
}

interface RankingDataProps {
    user_id_user: number;
    user_name: string;
    user_color: string;
    user_company_id: number;
    user_email: string;
    user_avatar_id: number;
    user_avatar_url: string;
    total_activities: number;
    total_comments?: number;
    total_tracking_time?: number;
}

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    "& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom": {
        backgroundColor: "#1F2D32",
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}));

const formatDuration = (timeInSeconds: number): string => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

const RankingChart: React.FC<RankingChartProps> = ({ flow_id, card_id }) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<RankingDataProps[]>();
    const [usersToShow] = useState<number>(2);
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const lottieContainer = useRef<HTMLDivElement>(null);

    const getApiData = useCallback(async (id_flow: number, card_id: number) => {

        setLoading(true);

        api.get(`/activity/ranking/by-card`, {
            params: {
                flow_id: id_flow,
                card_id: card_id
            }
        }).then(response => {
            if (response.data !== null) {

                const newData = response.data as RankingDataProps[];

                setData(newData);

            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            console.log(error);
        });

    }, []);

    useEffect(() => {

        getApiData(flow_id, card_id);

    }, [flow_id, card_id, getApiData]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../../assets/lottie/loader.json')
            })
        }
    }, []);

    return (
        <>
            {loading ?
                <ContainerLoader>
                    <Animation className="lottieContainer" ref={lottieContainer} />
                </ContainerLoader > :
                <ContainerRank>
                    <RankItem isFirst={false} isHeader={true}>
                        <DarkTooltip title={
                            <React.Fragment>
                                <div>Total de horas trabalhadas neste cartão</div>
                            </React.Fragment>
                        }>
                            <RankTimeTracking style={{ right: '100px' }}><TbClock /></RankTimeTracking>
                        </DarkTooltip>
                        <DarkTooltip title={
                            <React.Fragment>
                                <div>Total de comentários inseridos neste cartão</div>
                            </React.Fragment>
                        }>
                            <RankComments style={{ right: '37px' }}><TbMessageCircle /></RankComments>
                        </DarkTooltip>
                        <DarkTooltip title={
                            <React.Fragment>
                                <div>Total de atividades neste cartão</div>
                            </React.Fragment>
                        }>
                            <RankPoints style={{ right: '-2px' }}><TbHistory /></RankPoints>
                        </DarkTooltip>
                    </RankItem>

                    {data?.slice(0, usersToShow).map((d, index) => {

                        const isFirst = index === 0;

                        return (
                            <RankItem key={d.user_id_user} isFirst={isFirst}>
                                <RankPosition>#{index + 1}</RankPosition>
                                <RankAvatar>
                                    <AvatarCange
                                        user={{
                                            id_user: d.user_id_user,
                                            name: d.user_name,
                                            color: d.user_color,
                                            company_id: d.user_company_id,
                                            email: d.user_email,
                                            avatar_id: d.user_avatar_id,
                                            avatar_url: d.user_avatar_url

                                        } as User}
                                        size={"35"}
                                    />
                                </RankAvatar>
                                <RankName>{d.user_name}</RankName>
                                {d.total_tracking_time && d.total_tracking_time > 0 && (
                                    <RankTimeTracking>{formatDuration(d.total_tracking_time)}</RankTimeTracking>
                                )}
                                {d.total_comments && d.total_comments > 0 && (
                                    <RankComments>{d.total_comments}</RankComments>
                                )}
                                <RankPoints>{d.total_activities}</RankPoints>
                            </RankItem>
                        )
                    })}
                    {data !== undefined && data.length > usersToShow && !collapsed ?
                        <RankingChildren onClick={() => setCollapsed(true)}>
                            Mostrar mais {data?.length - usersToShow} {data.length - usersToShow > 1 ? "usuários" : "usuário"}
                        </RankingChildren> : data !== undefined && data.length > usersToShow && collapsed ?
                            data?.slice(usersToShow, data.length).map((d, index) => {
                                return (
                                    <RankItem key={d.user_id_user} isFirst={false}>
                                        <RankPosition>#{index + usersToShow + 1}</RankPosition>
                                        <RankAvatar>
                                            <AvatarCange
                                                user={{
                                                    id_user: d.user_id_user,
                                                    name: d.user_name,
                                                    color: d.user_color,
                                                    company_id: d.user_company_id,
                                                    email: d.user_email,
                                                    avatar_id: d.user_avatar_id,
                                                    avatar_url: d.user_avatar_url

                                                } as User}
                                                size={"35"}
                                            />
                                        </RankAvatar>
                                        <RankName>{d.user_name}</RankName>
                                        {d.total_tracking_time && d.total_tracking_time > 0 && (
                                            <RankTimeTracking>{formatDuration(d.total_tracking_time)}</RankTimeTracking>
                                        )}
                                        {d.total_comments && d.total_comments > 0 && (
                                            <RankComments>{d.total_comments}</RankComments>
                                        )}
                                        <RankPoints>{d.total_activities}</RankPoints>
                                    </RankItem>
                                )
                            }) :
                            <></>
                    }
                </ContainerRank >
            }
        </>
    );

}

export default RankingChart;