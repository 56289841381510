import styled, { css } from "styled-components";

export const Container = styled.div`

`;

export const Top = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px !important;

    .calendar-picker-start {
        display: none;
    }

    .calendar-picker-due {
        display: none;
    }
    
    input {
        border: none;
        font-size: 14px;
        color: #000000c2;

        ::placeholder {
            color: gray;
        }

    }
`;

export const LeftPicker = styled.div<{ isSelected: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    border: 1px solid #f5ecfd;
    width: 240px;
    border-radius: 5px;
    margin-right: 5px;
    position: relative;

    svg {
        margin-right: 10px;
        color: #000000c2;
        margin-bottom: 2px;
    }

    :hover {
        border-color: #9337ed;
        color: #9337ed;

        svg {
            color: #9337ed;
        }
        
        .clear-icon {
            opacity: 1;
        }
    }

    ${props => props.isSelected && css`
        border-color: #9337ed;
        color: #9337ed;

        svg {
            color: #9337ed;
        }
    `}
`;

export const RightPicker = styled.div<{ isSelected: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    border: 1px solid #f5ecfd;
    width: 242px;
    border-radius: 5px;    
    margin-left: 5px;
    position: relative;

    svg {
        margin-right: 10px;
        color: #000000c2;
        margin-bottom: 2px;
    }

    :hover {
        border-color: #9337ed;
        color: #9337ed;

        svg {
            color: #9337ed;
        }
        
        .clear-icon {
            opacity: 1;
        }
    }

    ${props => props.isSelected && css`
        border-color: #9337ed;
        color: #9337ed;

        svg {
            color: #9337ed;
        }
    `}
`;

export const ClearIcon = styled.div`
    position: absolute;
    right: 10px;
    opacity: 0;
    transition: opacity 0.2s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    svg {
        margin-right: 0;
        font-size: 16px;
        color: #9337ed;
        margin-bottom: 0px !important;
    }
    
    &:hover svg {
        color: #722eb9;
    }
`;

export const Body = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    height: 100%;
    width: 250px;
    position: relative;
    border-right: 1px solid #f5ecfd;
    min-height: 300px;
`;

export const FastPickButtons = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 10px;
`;

export const FastPickButton = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    margin-left: 4px;
    position: relative;
    width: 100%;
    font-size: 14px;
    color: black;
    cursor: pointer;
    border-radius: 5px;    
    margin-bottom: 5px !important;

    :hover {
        background-color: #9337ed1e;
    }
`;

export const FastPickButtonDetail = styled.span`
    position: absolute;
    right: 10px;
    font-size: 11px;
    color: gray;
`;

export const RecurButton = styled.div`    
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #9337ed1a;
    padding: 10px;
    border-radius: 5px;
    width: 230px;
    height: 44px;
    color: #000000c2;
    cursor: pointer;

    svg {
        font-size: 17px;
        margin-right: 7px;
    }

    :hover {
        border-color: #9337ed;
        color: #9337ed;
    }
`;

export const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 250px;
  margin-top: 10px;

  .react-datepicker__day--keyboard-selected {
    background-color: white;
    color: black;
  }

  .react-datepicker__day--highlighted {
    background-color: #9337eda8;
    color: white;
  }

  .date-range {
    background-color: #f2f7ff;    
    color: #333;
  }

  .recurring-date {
    background-color: #9337eda8;
    color: white;
  }

  .react-datepicker-popper {
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    border-radius: 5px;
    padding: 10px;
    background-color: white;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    border: none;
  }
  
  .react-datepicker__time-container {
    border-left: 1px solid #eef2f8;
  }

  .react-datepicker__day-name {
    font-weight: 600;
  }

  .react-datepicker__day--outside-month {
    color: gray;
  }

  .react-datepicker__day--today {
    border-radius: 0.3rem;
    background-color: #ffecef;
    color: black;
  }

  .react-datepicker-time__caption {
    display: none;
  }

  .react-datepicker-time__input-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border: none;
    border-radius: 5px;
    padding: 5px;
  }

  .react-datepicker__input-time-container {
    margin-left: 0px;
  }

  .react-datepicker__day--selected {
    background-color: #f23b5d;
  }

  .react-datepicker__time-list-item--selected {
    background-color: #f23b5d !important;
  }

  .react-datepicker__time-list-item--selected:hover {
    background-color: #f23b5d !important;
  }

  .react-datepicker__day--selected {
    background-color: #f23b5d;
    color: white;
  }

  .react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker-time__header {
    font-weight: 500;
    font-size: 12.8px;
  }

  .react-datepicker__day-names {
    margin-top: 10px;
  }

  .react-datepicker__current-month::first-letter {
    text-transform: uppercase;
  }

  .react-datepicker-time__input {
      width: 100%;
      margin-left: 0px !important;
  }

  .react-datepicker-time__input input {
    border: 1px solid #eef2f8;
    border-radius: 5px;
    padding: 10px;
    width: 100% !important;
    text-align: center;
  }
`;

export const RecurButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const RecurItemBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 10px !important;

    .react-datepicker-popper {
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    border-radius: 5px;
    padding: 10px;
    background-color: white;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    border: none;
  }
  
  .react-datepicker__time-container {
    border-left: 1px solid #eef2f8;
  }

  .react-datepicker__day-name {
    font-weight: 600;
  }

  .react-datepicker__day--outside-month {
    color: gray;
  }

  .react-datepicker__day--today {
    border-radius: 0.3rem;
    background-color: #ffecef;
    color: black;
  }

  .react-datepicker-time__caption {
    display: none;
  }

  .react-datepicker-time__input-container {
    border: 1px solid gray;
    border-radius: 5px;
    padding: 5px;
  }

  .react-datepicker__input-time-container {
    margin-left: 5px;
  }

  .react-datepicker__day--selected {
    background-color: #f23b5d;
  }

  .react-datepicker__time-list-item--selected {
    background-color: #f23b5d !important;
  }

  .react-datepicker__time-list-item--selected:hover {
    background-color: #f23b5d !important;
  }

  .react-datepicker__day--selected {
    background-color: #ffecef;
    color: #000;
  }

  .react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker-time__header {
    font-weight: 500;
    font-size: 12.8px;
  }

  .react-datepicker__day-names {
    margin-top: 10px;
  }

  .react-datepicker__current-month::first-letter {
    text-transform: uppercase;
  }

  .react-datepicker__input-container {
    input {
        width: 100%;
        height: 35px;
        border-radius: 5px;
        border-width: 1px;
        padding: 5px;
        font-size: 14px;
        margin-top: 5px;
        border-color: #f5ecfd;
        font-family: 'Roboto';        
        border-style: solid;
    }
  }
`;

export const RecurItemBoxLabel = styled.div`
    color: black;
    font-size: 14px;    
    font-family: 'Roboto';
`;

export const RecurItemBoxSelect = styled.select`
    width: 100%;
    margin-top: 5px;
    border: 1px solid #f5ecfd;
    padding: 5px;
    border-radius: 5px;
    font-family: 'Roboto';
    font-size: 14px;
    height: 35px;
`;

export const RecurItemBoxInput = styled.input`    
    width: 100%;
    margin-top: 5px;
    border: 1px solid #f5ecfd;
    padding: 5px;
    border-radius: 5px;
    font-family: 'Roboto';
    font-size: 14px;
    height: 35px;
`;

export const RecurItemBoxSelectOpt = styled.option`

`;

export const RecurContainer = styled.div`
    padding: 10px;
    width: 100%;
`;

export const BtnConfirm = styled.button`
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    border: none !important;
    background-color: #9337ed;
    color: white;
    font-weight: 500;
`;

export const RecurTitle = styled.div`
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px !important;
`;

export const RecurSummaryContainer = styled.div`
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  margin-bottom: 5px !important;
`;

export const RecurDescription = styled.div`   
    margin-bottom: 10px !important; 
`;

export const RecurNextOccurrence = styled.p`
  font-size: 13px;
`;

export const RecurButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

export const RecurDescriptionTitle = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    color: gray;
    gap: 5px;
    margin-bottom: 5px !important;
`;

export const RecurDescriptionText = styled.div`

`;

export const RecurItemDetail = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px !important;
    margin-top: 0px;
    margin-left: 2px;
`;

export const RecurItemDetailBtn = styled.div`
    background-color: transparent;
    border: none;
    color: #9337ed;
    font-size: 13px;
    cursor: pointer;
`;

export const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const Dialog = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  max-width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

export const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;

  h3 {
    margin: 0;
    font-weight: 500;
  }

  button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #666;
    
    &:hover {
      color: #333;
    }
  }
`;

export const DialogContent = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  margin-bottom: 15px;
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    padding: 8px 0;
    border-bottom: 1px solid #f0f0f0;
    
    &:last-child {
      border-bottom: none;
    }
  }
`;