export function deepCopy<T>(obj: T): T {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    // Tratamento especial para instâncias de Date
    if (obj instanceof Date) {
        return new Date(obj.getTime()) as unknown as T;
    }

    // Tratamento especial para Arrays
    if (Array.isArray(obj)) {
        const arrCopy = [] as unknown as T;
        for (const item of obj) {
            (arrCopy as unknown as any[]).push(deepCopy(item));
        }
        return arrCopy;
    }

    // Tratamento genérico para objetos
    const objCopy = {} as T;
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            (objCopy as any)[key] = deepCopy((obj as any)[key]);
        }
    }

    return objCopy;
}