import styled, { css } from "styled-components";

interface RankItemProps {
    isFirst: boolean;
    isHeader?: boolean;
}

export const ContainerRank = styled.div`
    width: 100%;
`;

export const RankItem = styled.div<RankItemProps>`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: black;
    font-size: 14px;
    margin-top: 15px;

    ${props => props.isFirst && css`
        font-weight: 500;
        color: #9e37ee;
    `}

    ${props => props.isHeader && css`
        font-weight: 400;
        color: gray;
        font-size: 12px;
        margin-top: 5px;
    `}
`;

export const RankPosition = styled.div`
    margin-right: 10px;
    color: gray;
    font-size: 12px;
`;

export const RankAvatar = styled.div`
    margin-right: 10px;
`;

export const RankName = styled.div`
    margin-right: 5px;    
`;

export const RankPoints = styled.div`
    position: absolute;
    right: 0px;
    font-size: 15px;
    font-weight: 500;
`;

export const RankTimeTracking = styled.div`
    position: absolute;
    right: 80px;
    font-size: 15px;
    font-weight: 500;
`;

export const RankComments = styled.div`
    position: absolute;
    right: 40px;
    font-size: 15px;
    font-weight: 500;
`;

export const RankingChildren = styled.button`
    cursor: pointer;
    font-size: 13px;
    margin-top: 15px;
    font-weight: 400;
    background: transparent;
    border: none;
    color: #9337ed;
`;

export const ContainerLoader = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 200px;
`;

export const Animation = styled.div`
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
        height: 30% !important;
        max-width: 75px;
    }
    
`;