import AppBarHeader, { NavigationBarItemType } from "../../../components/AppBarHeader";
import {
    BreakArea,
    Container,
    ContainerFields,
    Content,
    ContentList,
    FormField
} from "./style";
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import {
    FieldProps,
    Fields
} from "../../../components/Forms/Fields/FieldBuilder";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from 'react-router-dom';

import { BiSave } from "react-icons/bi";
import { BsGearFill } from "react-icons/bs";
import Button from "../../../components/Button";
import { FormHandles } from "@unform/core";
import { IconList } from "react-fa-icon-picker";
import { IoMdListBox } from "react-icons/io";
import LayoutContainer from '../../../components/LayoutContainer';
import ListContainer from '../../../components/ListContainer';
import { RiFlowChart } from "react-icons/ri";
import { TiFlowMerge } from 'react-icons/ti';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

interface flowProps {
    name_flow: string;
    color_flow: string;
    icon_flow: string;
    button_add_name?: string;
    is_private?: string;
}

interface RouteParams {
    hash: string;
    origin: string;
}

const fieldsFlowForm: FieldProps[] = [
    {
        name: "name_flow",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 1,
        title: "Nome do fluxo",
        description: "Digite o nome do fluxo",
        placeholder: "Digite aqui...",
        help_text: "Neste campo é ideal que você escolha um nome claro para o seu fluxo, pois este nome que será exibido dentro do sistema para identifica-lo",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["O campo Nome do Fluxo é obrigatório!"]
            },
            {
                type: "min",
                params: [2, "O campo Nome deve conter mais de 2 letras!"]
            }
        ]
    },
    {
        name: "color_flow",
        type: Fields.COLOR_PICKER_FIELD,
        required: false,
        index: 2,
        title: "Cor do fluxo",
        description: "Escolha uma cor para o seu fluxo"
    },
    {
        name: "icon_flow",
        type: Fields.ICON_PICKER_FIELD,
        required: false,
        index: 3,
        title: "Ícone do fluxo",
        description: "Escolha um ícone para o seu fluxo"
    },
    {
        name: "button_add_name",
        type: Fields.TEXT_SHORT_FIELD,
        required: false,
        index: 4,
        title: "Texto do botão 'Novo Item'",
        description: "Personalize o botão para adicionar um novo item",
        max_length: 15
    },
    {
        name: "is_private",
        type: Fields.CHECK_BOX_ONE_FIELD,
        required: false,
        index: 5,
        title: "Permissões do fluxo",
        description: "Quando ativo permite que somente usuários convidados acessem o fluxo",
        placeholder: "Fluxo Privado"
    }

    //Texto do botão de 'Criar Card'
]

const flowDefault: flowProps = {
    color_flow: "#f23b5c",
    icon_flow: "FaCodeBranch",
    name_flow: "",
    button_add_name: "",
    is_private: "1"
}

const FirstStep: React.FC = () => {

    const { params } = useRouteMatch<RouteParams>();

    const { addToast } = useToast();

    const history = useHistory();

    const formRefFlow = useRef<FormHandles>(null);

    const [flowApi, setFlowApi] = useState(flowDefault);
    const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSavePage = useCallback(async (urlRedirect?: string) => {

        if (loading) {
        } else if (formRefFlow.current !== null && formRefFlow.current !== undefined) {

            const dataNormalized = formRefFlow.current.getData() as unknown as flowProps;

            if (dataNormalized.name_flow === undefined || dataNormalized.name_flow === null || dataNormalized.name_flow.trim() === "") {

                addToast({
                    type: 'error',
                    title: 'Erro ao salvar',
                    description: 'O campo Nome do Fluxo é obrigatório!',
                });

                return;
            } else {

                setLoadingInsert(true);
                await api
                    .post('/flow', {
                        hash: params.hash,
                        name: dataNormalized.name_flow,
                        color: dataNormalized.color_flow,
                        icon: dataNormalized.icon_flow,
                        button_add_name: dataNormalized.button_add_name,
                        isPrivate: dataNormalized.is_private
                    })
                    .then(response => {

                        if (urlRedirect !== undefined) {
                            history.push(urlRedirect);
                        }
                        setLoadingInsert(false);
                    }).catch(error => {
                        setLoadingInsert(false);
                        addToast({
                            type: 'error',
                            title: 'Erro ao buscar o cadastro',
                            description: 'Ocorreu um erro ao carregar o cadastro!',
                        });
                    });

            }

        }

    }, [history, addToast, params.hash, loading]);

    const handleSubmitPage = useCallback(async () => {
        if (formRefFlow.current !== undefined) {
            formRefFlow.current?.submitForm();
        }
    }, []);

    const handleBackEditPage = useCallback(async () => {
        history.push('/flow/' + params.hash);
    }, [history, params.hash]);

    const handleSubmitForm = useCallback(async (data: object[]) => {

        if (params.origin === "new") {
            handleSavePage('/flow/2/' + params.hash + "/new")
        } else {
            handleSavePage('/flow/' + params.hash)
        }

    }, [params.hash, params.origin, handleSavePage]);

    const goToFormInitTab = useCallback(async () => {

        if (params.origin === "new") {
            handleSavePage("/flow/2/" + params.hash + "/new");
        } else {
            handleSavePage("/flow/2/" + params.hash + "/edit");
        }

    }, [params.origin, params.hash, handleSavePage]);

    const goToStepsTab = useCallback(async () => {

        if (params.origin === "new") {
            handleSavePage("/flow/3/" + params.hash + "/new");
        } else {
            handleSavePage("/flow/3/" + params.hash + "/edit");
        }

    }, [params.origin, params.hash, handleSavePage]);

    const navBarItems: NavigationBarItemType[] = [
        {
            index: 1,
            description: "Geral",
            icon: BsGearFill,
            active: true,
            onClick: () => { }
        },
        {
            index: 2,
            description: "Formulário Inicial",
            icon: IoMdListBox,
            onClick: () => goToFormInitTab()
        },
        {
            index: 3,
            description: "Etapas",
            icon: RiFlowChart,
            onClick: () => goToStepsTab()
        }
    ]

    const navBarItemsDefault: NavigationBarItemType[] = [
        {
            index: 1,
            description: "Geral",
            icon: BsGearFill,
            active: true,
            onClick: () => { }
        }
    ]

    useEffect(() => {

        if (formRefFlow.current !== null) {
            formRefFlow.current.setData(flowApi);
        }

    }, [flowApi]);

    useEffect(() => {

        setLoading(true);
        api.get(`/flow`, {
            params: {
                hash: params.hash
            }
        }).then(response => {

            const flowApiReturn: flowProps = {
                name_flow: response.data.name !== null ? response.data.name : flowDefault.name_flow,
                icon_flow: response.data.icon !== null ? response.data.icon : flowDefault.icon_flow,
                color_flow: response.data.color !== null ? response.data.color : flowDefault.color_flow,
                button_add_name: response.data.button_add_name !== null ? response.data.button_add_name : flowDefault.button_add_name,
                is_private: response.data.isPrivate !== null ? response.data.isPrivate : flowDefault.is_private
            }

            setFlowApi(flowApiReturn);
            setLoading(false);

        }).catch(error => {
            setLoading(false);
            if (params.origin !== "new") {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar o cadastro',
                    description: 'Ocorreu um erro ao carregar o cadastro!',
                });
            }
        });

    }, [addToast, params.hash, params.origin]);

    useEffect(() => {
        //Valid if close the window
        window.onbeforeunload = confirmExit;
        function confirmExit() {
            return "show warning";
        }
    }, [])

    return (
        <LayoutContainer>
            <title>{flowApi.name_flow !== undefined ? flowApi.name_flow : "Novo Fluxo"} | Cange</title>
            <ListContainer>

                {/* Header Page */}
                <AppBarHeader
                    icon={params.origin === "new" ? TiFlowMerge : BsGearFill}
                    iconDynamic={flowApi !== undefined ? flowApi.icon_flow as IconList : undefined}
                    iconDynamicColor={flowApi !== undefined ? flowApi.color_flow : undefined}
                    title={params.origin === "new" ? "Novo Fluxo" : flowApi !== undefined ? flowApi.name_flow : "Configurações Gerais"}
                    navBarItems={params.origin === "new" ? navBarItemsDefault : navBarItems}
                    isRoundedCorners={true}
                >
                    {params.origin === "edit" ?
                        <Button
                            color="gray"
                            height="36px"
                            margin="0px 30px 0px 0px"
                            icon={FiArrowLeft}
                            icon_side="left"
                            onClick={handleBackEditPage}
                        >
                            Voltar
                        </Button> : <></>
                    }

                    {params.origin === "new" ?
                        <Button
                            type="submit"
                            color="#23cd78"
                            height="36px"
                            margin="0px 30px 0px 0px"
                            icon={FiArrowRight}
                            icon_side="right"
                            onClick={handleSubmitPage}
                            isLoading={loadingInsert}
                        >
                            Avançar
                        </Button> :
                        <Button
                            type="submit"
                            color="#23cd78"
                            height="36px"
                            margin="0px 30px 0px 0px"
                            icon={BiSave}
                            onClick={handleSubmitPage}
                            isLoading={loadingInsert}
                        >
                            Salvar
                        </Button>
                    }
                </AppBarHeader>

                {/* Inputs Form */}
                <Container>
                    <Content container>

                        {/* Forms */}
                        <ContainerFields item xs={12} md={6}>

                            <ContentList>

                                <BreakArea />

                                <FormField
                                    id="formFirstStep"
                                    formRef={formRefFlow}
                                    fields={fieldsFlowForm}
                                    handleSubmit={handleSubmitForm}
                                    hideContainer={true}
                                />

                            </ContentList>

                        </ContainerFields>
                    </Content>
                </Container>



            </ListContainer>

        </LayoutContainer>

    );
}

export default FirstStep;