import AppBarHeader, { NavigationBarItemType } from '../../../components/AppBarHeader';
import {
    BreakArea,
    ButtonNextStep,
    Container,
    ContainerAdrress,
    ContainerFields,
    ContainerPreview,
    Content,
    ContentList,
    CopyBtn,
    Footer,
    FormPreview,
    TitleAddress
} from "./style";
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import {
    FieldOptionsProps,
    FieldProps,
    Fields as FieldItems
} from "../../../components/Forms/Fields/FieldBuilder";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from 'react-router-dom';

import { BiSave } from "react-icons/bi";
import { BsGearFill } from "react-icons/bs";
import ContentLoader from "react-content-loader"
import ContentPreview from "../../../components/ContentPreview";
import Description from "../../../components/Description";
import Fields from "../../../components/Fields";
import { Flow } from "../../../interfaces/Flow";
import { Form } from "../../../interfaces/Form";
import { FormHandles } from "@unform/core";
import { IconList } from "react-fa-icon-picker";
import { IoMdListBox } from "react-icons/io";
import LayoutContainer from '../../../components/LayoutContainer';
import ListContainer from '../../../components/ListContainer';
import { RiFlowChart } from "react-icons/ri";
import { TiFlowMerge } from 'react-icons/ti';
import Title from "../../../components/Title";
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { TbCheck, TbCircleKey, TbCopy } from 'react-icons/tb';
import { useAuth } from '../../../hooks/auth';
import { getFeatureControlByPlan } from '../../../middlewares/FeatureControl';
import FormBuilder from '../../../components/Forms/FormBuilder';

interface RouteParams {
    hash: string;
    origin: string;
}

export interface FlowFieldFormData {
    field_title: string;
    field_required: boolean;
    field_description: string;
    field_help_text: string;
    field_place_holder: string;
    field_options: FieldOptionsProps[];
    field_register_id: number;
    field_flow_id: number;
    field_variation: string;
    field_unique: string;
    field_max_length: string;
    field_on_edit: string;
    field_formula: string;
    field_updated: string;
    field_show_on_form: string;
    field_show_on_history: string;
}

interface FormInitData {
    new_answer_option?: string;
    new_answer_title?: string;
    new_answer_label?: string;
}

const fieldsFormInit: FieldProps[] = [
    {
        name: "new_answer_option",
        type: FieldItems.COMBO_BOX_FIELD,
        required: false,
        index: 0,
        title: "Comportamento após inserção",
        description: "Escolha o comportamento da janela após a inserção de um novo item",
        placeholder: "Selecione uma opção...",
        help_text: "Selecione o comportamento após a inserção de um novo item, o que deseja que aconteça após a inserção de um novo item",
        turn_on_field: true,
        options: [
            { value: "1", label: "Fechar a tela do formulário inicial (Padrão)" },
            { value: "2", label: "Fechar a tela do formulário e abrir o cartão inserido" },
            { value: "3", label: "Limpar o formulário e permanecer com a janela aberta" }
        ]
    },
    {
        name: "new_answer_title",
        type: FieldItems.TEXT_SHORT_FIELD,
        required: false,
        turn_on_field: true,
        index: 1,
        title: "Título do formulário",
        description: "Personalize o seu formulário com um título",
        placeholder: "Digite aqui...",
        help_text: "Este será o título do formulário, personalize de acordo com a sua necessidade",
        max_length: 75
    },
    {
        name: "new_answer_label",
        type: FieldItems.TEXT_SHORT_FIELD,
        required: false,
        turn_on_field: true,
        index: 2,
        title: "Texto do botão para inserir o cartão",
        description: "Personalize o texto do botão para inserir o cartão",
        placeholder: "Digite aqui...",
        help_text: "Este será o texto do botão para inserir o cartão, personalize de acordo com a sua necessidade",
        max_length: 75
    }
]

const LoaderField = () => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={70}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="70" />
    </ContentLoader>
)

const LoaderBtnField = () => (
    <ContentLoader
        speed={2}
        width={180}
        height={36}
        viewBox="0 0 180 36"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="5" ry="5" width="180" height="36" />
    </ContentLoader>
)

interface objectInit {
    [x: string]: string | object
}

const SecStep: React.FC = () => {

    const formRefPreview = useRef<FormHandles>(null);

    const { params } = useRouteMatch<RouteParams>();
    const { user } = useAuth();

    const history = useHistory();

    const { addToast } = useToast();

    const [statePage, setStatePage] = React.useState(0);
    const [flow, setFlow] = React.useState<Flow>();

    /* Loading Variables */
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingItems] = useState<number[]>([1, 2, 3]);
    const [loadingInsert, setLoadingInsert] = useState<boolean>(false);

    /* Field Array */
    const [fields, setFields] = React.useState([] as FieldProps[]);
    const [form, setForm] = React.useState({} as Form);
    const [defaultValue, setDefaultValue] = useState<object[]>();

    /* Dialog controller */
    const [open, setOpen] = React.useState(false);
    const [stateDialog, setStateDialog] = React.useState("INS");
    const [selectedValue, setSelectedValue] = React.useState({} as FieldProps);

    const [copied, setCopied] = useState<boolean>(false);

    const formRefInit = useRef<FormHandles>(null);

    const handleCopyClick = () => {

        let values: objectInit = {}

        //Run into fields and append to values as a key-value
        fields.forEach((field) => {
            values[field.name] = field.title;
        });

        const objApi = {
            id_form: flow?.form_init_id,
            origin: "/NomeDaSuaApi",
            values: values,
            flow_id: flow?.id_flow,
        }

        //Set to string the objApi
        const toString = (obj: object) => {
            return JSON.stringify(obj, null, 2);
        };

        navigator.clipboard.writeText(toString(objApi));

        setCopied(true);
    };

    const handleSubmitPage = useCallback(async () => {

        if (loading) {            
        } else if (fields.length > 0) {

            //Monta o objeto para enviar na API
            let dataToApi: Form = {
                id_form: form.id_form,
                name: "FI",
                fields: fields,
                hashFlow: params.hash
            }

            //Normalize the Form Data
            if (formRefInit.current !== null) {
                const dataNormalize = formRefInit.current.getData() as unknown as FormInitData;

                dataToApi.new_answer_option = dataNormalize.new_answer_option !== undefined && dataNormalize.new_answer_option !== null ? dataNormalize.new_answer_option : undefined;
                dataToApi.new_answer_title = dataNormalize.new_answer_title !== undefined && dataNormalize.new_answer_title !== null ? dataNormalize.new_answer_title : undefined;
                dataToApi.new_answer_label = dataNormalize.new_answer_label !== undefined && dataNormalize.new_answer_label !== null ? dataNormalize.new_answer_label : undefined;

            }

            setLoadingInsert(true);
            await api
                .post('/form', dataToApi)
                .then(response => {
                    if (response.data !== null) {
                        setForm(response.data as Form);
                        setFields(response.data.fields as FieldProps[]);
                    }

                    if (params.origin === "new") {
                        history.push('/flow/3/' + params.hash + "/new");
                    } else {
                        history.push('/flow/' + params.hash);
                    }
                    setLoadingInsert(false);
                }).catch(error => {
                    setLoadingInsert(false);
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar o fluxo',
                        description: 'Ocorreu um erro ao salvar o fluxo!',
                    });
                });

        } else {
            addToast({
                type: 'error',
                title: 'Sem campos adicionados!',
                description: 'Para prosseguir, você deve adicionar ao menos um campo no Formulário Inicial',
            });
        }

    }, [fields, form, addToast, params.hash, params.origin, history, loading]);

    const handleSavePage = useCallback(async (urlRedirect?: string) => {

        if (fields.length > 0) {
            //Monta o objeto para enviar na API
            let dataToApi: Form = {
                id_form: form.id_form,
                name: "FI",
                fields: fields,
                hashFlow: params.hash
            }

            //Normalize the Form Data
            if (formRefInit.current !== null) {
                const dataNormalize = formRefInit.current.getData() as unknown as FormInitData;

                dataToApi.new_answer_option = dataNormalize.new_answer_option !== undefined && dataNormalize.new_answer_option !== null ? dataNormalize.new_answer_option : undefined;
                dataToApi.new_answer_title = dataNormalize.new_answer_title !== undefined && dataNormalize.new_answer_title !== null ? dataNormalize.new_answer_title : undefined;
                dataToApi.new_answer_label = dataNormalize.new_answer_label !== undefined && dataNormalize.new_answer_label !== null ? dataNormalize.new_answer_label : undefined;

            }

            setLoadingInsert(true);
            await api
                .post('/form', dataToApi)
                .then(response => {
                    if (response.data !== null) {
                        setForm(response.data as Form);

                        setFields(response.data.fields as FieldProps[]);
                    }
                    setLoadingInsert(false);
                }).catch(error => {
                    setLoadingInsert(false);
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar o fluxo',
                        description: 'Ocorreu um erro ao salvar o fluxo!',
                    });
                });
        }

        if (urlRedirect !== undefined) {
            history.push(urlRedirect);
        }

    }, [history, params.hash, addToast, fields, form.id_form]);

    const handleBackPage = useCallback(async () => {

        if (params.origin === "new") {

            handleSavePage("/flow/1/" + params.hash + "/new");

        } else {
            history.push('/flow/' + params.hash);
        }

    }, [history, params.hash, params.origin, handleSavePage]);

    const handleSubmit = useCallback(async (data: object[]) => {

    }, []);

    const dialogNewFieldDelete = useCallback(async (name: string, id_field?: number) => {
        setOpen(false);

        if (id_field !== undefined && id_field > 0) { //Já inserido no BD

            await api
                .delete('/field?id_field=' + id_field + '&hashFlow=' + params.hash)
                .then(response => {

                    let fieldsNew = fields.filter((field) => field.name !== name);

                    fieldsNew = fieldsNew.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));

                    for (let index = 0; index < fieldsNew.length; index++) {

                        fieldsNew[index].index = index;

                    }

                    setFields(fieldsNew);

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao excluir o campo',
                        description: 'Ocorreu um erro ao excluir o campo!',
                    });
                });


        } else { //Somente em memória

            let fieldsNew = fields.filter((field) => field.name !== name);

            fieldsNew = fieldsNew.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));

            for (let index = 0; index < fieldsNew.length; index++) {

                fieldsNew[index].index = index;

            }

            setFields(fieldsNew);

        }


    }, [fields, addToast, params.hash]);

    const dialogNewFieldOpen = useCallback(async () => {
        setSelectedValue({} as FieldProps);
        setOpen(true);
        setStateDialog("INS");
        setStatePage(Math.random());
    }, []);

    const dialogNewFieldClose = useCallback(async (value: FieldProps) => {
        setOpen(false);
        setSelectedValue(value);
    }, []);

    const dialogNewFieldSubmit = useCallback(async (data: FieldProps) => {

        if (stateDialog === "INS") {
            data.index = fields.length === 0 ? 0 : (fields.length - 1) + 1;

            if (fields.length > 0) {
                setFields((fields) => {
                    return [...fields, data]
                });
            } else {
                setFields([data]);
            }

        } else if (stateDialog === "EDI") {

            let newFields = fields;

            newFields[data.index] = data;

            setFields(newFields);
        }

        setOpen(false);
        setSelectedValue(data);
    }, [fields, stateDialog]);

    /* Field Controller */
    const handleEditField = useCallback(async (index: number) => {
        setSelectedValue(fields[index]);
        setOpen(true);
        setStateDialog("EDI");
    }, [fields]);

    function generateRandomNumber() {
        return Math.floor(Math.random() * 1000000);
    }

    const handleCopyField = useCallback(async (index: number) => {

        let newFields = [...fields];

        let copyField = { ...newFields[index] };

        var hash = require('object-hash');
        const dateNow = new Date().getTime();
        let nameHash = await hash(copyField.title + '' + dateNow + '' + generateRandomNumber());

        copyField.index = newFields.length;
        copyField.title = "Copia de " + copyField.title;
        copyField.id_field = undefined;
        copyField.name = nameHash;

        if (copyField.options !== undefined && copyField.options.length > 0) {
            copyField.options = copyField.options.map((option) => {
                option.id_field_option = undefined;
                return option;
            });
        }

        if (copyField.validations !== undefined && copyField.validations.length > 0) {
            copyField.validations = copyField.validations.map((validation) => {
                validation.id_field_validation = undefined;
                return validation;
            });
        }

        newFields.push(copyField);

        setFields(newFields);

    }, [fields]);

    const handleMoveFieldDragAndDrop = (nameField: string, newIndex: number) => {
        let newFields = Array.from(fields);
        const currentIndex = newFields.findIndex((field) => field.name === nameField);
        if (currentIndex === -1) return;

        const [movedField] = newFields.splice(currentIndex, 1);
        newFields.splice(newIndex, 0, movedField);

        newFields = newFields.map((field, idx) => ({
            ...field,
            index: idx,
        }));

        setFields(newFields);
    };

    const handleMoveField = useCallback(async (type: string, index: number) => {

        let newFields = fields;

        if (type === "up") { //Se clicou para cima 

            if (index > 0) {
                let upFil = fields[index];
                upFil.index = index - 1;

                let downFil = fields[index - 1];
                downFil.index = index;

                newFields[index] = upFil;
                newFields[index - 1] = downFil;
            }

        } else if (type === "down") { //Se clicou para baixo

            if (index < fields.length - 1) {
                let downFil = fields[index];
                downFil.index = index + 1;

                let upFil = fields[index + 1];
                upFil.index = index;

                newFields[index] = downFil;
                newFields[index + 1] = upFil;
            }

        }

        setFields(newFields);
        setStatePage(Math.random());

    }, [fields]);

    const goToGeralTab = useCallback(async () => {

        if (params.origin === "new") {
            handleSavePage("/flow/1/" + params.hash + "/new");
        } else {
            handleSavePage("/flow/1/" + params.hash + "/edit");
        }

    }, [params.origin, params.hash, handleSavePage]);

    const goToStepsTab = useCallback(async () => {

        if (params.origin === "new") {
            handleSavePage("/flow/3/" + params.hash + "/new");
        } else {
            handleSavePage("/flow/3/" + params.hash + "/edit");
        }

    }, [params.origin, params.hash, handleSavePage]);

    const navBarItems: NavigationBarItemType[] = [
        {
            index: 1,
            description: "Geral",
            icon: BsGearFill,
            onClick: () => goToGeralTab()
        },
        {
            index: 2,
            description: "Formulário Inicial",
            icon: IoMdListBox,
            active: true,
            onClick: () => { }
        },
        {
            index: 3,
            description: "Etapas",
            icon: RiFlowChart,
            onClick: () => goToStepsTab()
        }
    ]

    const navBarItemsDefault: NavigationBarItemType[] = [
        {
            index: 1,
            description: "Geral",
            icon: BsGearFill,
            onClick: () => goToGeralTab()
        },
        {
            index: 2,
            description: "Formulário Inicial",
            icon: IoMdListBox,
            active: true,
            onClick: () => { }
        }
    ]

    const handleSubmitForm = useCallback(async (data: object[]) => {

    }, []);

    useEffect(() => {

        setLoading(true);

        api.get(`/flow`, {
            params: {
                hash: params.hash,
                withSteps: true
            }
        }).then(response => {

            if (response.data.form_init_id !== null &&
                response.data.form_init_id !== undefined) {

                const flowApi: Flow = response.data;

                setFlow(flowApi);

                api.get(`/form/by-flow`, {
                    params: {
                        hash: params.hash
                    }
                }).then(response => {
                    if (response.data !== null) {
                        setForm(response.data as Form);
                        setFields(response.data.fields as FieldProps[]);
                    }
                    setLoading(false);
                }).catch(error => {
                    setLoading(false);
                    addToast({
                        type: 'error',
                        title: 'Erro ao buscar os dados do Fluxo',
                        description: 'Erro ao buscar os dados do Fluxo!',
                    });
                });
            } else {
                setLoading(false);
            }
        }).catch(error => {
            setLoading(false);
        });

    }, [addToast, params.hash, params.origin]);

    useEffect(() => {

        if (formRefInit.current !== null) {

            const newForm: FormInitData = {
                "new_answer_option": form.new_answer_option !== undefined ? form.new_answer_option : undefined,
                "new_answer_title": form.new_answer_title !== undefined ? form.new_answer_title : undefined,
                "new_answer_label": form.new_answer_label !== undefined ? form.new_answer_label : undefined
            }

            setDefaultValue(newForm as unknown as object[]);

        }

    }, [form]);

    //Keyboard Shortcuts
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if ((event.ctrlKey) && event.key === 'n' && !open) {
                event.preventDefault();
                dialogNewFieldOpen();
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [dialogNewFieldOpen, open]);

    useEffect(() => {
        //Valid if close the window
        window.onbeforeunload = confirmExit;
        function confirmExit() {
            return "show warning";
        }
    }, [])

    return (
        <LayoutContainer>
            <title>{flow !== undefined ? flow.name : "Novo Fluxo"} | Cange</title>
            <ListContainer statePage={statePage}>

                {/* Header Page */}
                <AppBarHeader
                    icon={params.origin === "new" ? TiFlowMerge : IoMdListBox}
                    iconDynamic={flow !== undefined ? flow.icon as IconList : undefined}
                    iconDynamicColor={flow !== undefined ? flow.color : undefined}
                    title={params.origin === "new" ? "Novo Fluxo" : flow !== undefined ? flow.name : "Formulário Inicial"}
                    navBarItems={params.origin === "new" ? navBarItemsDefault : navBarItems}
                    isRoundedCorners={true}
                >
                    <ButtonNextStep
                        color="gray"
                        height="36px"
                        margin="0px 30px 0px 0px"
                        icon={FiArrowLeft}
                        icon_side="left"
                        onClick={handleBackPage}
                        isLoading={loadingInsert}
                    >
                        Voltar
                    </ButtonNextStep>

                    {params.origin === "new" ?
                        <ButtonNextStep
                            color="#23cd78"
                            height="36px"
                            margin="0px 30px 0px 0px"
                            icon={FiArrowRight}
                            icon_side="right"
                            onClick={handleSubmitPage}
                            isLoading={loadingInsert}
                        >
                            Avançar
                        </ButtonNextStep> :
                        <ButtonNextStep
                            type="submit"
                            color="#23cd78"
                            height="36px"
                            margin="0px 30px 0px 0px"
                            icon={BiSave}
                            onClick={handleSubmitPage}
                            isLoading={loadingInsert}
                        >
                            Salvar
                        </ButtonNextStep>
                    }
                </AppBarHeader>

                {/* Inputs Form */}
                <Container>
                    <Content container>

                        {/* Forms */}
                        <ContainerFields item xs={12} md={6}>

                            <ContentList>

                                <BreakArea />

                                <Title
                                    title="Formulário Inicial"
                                    required={false}
                                    font_size="20px"
                                    font_weight="600"
                                />
                                <Description>O formulário é o gatilho inicial necessário para começar um novo fluxo. Você poderá customizar da maneira que desejar</Description>

                                <BreakArea />

                                {/* Campos do Formulário */}
                                <Title
                                    title="Campos do formulário"
                                    required={false}
                                    help_text="Os campos são ideais para você armazenar todas as informações que irá necessitar durante o fluxo"
                                />
                                <Description style={{ marginBottom: '10px' }}>Para iniciar o seu fluxo, crie campos para coletar as informações necessárias</Description>

                                {loading ?
                                    <>
                                        {loadingItems.map((item) => {
                                            return (
                                                <div key={item} style={{ marginBottom: '15px' }}>
                                                    <LoaderField />
                                                </div>
                                            )
                                        })}
                                        <LoaderBtnField />
                                    </> :
                                    <Fields
                                        fields={fields}
                                        flow={flow}
                                        origin='flow'
                                        open={open}
                                        selectedValue={selectedValue}
                                        stateDialog={stateDialog}
                                        isFormInit={true}
                                        handleMoveField={handleMoveField}
                                        handleMoveFieldDragAndDrop={handleMoveFieldDragAndDrop}
                                        handleEditField={handleEditField}
                                        handleCopyField={handleCopyField}
                                        dialogNewFieldSubmit={dialogNewFieldSubmit}
                                        dialogNewFieldOpen={dialogNewFieldOpen}
                                        dialogNewFieldClose={dialogNewFieldClose}
                                        dialogNewFieldDelete={dialogNewFieldDelete}
                                    />
                                }

                                <BreakArea />

                                <FormBuilder
                                    id="formRefInit"
                                    formRef={formRefInit}
                                    fields={fieldsFormInit}
                                    initialValue={defaultValue}
                                    handleSubmit={handleSubmitForm}
                                    hideContainer={true}
                                />

                            </ContentList>
                        </ContainerFields>

                        {/* Pré-Visualização/Design/Simulação */}
                        <ContainerPreview item xs={12} md={6}>
                            <ContentPreview>
                                <FormPreview
                                    id="previewAllForm"
                                    formRef={formRefPreview}
                                    fields={fields}
                                    handleSubmit={handleSubmit}
                                    flow_id={flow?.id_flow}
                                    hideAutoComplete={false}
                                    typeUser={'A'}
                                />
                                {form !== undefined && form.id_form !== undefined && getFeatureControlByPlan(3, user.company) && (
                                    <ContainerAdrress>
                                        <TitleAddress>
                                            <TbCircleKey />
                                            <h3>OBJETO API</h3>
                                            <CopyBtn onClick={handleCopyClick}>
                                                {copied ?
                                                    <>
                                                        <TbCheck />
                                                        Copiado
                                                    </> :
                                                    <>
                                                        <TbCopy />
                                                        Copiar
                                                    </>
                                                }
                                            </CopyBtn>
                                        </TitleAddress>
                                    </ContainerAdrress>
                                )}
                            </ContentPreview>
                        </ContainerPreview>

                    </Content>
                </Container>

                <Footer></Footer>
            </ListContainer>
        </LayoutContainer>
    );
}

export default SecStep;