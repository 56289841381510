import {
    AddNewFilter,
    BoxContainer,
    ButtonLink,
    DeleteButton,
    FilterBottom,
    FilterContainer,
    GoFilter,
    CleanFilter,
    BoxContainerOrder,
    Input,
    SearchInput,
    SelectComparator,
    SelectComparatorOrder,
    SelectInput,
    BoxContainerField,
    FieldsContainer,
    FieldViewItem,
    FieldViewItemToggle,
    FieldViewItemTitle,
    FieldViewItemOrigin,
    FieldViewItemDrag,
    FieldViewItemIcon
} from "./style";
import { AiFillThunderbolt, AiOutlineEyeInvisible, AiOutlineSearch } from "react-icons/ai";
import { Box, ClickAwayListener } from "@material-ui/core";
import { FaPlus, FaTrash } from "react-icons/fa";
import { FieldOptionsProps, FieldProps, Fields, getComponentComparationType, getFieldObject } from "../../Forms/Fields/FieldBuilder";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";

import FilterDate from "../../FilterBar/FilterComponents/FilterDate";
import FilterInput from "../../FilterBar/FilterComponents/FilterInput";
import FilterSelect from "../../FilterBar/FilterComponents/FilterSelect";
import { IoIosCloseCircle } from "react-icons/io";
import { MdOutlineDragIndicator, MdOutlineFilterList } from "react-icons/md";
import { BsSortDown, BsToggleOff, BsToggleOn } from "react-icons/bs";
import { Register } from "../../../interfaces/Register";

export interface FieldViewProps {
    id_field: number;
    form_id: number;
    title: string;
    index: number;
    active: boolean;
    type: string;
    origin: string;
    variation?: string;
    maxLength?: string;
    indexOrigin: number;
    reordered: boolean;
    disabled?: boolean;
}

export interface FilterConditionItem {
    index: number;
    fields: FieldProps[];
    comparators: string[];
    selectedField: FieldProps;
    selectedComparator: string;
    value: string;
    valueOptions?: string[];
    options?: FieldOptionsProps[];
    nameComponent?: string;
}

export interface OrderConditionItem {
    index: number;
    fields?: OrderField[];
    orders?: string[];
    selectedField: OrderField;
    selectedOrder: string;
}

interface OrderField {
    id_field: number;
    form_id?: number;
    type: string;
    title: string;
}

export interface FilterRegisterProps {
    fieldView?: FieldViewProps[],
    conditions: FilterConditionItem[],
    orderBy?: OrderConditionItem[],
    searchText?: string
}

interface FilterDialogRegisterProps {
    register?: Register;
    selectedValue?: FilterRegisterProps,
    onFilterRegister: (filterRegister: FilterRegisterProps, openedDialog: boolean) => void;
}

export interface FilterComponentProps {
    comparator: string;
    value?: string;
    valueOptions?: string[];
    field?: FieldProps;
    options?: FieldOptionsProps[];
    onEvent: (e?: React.FormEvent<HTMLInputElement>, conditions?: string[]) => void;
}

const FilterDialogRegister: React.FC<FilterDialogRegisterProps> = ({ register, selectedValue, onFilterRegister }) => {

    const [fieldsView, setFieldsView] = useState<FieldViewProps[]>(selectedValue?.fieldView !== undefined ? selectedValue.fieldView : []);
    const [conditionItems, setConditionItems] = useState<FilterConditionItem[]>(selectedValue?.conditions !== undefined ? selectedValue.conditions : []);
    const [orderItems, setOrderItems] = useState<OrderConditionItem[]>(selectedValue?.orderBy !== undefined ? selectedValue.orderBy : []);
    const [searchText, setSearchText] = useState<string>(selectedValue?.searchText !== undefined ? selectedValue.searchText : "");

    const [openFilter, setOpenFilter] = useState(false);
    const [fields, setFields] = useState<FieldProps[]>([]);
    const [filterState, setFilterState] = useState<boolean>();
    const [filterStateMessage, setFilterStateMessage] = useState<string>();

    const [openOrder, setOpenOrder] = useState(false);
    const [fieldsOrder, setFieldsOrder] = useState<OrderField[]>([]);
    const [orderState, setOrderState] = useState<boolean>();
    const [orderStateMessage, setOrderStateMessage] = useState<string>();

    const [openFields, setOpenFields] = useState(false);

    const [searchField, setSearchField] = useState<boolean>();
    const searchInputRef = useRef<HTMLInputElement>(null);

    /* Relationship */
    const ComponentField = {
        TEXT_FILTER: "TEXT_FILTER",
        DATE_FILTER: "DATE_FILTER",
        OPTION_FILTER: "OPTION_FILTER",
    };

    const getFilterComponent = (type: string) => {
        const relationFields = {
            [ComponentField.TEXT_FILTER]: FilterInput,
            [ComponentField.DATE_FILTER]: FilterDate,
            [ComponentField.OPTION_FILTER]: FilterSelect,
        };

        return relationFields[type];
    };

    const getFilterNameComponent = (type: string) => {
        const relationFields = {
            [Fields.TEXT_SHORT_FIELD]: "TEXT_FILTER",
            [Fields.TEXT_LONG_FIELD]: "TEXT_FILTER",
            [Fields.MAIL_FIELD]: "TEXT_FILTER",
            [Fields.COMBO_BOX_FIELD]: "OPTION_FILTER",
            [Fields.RADIO_BOX_FIELD]: "OPTION_FILTER",
            [Fields.CHECK_BOX_FIELD]: "OPTION_FILTER",
            [Fields.DATE_PICKER_FIELD]: "DATE_FILTER",
            [Fields.SWITCH_FIELD]: "OPTION_FILTER",
            [Fields.INPUT_LIST_FIELD]: "OPTION_FILTER",
            [Fields.CHECK_BOX_ONE_FIELD]: "OPTION_FILTER",
            [Fields.COMBO_BOX_USER_FIELD]: "OPTION_FILTER",
            [Fields.DUE_DATE_FIELD]: "DATE_FILTER",
            [Fields.CURRENCY_FIELD]: "TEXT_FILTER",
            [Fields.FORMULA_FIELD]: "TEXT_FILTER",
            [Fields.NUMBER_FIELD]: "TEXT_FILTER",
            [Fields.PHONE_FIELD]: "TEXT_FILTER",
            [Fields.COMBO_BOX_REGISTER_FIELD]: "OPTION_FILTER",
            [Fields.COMBO_BOX_FLOW_FIELD]: "OPTION_FILTER",
            [Fields.INPUT_RICH_TEXT_FIELD]: "TEXT_FILTER",
            [Fields.DOC_FIELD]: "TEXT_FILTER",
            [Fields.ID_FIELD]: "TEXT_FILTER",
            [Fields.LINK_FIELD]: "TEXT_FILTER",
        };

        return relationFields[type];
    }

    const handleClickAway = () => {
        setOpenFilter(false);
        setOpenOrder(false);
        setOpenFields(false);

        if (searchText === undefined || searchText === "") {
            setSearchField(false);
        }
    };

    const onSearch = (text: React.FormEvent<HTMLInputElement>) => {

        setSearchText(text?.currentTarget.value);

    };

    const onSelectFieldFilter = (field_id: string, index: number) => {

        const fieldSelected = fields.filter((field) => String(field.id_field) === field_id);

        if (fieldSelected !== undefined && fieldSelected.length > 0) {

            setConditionItems(conditionItems.map((condition, idx) => {
                if (idx === index) {
                    condition.comparators = getComponentComparationType(fieldSelected[0].type);
                    condition.selectedComparator = getComponentComparationType(fieldSelected[0].type)[0];
                    condition.selectedField = fieldSelected[0];
                    condition.nameComponent = getFilterNameComponent(fieldSelected[0].type)
                    condition.valueOptions = [];
                    condition.value = "";
                }

                return condition;
            }))

        }

        onFilterRegister({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText }, openFilter);

    };

    const onSelectFieldOrder = (field_id: string, index: number) => {

        const fieldSelected = fieldsOrder.filter((field) => String(field.id_field) === field_id);

        if (fieldSelected !== undefined && fieldSelected.length > 0) {

            const orderSorter: string[] | undefined = getFieldObject(fieldSelected[0].type).orderSorter;

            if (orderSorter !== undefined) {
                setOrderItems(orderItems.map((order, idx) => {
                    if (idx === index) {
                        order.orders = orderSorter;
                        order.selectedOrder = orderSorter[0];
                        order.selectedField = fieldSelected[0];
                    }

                    return order;
                }))
            }

        }

        onFilterRegister({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText }, openOrder);

    };

    const onSelectComparator = (comparatorName: string, index: number) => {

        setConditionItems(conditionItems.map((condition, idx) => {
            if (idx === index) {
                condition.selectedComparator = comparatorName;

                //Clean the value when is blank
                if (comparatorName === "Está em branco" || comparatorName === "Não está em branco") {
                    condition.value = "";
                }
            }

            return condition;
        }))

        onFilterRegister({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText }, openFilter);

    };

    const onSelectOrder = (orderName: string, index: number) => {

        setOrderItems(orderItems.map((order, idx) => {
            if (idx === index) {
                order.selectedOrder = orderName;
            }

            return order;
        }))

        onFilterRegister({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText }, openOrder);

    };

    const handleDeleteAllConditionOrder = useCallback(() => {

        setOrderItems([]);

        onFilterRegister({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText }, openOrder);

        setOpenOrder(!openOrder)

    }, [conditionItems, orderItems, searchText, onFilterRegister, openOrder, fieldsView]);

    const handleDeleteAllConditionFilter = useCallback(() => {

        let newConditions = [];

        const newConditionItem: FilterConditionItem = {
            index: 0,
            fields: fields,
            comparators: getComponentComparationType(fields[0].type),
            selectedField: fields[0],
            selectedComparator: getComponentComparationType(fields[0].type)[0],
            value: "",
            valueOptions: undefined,
            options: fields[0].options,
            nameComponent: getFilterNameComponent(fields[0].type)
        }

        newConditions.push(newConditionItem);

        setConditionItems(newConditions);

        onFilterRegister({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText }, openFilter);

        setOpenFilter(!openFilter)

    }, [conditionItems, onFilterRegister, fields, openFilter, orderItems, searchText, fieldsView]);

    const handleDeleteConditionFilter = useCallback((index: number) => {

        let newConditions = conditionItems.filter((condition) => condition.index !== index);

        //Rebuild the indexes
        newConditions.map((condition, idx) => {
            condition.index = idx;
            return condition;
        })

        if (newConditions.length === 0) {

            const newConditionItem: FilterConditionItem = {
                index: 0,
                fields: fields,
                comparators: getComponentComparationType(fields[0].type),
                selectedField: fields[0],
                selectedComparator: getComponentComparationType(fields[0].type)[0],
                value: "",
                valueOptions: undefined,
                options: fields[0].options,
                nameComponent: getFilterNameComponent(fields[0].type)
            }

            newConditions.push(newConditionItem);

        }

        setConditionItems(newConditions);

        onFilterRegister({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText }, openFilter);

    }, [conditionItems, onFilterRegister, fields, openFilter, orderItems, searchText, fieldsView]);

    const handleDeleteConditionOrder = useCallback((index: number) => {

        let newConditions = orderItems.filter((condition) => condition.index !== index);

        //Rebuild the indexes
        newConditions.map((condition, idx) => {
            condition.index = idx;
            return condition;
        })

        if (newConditions.length === 0) {

            const orderSorter: string[] | undefined = getFieldObject(fieldsOrder[0].type).orderSorter;

            const newOrderItem: OrderConditionItem = {
                index: 0,
                fields: fieldsOrder,
                orders: orderSorter !== undefined ? orderSorter : [],
                selectedField: fieldsOrder[0],
                selectedOrder: orderSorter !== undefined ? orderSorter[0] : "A → Z",
            }

            newConditions.push(newOrderItem);

        }

        setOrderItems(newConditions);

        onFilterRegister({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText }, openOrder);

    }, [orderItems, onFilterRegister, fieldsOrder, openOrder, conditionItems, searchText, fieldsView]);

    const onEventFieldComponent = useCallback((index: number, value?: string, conditions?: string[], version?: number) => {

        if (value !== undefined) {

            setConditionItems(conditionItems.map((condition, idx) => {
                if (idx === index) {
                    condition.value = value;
                }

                return condition;
            }))

            onFilterRegister({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText }, openFilter);

        } else if (conditions !== undefined && conditions.length > 0) {

            setConditionItems(conditionItems.map((condition, idx) => {
                if (idx === index) {
                    condition.valueOptions = conditions
                }

                return condition;
            }))

            onFilterRegister({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText }, openFilter);

        }

    }, [onFilterRegister, conditionItems, searchText, openFilter, orderItems, fieldsView]);

    const addNewConditionFilter = useCallback(() => {

        let newArr = conditionItems;

        const newConditionItem: FilterConditionItem = {
            index: newArr.length,
            fields: fields,
            comparators: getComponentComparationType(fields[0].type),
            selectedField: fields[0],
            selectedComparator: getComponentComparationType(fields[0].type)[0],
            value: "",
            options: fields[0].options,
            nameComponent: getFilterNameComponent(fields[0].type)
        }

        newArr.push(newConditionItem);

        setConditionItems(newArr.map((condition) => {
            return condition;
        }));

    }, [fields, conditionItems]);

    const addNewConditionOrder = useCallback(() => {

        let newArr = orderItems;

        const orderSorter: string[] | undefined = getFieldObject(fieldsOrder[0].type).orderSorter;

        const newOrderItem: OrderConditionItem = {
            index: newArr.length,
            fields: fieldsOrder,
            orders: orderSorter !== undefined ? orderSorter : [],
            selectedField: fieldsOrder[0],
            selectedOrder: orderSorter !== undefined ? orderSorter[0] : "A → Z",
        }

        newArr.push(newOrderItem);

        setOrderItems(newArr.map((order) => {
            return order;
        }));

    }, [fieldsOrder, orderItems]);

    const addDefaultItemsOrder = useCallback(() => {

        const orderSorter: string[] | undefined = getFieldObject(fieldsOrder[0].type).orderSorter;

        if (fieldsOrder !== undefined && fieldsOrder.length > 0 && orderItems.length === 0) {

            const newConditionItem: OrderConditionItem = {
                index: 0,
                fields: fieldsOrder,
                orders: orderSorter !== undefined ? orderSorter : [],
                selectedField: fieldsOrder[0],
                selectedOrder: orderSorter !== undefined ? orderSorter[0] : "A → Z",
            }

            setOrderItems([newConditionItem]);

        }

    }, [fieldsOrder, orderItems.length]);

    const addDefaultItemsFilter = useCallback(() => {

        if (fields !== undefined && fields.length > 0 && conditionItems.length === 0) {

            const newConditionItem: FilterConditionItem = {
                index: 0,
                fields: fields,
                comparators: getComponentComparationType(fields[0].type),
                selectedField: fields[0],
                selectedComparator: getComponentComparationType(fields[0].type)[0],
                value: "",
                options: fields[0].options,
                nameComponent: getFilterNameComponent(fields[0].type)
            }

            setConditionItems([newConditionItem]);

        }

    }, [fields, conditionItems.length]);

    const handleSelectFieldViewItem = useCallback((id_field: number) => {

        setFieldsView(fieldsView.map((f) => {
            if (f.id_field === id_field) {
                f.active = !f.active;
            }
            return f;
        }));

    }, [fieldsView]);

    const onDragEnd = (result: DropResult) => {
        //If was dragged to outside the list
        if (!result.destination) {
            return;
        }

        const reorderedItems = Array.from(fieldsView);
        const [draggedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, draggedItem);

        //Update values of items
        const updatedItems = reorderedItems.map((item, index) => ({
            ...item,
            index: index,
            reordered: true
        }));

        setFieldsView(updatedItems);
    };

    function sortFieldView(a: FieldViewProps, b: FieldViewProps) {
        return a.index - b.index;
    }

    useEffect(() => {
        if (searchText !== "") {
            const timer = setTimeout(() => {
                onFilterRegister({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText }, openFilter);
            }, 350)

            return () => clearTimeout(timer);
        } else {

            let stateDialog = false;
            if (!openFilter && !openOrder) {
                stateDialog = false;
            } else {
                stateDialog = true;
            }

            onFilterRegister({ fieldView: fieldsView, conditions: conditionItems, orderBy: orderItems, searchText }, stateDialog);
        }
    }, [searchText, conditionItems, onFilterRegister, openFilter, orderItems, openOrder, fieldsView]);

    useEffect(() => {

        //Update the sort state
        if (orderItems.length !== undefined && orderItems.length >= 1) {

            let ret = false;
            let fieldsOn: string[] = [];

            orderItems.map((cond) => {

                fieldsOn.push(cond.selectedField.title);
                ret = true;

                return cond;
            })

            setOrderState(ret);
            if (ret) {
                let message = "Ordenando por ";

                fieldsOn.map((fil, index) => {

                    const containsInMessage = message.includes(fil);

                    if (index === 0) {
                        message += fil;
                    } else if (index === 1 && fieldsOn.length > 2) {
                        message += " + " + (fieldsOn.length - 1) + " campos";
                    } else if (index === 1 && fieldsOn.length > 1 && !containsInMessage) {
                        message += " e " + fil;
                    }

                    return fil;
                });

                setOrderStateMessage(message);
            }

        } else {
            setOrderState(false);
        }

    }, [orderItems])

    useEffect(() => {

        //Update the filter state
        if (conditionItems.length !== undefined && conditionItems.length >= 1) {

            let ret = false;
            let fieldsOn: string[] = [];

            conditionItems.map((cond) => {
                if (cond.value !== "" || (cond.valueOptions !== undefined && cond.valueOptions.length >= 1) || cond.selectedComparator === "Está em branco" || cond.selectedComparator === "Não está em branco") {
                    ret = true;
                    fieldsOn.push(cond.selectedField.title);
                }

                return cond;
            })

            setFilterState(ret);
            if (ret) {
                let message = "Filtrando por ";

                fieldsOn.map((fil, index) => {

                    const containsInMessage = message.includes(fil);

                    if (index === 0) {
                        message += fil;
                    } else if (index === 1 && fieldsOn.length > 2) {
                        message += " + " + (fieldsOn.length - 1) + " campos";
                    } else if (index === 1 && fieldsOn.length > 1 && !containsInMessage) {
                        message += " e " + fil;
                    }

                    return fil;
                });

                setFilterStateMessage(message);
            }

        } else {
            setFilterState(false);
        }

    }, [conditionItems])

    useEffect(() => {

        let newField: FieldProps[] = [];

        //Get all fields 
        if (register !== undefined) {

            //Prepare standard fields               
            //Criado em
            newField.push({
                id_field: -1,
                name: "-1",
                type: "DATE_PICKER_FIELD",
                title: "Criado em",
                description: "Criado em",
                index: -1
            });

            //Criado por
            newField.push({
                id_field: -2,
                name: "-2",
                type: "TEXT_SHORT_FIELD",
                title: "Criado por",
                description: "Criado por",
                index: -2
            });

            //Identificador
            newField.push({
                id_field: -3,
                name: "-3",
                type: "TEXT_SHORT_FIELD",
                title: "Identificador",
                description: "Identificador",
                index: -3
            });

            if (register.form !== undefined && register.form !== null) {
                if (register.form.fields !== undefined && register.form.fields !== null && register.form.fields.length > 0) {
                    newField.push(...register.form.fields);
                }
            }

            setFields(newField.filter((f) => f.index < 0 || getFieldObject(f.type).onFilter));

            //Prepare the sort fields
            let newOrderFields: OrderField[] = [];

            //Criado em
            newOrderFields.push({
                id_field: -1,
                type: "DATE_PICKER_FIELD",
                title: "Criado em"
            });

            //Criado por
            newOrderFields.push({
                id_field: -2,
                type: "TEXT_SHORT_FIELD",
                title: "Criado por"
            });

            //Identificador
            newOrderFields.push({
                id_field: -3,
                type: "TEXT_SHORT_FIELD",
                title: "Identificador"
            });

            //Add all custom fields
            newField.filter((f) => getFieldObject(f.type).onSorter).map((f) => {
                if (f.id_field !== undefined) {
                    newOrderFields.push({
                        id_field: f.id_field,
                        form_id: f.form_id,
                        type: f.type,
                        title: f.title
                    });
                }
                return f;
            });

            setFieldsOrder(newOrderFields);

            //Prepare the sort fields
            let newViewFields: FieldViewProps[] = [];

            newViewFields.push(...newField
                .filter((f) => f.id_field !== undefined && f.id_field > 0 && getFieldObject(f.type).onViewFlow)
                .map((f, index) => {

                    let newOrigin = "";
                    if (f.id_field !== undefined && f.id_field < 0) {
                        newOrigin = "Padrão";
                    } else if (f.form_id === register.form_id) {
                        newOrigin = "Campos";
                    }

                    return {
                        id_field: f.id_field,
                        form_id: f.form_id,
                        title: f.title,
                        index: index,
                        type: f.type,
                        variation: f.variation,
                        maxLength: f.max_length,
                        origin: newOrigin,
                        indexOrigin: 1,
                        reordered: false,
                        active: true
                    } as FieldViewProps
                }));

            const indexStart = newViewFields.length;

            //Criado em
            newViewFields.push({
                id_field: -1,
                form_id: 0,
                title: "Criado em",
                index: indexStart + 1,
                active: true,
                type: "DATE_PICKER_FIELD",
                origin: "Padrão",
                indexOrigin: 0,
                reordered: false
            });

            //Criado por
            newViewFields.push({
                id_field: -2,
                form_id: 9999999,
                title: "Criado por",
                index: indexStart + 2,
                active: true,
                type: "TEXT_SHORT_FIELD",
                origin: "Padrão",
                indexOrigin: 0,
                reordered: false
            });

            //Identificador
            newViewFields.push({
                id_field: -3,
                form_id: 9999999,
                title: "Identificador",
                index: indexStart + 3,
                active: true,
                type: "TEXT_SHORT_FIELD",
                origin: "Padrão",
                indexOrigin: 0,
                reordered: false
            });

            //Restore the selectedValue saved
            if (selectedValue !== undefined && selectedValue.fieldView !== undefined && selectedValue.fieldView.length > 0) {
                newViewFields = newViewFields.map((nvf) => {

                    const findViewField = selectedValue.fieldView?.find((f) => f.id_field === nvf.id_field);
                    if (findViewField !== undefined) {
                        nvf.active = findViewField.active;
                        nvf.index = findViewField.index;
                        nvf.reordered = findViewField.reordered;
                    }

                    return nvf;
                })
            } else { //Restore the value from the legacy
                newViewFields = newViewFields.map((nvf) => {

                    const findField = newField.find((f) => f.id_field !== undefined && f.id_field > 0 && nvf.id_field === f.id_field);
                    if (findField !== undefined && findField.card_highlight !== undefined && findField.card_highlight !== null && findField.card_highlight === "1") {
                        nvf.active = true;
                    }

                    return nvf;
                })
            }

            //Order by index origin and after by index
            newViewFields = newViewFields.sort(sortFieldView);

            setFieldsView(newViewFields);

        }

    }, [register, selectedValue])

    useEffect(() => {

        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }

    }, [searchField]);

    useEffect(() => {
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setSearchText('');
                setSearchField(false);
            }
        };

        window.addEventListener('keydown', handleEscape);

        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, []);

    useEffect(() => {

        //Set the new view
        if (selectedValue) {

            if (selectedValue?.conditions) {
                setConditionItems(selectedValue.conditions);
            }

            if (selectedValue?.orderBy) {
                setOrderItems(selectedValue.orderBy);
            }

            if (selectedValue?.searchText) {
                setSearchText(selectedValue.searchText);
            }

        }

    }, [selectedValue]);

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box style={{ display: "flex", width: "100%" }}>

                    <ButtonLink type="button"
                        onClick={() => {

                            setOpenFields(!openFields);
                            setOpenOrder(false);
                            setOpenFilter(false);

                            if (searchText === undefined || searchText === "") {
                                setSearchField(false);
                            }
                        }}
                    >
                        <AiOutlineEyeInvisible />
                        <div>Campos</div>
                    </ButtonLink>

                    <ButtonLink type="button"
                        onClick={() => {

                            addDefaultItemsFilter();

                            setOpenFilter(!openFilter);
                            setOpenOrder(false);
                            setOpenFields(false);

                            if (searchText === undefined || searchText === "") {
                                setSearchField(false);
                            }
                        }}
                        active={filterState}>
                        <MdOutlineFilterList />
                        <div>{filterState ? filterStateMessage : "Filtrar"}</div>
                    </ButtonLink>

                    <ButtonLink type="button"
                        onClick={() => {

                            addDefaultItemsOrder();

                            setOpenOrder(!openOrder);
                            setOpenFilter(false);
                            setOpenFields(false);

                            if (searchText === undefined || searchText === "") {
                                setSearchField(false);
                            }
                        }}
                        active={orderState}>
                        <BsSortDown />
                        <div>{orderState ? orderStateMessage : "Ordenar"}</div>
                    </ButtonLink>

                    {!searchField ?
                        <ButtonLink type="button"
                            onClick={() => {
                                setSearchField(true);
                                setOpenFilter(false);
                                setOpenFields(false);
                                setOpenOrder(false);
                            }}>
                            <AiOutlineSearch />
                            <div>Pesquisar</div>
                        </ButtonLink> :
                        <SearchInput>
                            <AiOutlineSearch />
                            <Input
                                ref={searchInputRef}
                                onChange={(e) => onSearch(e)}
                                placeholder="Busque aqui..."
                                value={searchText}
                            />
                            {searchText !== undefined && searchText !== "" ?
                                <IoIosCloseCircle
                                    style={{ marginRight: "9px" }}
                                    onClick={() => {
                                        setSearchText("");
                                        setSearchField(false);
                                    }}
                                /> :
                                <></>
                            }
                        </SearchInput>
                    }

                    {openFields ? (
                        <BoxContainerField>

                            <FieldsContainer>
                                <h2>Campos da visualização</h2>
                                <span>Selecione e ordene o que você deseja exibir na visualização</span>
                            </FieldsContainer>

                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="selectFieldView">
                                    {(provided) => (
                                        <div  {...provided.droppableProps} ref={provided.innerRef}>
                                            {fieldsView.sort(sortFieldView).map((f, index) => {

                                                const Icon = getFieldObject(f.type).icon;

                                                return (
                                                    <Draggable
                                                        key={f.id_field}
                                                        draggableId={f.id_field.toString()}
                                                        index={index}>
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <FieldViewItem onClick={() => !f.disabled ? handleSelectFieldViewItem(f.id_field) : {}}>
                                                                    <FieldViewItemToggle>
                                                                        {!f.disabled ?
                                                                            f.active !== undefined && f.active === true ?
                                                                                <BsToggleOn color="green" /> :
                                                                                <BsToggleOff /> :
                                                                            <BsToggleOn color="gray" />
                                                                        }
                                                                    </FieldViewItemToggle>
                                                                    <FieldViewItemIcon><Icon /></FieldViewItemIcon>
                                                                    <FieldViewItemTitle>{f.title}</FieldViewItemTitle>
                                                                    <FieldViewItemOrigin>{f.origin}</FieldViewItemOrigin>
                                                                    <FieldViewItemDrag className="drag-handle">
                                                                        <MdOutlineDragIndicator />
                                                                    </FieldViewItemDrag>
                                                                </FieldViewItem>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>

                        </BoxContainerField>
                    ) : <></>
                    }

                    {openFilter ? (
                        <BoxContainer>

                            {conditionItems.map((condition, index) => {

                                const nameComponent: string = getFilterNameComponent(condition.selectedField.type);

                                let FilterComponent = getFilterComponent(nameComponent);

                                return (

                                    <FilterContainer key={condition.index}>

                                        <SelectInput value={condition.selectedField.id_field} onChange={(e) => onSelectFieldFilter(e.currentTarget.value, index)}>
                                            <optgroup label="Campos Padrões">
                                                <option key={-1} value={-1}>{"Criado em"}</option>
                                                <option key={-2} value={-2}>{"Criado por"}</option>
                                                <option key={-3} value={-3}>{"Identificador"}</option>
                                            </optgroup>
                                            {fields && fields.filter((f) => f.index >= 0).length > 0 ?
                                                <optgroup label="Campos Customizáveis">
                                                    {fields.filter((f) => f.index >= 0).map((field) => {
                                                        return (
                                                            <option key={field.id_field} value={field.id_field}>{field.title}</option>
                                                        )
                                                    })}
                                                </optgroup> :
                                                <></>}
                                        </SelectInput>

                                        <SelectComparator value={condition.selectedComparator} onChange={(e) => onSelectComparator(e.currentTarget.value, index)}>
                                            {condition.comparators.map((comparator) => {
                                                return (
                                                    <option key={comparator} value={comparator}>{comparator}</option>
                                                )
                                            })}
                                        </SelectComparator>

                                        <FilterComponent
                                            comparator={condition.selectedComparator}
                                            onEvent={(e, cond) => onEventFieldComponent(index, e?.currentTarget.value, cond)}
                                            value={condition.value}
                                            valueOptions={condition.valueOptions}
                                            field={condition.selectedField}
                                            options={condition.selectedField.options}
                                        />

                                        <DeleteButton onClick={() => handleDeleteConditionFilter(index)}>
                                            <FaTrash />
                                        </DeleteButton>

                                    </FilterContainer>

                                )

                            })}

                            {(conditionItems === undefined || conditionItems.length <= 0) ? (
                                <div style={{ padding: '10px', fontSize: '14px' }}>Você não possui campos para filtrar ainda, adicione novos campos para usar esta funcionalidade ;)</div>
                            ) : (

                                <FilterBottom>

                                    <AddNewFilter onClick={() => addNewConditionFilter()}>
                                        <FaPlus />
                                        Adicionar condição
                                    </AddNewFilter>

                                    <CleanFilter onClick={() => handleDeleteAllConditionFilter()}>
                                        <FaTrash />
                                        Limpar Tudo
                                    </CleanFilter>

                                    <GoFilter onClick={() => setOpenFilter(!openFilter)}>
                                        <AiFillThunderbolt />
                                        Filtrar
                                    </GoFilter>

                                </FilterBottom>

                            )}

                        </BoxContainer>
                    ) : <></>
                    }

                    {openOrder ? (
                        <BoxContainerOrder>

                            {orderItems.map((order, index) => {

                                return (

                                    <FilterContainer key={order.index}>

                                        <SelectInput value={order.selectedField.id_field} onChange={(e) => onSelectFieldOrder(e.currentTarget.value, index)}>
                                            <optgroup label="Campos Padrões">
                                                <option key={-1} value={-1}>{"Criado em"}</option>
                                                <option key={-2} value={-2}>{"Criado por"}</option>
                                                <option key={-3} value={-3}>{"Identificador"}</option>
                                            </optgroup>

                                            {fieldsOrder && fieldsOrder.filter((f) => f.id_field > 0).length > 0 ?
                                                <optgroup label="Campos Customizáveis">
                                                    {fieldsOrder.filter((f) => f.id_field > 0).map((field) => {
                                                        return (
                                                            <option key={field.id_field} value={field.id_field}>{field.title}</option>
                                                        )
                                                    })}
                                                </optgroup> :
                                                <></>
                                            }
                                        </SelectInput>

                                        <SelectComparatorOrder value={order.selectedOrder} onChange={(e) => onSelectOrder(e.currentTarget.value, index)}>
                                            {order.orders ?
                                                order.orders.map((order) => {
                                                    return (
                                                        <option key={order} value={order}>{order}</option>
                                                    )
                                                }) :
                                                <></>
                                            }
                                        </SelectComparatorOrder>

                                        <DeleteButton onClick={() => handleDeleteConditionOrder(index)}>
                                            <FaTrash />
                                        </DeleteButton>

                                    </FilterContainer>

                                )

                            })}

                            {(orderItems === undefined || orderItems.length <= 0) ? (
                                <div style={{ padding: '10px', fontSize: '14px' }}>Você não possui campos para ordenar ainda, adicione novos campos para usar esta funcionalidade ;)</div>
                            ) : (

                                <FilterBottom>

                                    <AddNewFilter onClick={() => addNewConditionOrder()}>
                                        <FaPlus />
                                        Adicionar ordenação
                                    </AddNewFilter>

                                    <CleanFilter style={{ marginRight: '20px' }} onClick={() => handleDeleteAllConditionOrder()}>
                                        <FaTrash />
                                        Limpar Tudo
                                    </CleanFilter>

                                    <GoFilter onClick={() => setOpenOrder(!openOrder)}>
                                        <AiFillThunderbolt />
                                        Ordenar
                                    </GoFilter>

                                </FilterBottom>

                            )}

                        </BoxContainerOrder>
                    ) : <></>
                    }

                </Box>
            </ClickAwayListener >
        </>
    );
};
export default FilterDialogRegister;
