import {
    Container,
    ContainerBox,
    ContainerBoxTitle,
    ContainerBoxBody,
    ContainerBoxBodyKpis,
    KpiCardContainer,
    KpiCardHeader,
    KipCardTitle,
    KpiCardBody,
    KpiCardBodyValue,
    KpiCardBodyValueComplement,
    KpiCardComplementBackground,
    ContainerLoader,
    Animation,
    LogGroup,
    LogGroupItem,
    LogGroupLine,
    LogGroupTitle,
    LogGroupTitleText,
    LogArea,
    LogContainer,
    LogBody,
    LogHistoryItem,
    LogHistoryAvatar,
    LogHistoryUserName,
    LogHistoryDescription,
    LogHistoryDate,
    LogHistoryChildren,
    LogHistoryExpanded
} from "./style";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Flow } from "../../../interfaces/Flow";
import { IoMdPodium } from "react-icons/io";
import { MdNumbers } from "react-icons/md";
import { TbHistory } from "react-icons/tb";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import { formatTimeBySeconds } from "../../../utils/formatTimeBySeconds";
import RankingChart from "./RankingChart";
import { LogHistory } from "../../../interfaces/LogHistory";
import { LogItems } from "../../../pages/Flow/ActivityTab/style";
import AvatarCange from "../../../components/AvatarCange";
import { formatDateToDateRead } from "../../../utils/formatDateToDateRead";
import { useAuth } from "../../../hooks/auth";
import lottie from "lottie-web";
import { styled, Tooltip, TooltipProps } from "@material-ui/core";

interface MailTabProps {
    typeUser: string;
    flow?: Flow;
    card_id?: number
}

interface KpisCardProps {
    card_life_time?: number;
    card_average_time?: number;
    card_step_life_time?: number;
    card_step_average_time?: number;
    card_last_activity?: number;
    card_total_hours?: number;
    card_remaining_time?: number;
}


const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    "& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom": {
        backgroundColor: "#1F2D32",
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}));


const ActivityTab: React.FC<MailTabProps> = ({ card_id, flow, typeUser }) => {

    const { addToast } = useToast();
    const { user } = useAuth();
    const lottieContainer = useRef<HTMLDivElement>(null);

    const [kpisCard, setKpisCard] = useState<KpisCardProps>();
    const [activities, setActivities] = useState<LogHistory[]>([]);

    const [loadingKpis, setLoadingKpis] = useState<boolean>(true);
    const [loadingActivities, setLoadingActivities] = useState<boolean>(true);

    const [groupByType] = useState<number>(0); // 0 - Date Group
    const [expandedGroups, setExpandedGroups] = useState<string[]>([]);
    const [allExpanded] = useState<boolean>(false);

    const getKpisCard = useCallback(async () => {

        if (flow !== undefined && card_id !== undefined && flow.id_flow !== undefined) {

            setLoadingKpis(true);

            await api.get('/activity/kpis/by-card', {
                params: {
                    flow_id: flow?.id_flow,
                    card_id: card_id
                }
            }).then(response => {

                setKpisCard(response.data);

                setLoadingKpis(false);

            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar indicadores do cartão',
                    description: 'Erro ao buscar indicadores do cartão, tente novamente'
                });
            });
        }

    }, [flow, card_id, addToast]);

    const getActivities = useCallback(async () => {

        if (flow !== undefined && card_id !== undefined && flow.id_flow !== undefined) {

            setLoadingActivities(true);

            await api.get('/activity/by-card', {
                params: {
                    flow_id: flow?.id_flow,
                    card_id: card_id
                }
            }).then(response => {

                let respActivities: LogHistory[] = response.data;

                if (respActivities !== undefined) {

                    //Format date
                    respActivities = respActivities.map((activity: LogHistory) => {

                        return {
                            ...activity,
                            date_group: formatDateToDateRead(new Date(activity.dt_action))
                        }

                    });

                    setActivities(respActivities);
                }

                setLoadingActivities(false);

            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao buscar indicadores do cartão',
                    description: 'Erro ao buscar indicadores do cartão, tente novamente'
                });
            });
        }

    }, [flow, card_id, addToast]);

    const toggleGroup = (id: string) => {
        if (expandedGroups.includes(id)) {
            setExpandedGroups(expandedGroups.filter((group) => group !== id));
        } else {
            setExpandedGroups([...expandedGroups, id]);
        }
    };

    const groupedLogs: { [groupName: string]: LogHistory[] } = activities.reduce(
        (acc, item) => {

            if (groupByType === 0) { //Date Group
                if (item.date_group !== undefined) {
                    if (acc[item.date_group]) {
                        acc[item.date_group].push(item);
                    } else {
                        acc[item.date_group] = [item];
                    }
                }
            }

            return acc;
        },
        {} as { [groupName: string]: LogHistory[] }
    );

    useEffect(() => {

        getKpisCard();
        getActivities();

    }, [getKpisCard, getActivities]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../assets/lottie/loader.json')
            })
        }
    }, [loadingActivities]);

    return (
        <Container>

            <ContainerBox>
                <ContainerBoxTitle>
                    <MdNumbers />
                    <h3>Indicadores do cartão</h3>
                </ContainerBoxTitle>
                <ContainerBoxBodyKpis>

                    <KpiCardComplementBackground>
                        <DarkTooltip title={
                            <React.Fragment>
                                <div>Tempo total que o cartão existe, desde a sua criação até agora</div>
                            </React.Fragment>
                        }>
                            <KpiCardContainer>
                                <KpiCardHeader>
                                    <KipCardTitle>
                                        Vida do cartão
                                    </KipCardTitle>
                                </KpiCardHeader>
                                <KpiCardBody>
                                    <KpiCardBodyValue>
                                        {loadingKpis ?

                                            <div>...</div> :
                                            <div>
                                                {formatTimeBySeconds(kpisCard?.card_life_time || 0).total}
                                            </div>
                                        }
                                    </KpiCardBodyValue>
                                    <KpiCardBodyValueComplement>
                                        {formatTimeBySeconds(kpisCard?.card_life_time || 0).unit}
                                    </KpiCardBodyValueComplement>
                                </KpiCardBody>
                            </KpiCardContainer>
                        </DarkTooltip>
                    </KpiCardComplementBackground>

                    <KpiCardComplementBackground>
                        <DarkTooltip title={
                            <React.Fragment>
                                <div>Tempo total que o cartão está nesta estapa, desde a sua última entrada até agora</div>
                            </React.Fragment>
                        }>
                            <KpiCardContainer>
                                <KpiCardHeader>
                                    <KipCardTitle>
                                        Tempo na etapa
                                    </KipCardTitle>
                                </KpiCardHeader>
                                <KpiCardBody>
                                    <KpiCardBodyValue>
                                        {loadingKpis ?

                                            <div>...</div> :
                                            <div>
                                                {formatTimeBySeconds(kpisCard?.card_step_life_time || 0).total}
                                            </div>
                                        }
                                    </KpiCardBodyValue>
                                    <KpiCardBodyValueComplement>
                                        {formatTimeBySeconds(kpisCard?.card_step_life_time || 0).unit}
                                    </KpiCardBodyValueComplement>
                                </KpiCardBody>
                            </KpiCardContainer>
                        </DarkTooltip>
                    </KpiCardComplementBackground>

                    <KpiCardComplementBackground>
                        <DarkTooltip title={
                            <React.Fragment>
                                <div>Tempo total entre a última atividade feita no cartão até agora</div>
                            </React.Fragment>
                        }>
                            <KpiCardContainer>
                                <KpiCardHeader>
                                    <KipCardTitle>
                                        Última atividade
                                    </KipCardTitle>
                                </KpiCardHeader>
                                <KpiCardBody>
                                    <KpiCardBodyValue>
                                        {loadingKpis ?

                                            <div>...</div> :
                                            <div>
                                                {formatTimeBySeconds(kpisCard?.card_last_activity || 0).total}
                                            </div>
                                        }
                                    </KpiCardBodyValue>
                                    <KpiCardBodyValueComplement>
                                        {formatTimeBySeconds(kpisCard?.card_last_activity || 0).unit} atrás
                                    </KpiCardBodyValueComplement>
                                </KpiCardBody>
                            </KpiCardContainer>
                        </DarkTooltip>
                    </KpiCardComplementBackground>

                </ContainerBoxBodyKpis>
            </ContainerBox>

            <ContainerBox>
                <ContainerBoxTitle>
                    <IoMdPodium />
                    <h3>Colaboradores do cartão</h3>
                </ContainerBoxTitle>
                <ContainerBoxBody>

                    <RankingChart flow_id={flow?.id_flow || 0} card_id={card_id || 0} />

                </ContainerBoxBody>
            </ContainerBox>

            <ContainerBox>
                <ContainerBoxTitle>
                    <TbHistory />
                    <h3>Atividades</h3>
                </ContainerBoxTitle>
                <ContainerBoxBody style={{ flexDirection: 'column', padding: 0, paddingTop: '5px' }}>

                    {loadingActivities ?
                        <ContainerLoader>
                            <Animation className="lottieContainer" ref={lottieContainer} />
                        </ContainerLoader > :
                        <>
                            {Object.entries(groupedLogs).map(([groupName, itemsLog]) => {

                                return (
                                    <LogGroup key={groupName}>
                                        <LogGroupItem>
                                            <LogGroupLine />
                                            <LogGroupTitle>

                                                <LogGroupTitleText>
                                                    {groupName}
                                                </LogGroupTitleText>

                                            </LogGroupTitle>

                                        </LogGroupItem>

                                        <LogItems>
                                            {itemsLog.map((log) => {

                                                let isExpanded = expandedGroups.includes(String(log.id_log_history));

                                                if (allExpanded) {
                                                    isExpanded = true;
                                                }

                                                return (
                                                    <LogArea
                                                        key={log.id_log_history}
                                                    >
                                                        <LogContainer key={log.id_log_history} className="log-container">
                                                            <LogBody>

                                                                <LogHistoryItem>
                                                                    <LogHistoryAvatar>
                                                                        <AvatarCange user={log.user} size={"20"} />
                                                                    </LogHistoryAvatar>
                                                                    <LogHistoryUserName>
                                                                        {log.user.name}{log.user_id === user.id_user ? ' (você):' : ':'}
                                                                    </LogHistoryUserName>
                                                                    <LogHistoryDescription>
                                                                        {log.description} <b>·</b> <LogHistoryDate>{formatDateToDateRead(new Date(log.dt_action), true)}</LogHistoryDate>
                                                                    </LogHistoryDescription>
                                                                </LogHistoryItem>

                                                                {log.log_children !== undefined && log.log_children.length > 0 ?
                                                                    !isExpanded ?
                                                                        <LogHistoryChildren onClick={() => toggleGroup(String(log.id_log_history))}>
                                                                            Mostrar mais {log.log_children?.length} {log.log_children?.length === 1 ? 'atualização' : 'atualizações'}
                                                                        </LogHistoryChildren> :
                                                                        <LogHistoryExpanded>
                                                                            {log.log_children.map((logChild, index) => {

                                                                                //Check if the last log is the same user
                                                                                let showAvatarUser = true;

                                                                                if (index === 0) {
                                                                                    if (logChild.user_id === log.user_id) {
                                                                                        showAvatarUser = false;
                                                                                    }
                                                                                } else {
                                                                                    if (log.log_children !== undefined) {
                                                                                        if (logChild.user_id === log.log_children[index - 1].user_id) {
                                                                                            showAvatarUser = false;
                                                                                        }
                                                                                    }
                                                                                }

                                                                                return (
                                                                                    <>
                                                                                        {showAvatarUser ? <div style={{ marginTop: '5px' }}></div> : <></>}
                                                                                        <LogHistoryItem key={logChild.id_log_history}>
                                                                                            {showAvatarUser ?
                                                                                                <LogHistoryAvatar>
                                                                                                    <AvatarCange user={logChild.user} size={"20"} />
                                                                                                </LogHistoryAvatar> :
                                                                                                <></>
                                                                                            }
                                                                                            <LogHistoryUserName>
                                                                                                {logChild.user.name}{logChild.user_id === user.id_user ? ' (você):' : ':'}
                                                                                            </LogHistoryUserName>
                                                                                            <LogHistoryDescription>
                                                                                                {logChild.description} <b>·</b> <LogHistoryDate>{formatDateToDateRead(new Date(logChild.dt_action), true)}</LogHistoryDate>
                                                                                            </LogHistoryDescription>
                                                                                        </LogHistoryItem>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </LogHistoryExpanded> :
                                                                    <></>
                                                                }
                                                            </LogBody>
                                                        </LogContainer>
                                                    </LogArea>
                                                )
                                            })}
                                        </LogItems>
                                    </LogGroup>
                                )
                            })}
                        </>}

                </ContainerBoxBody>
            </ContainerBox>


        </Container>
    );

}

export default ActivityTab;