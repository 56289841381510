import { Box, ClickAwayListener } from "@material-ui/core";
import {
    BoxAddNew,
    BoxContainer,
    BoxItemsContainer,
    BoxSearchContainer,
    ButtonAddLeft,
    ButtonAddRegister,
    ButtonAddRight,
    ButtonBack,
    ButtonNew,
    Container,
    FormItemsContainer,
    FormItemsCounter,
    FormItemsForm,
    FormItemsHeader,
    ItemRegisterContainer,
    ItemRegisterDetail,
    ItemRegisterDetailBody,
    ItemRegisterDetailContainer,
    ItemRegisterDetailHead,
    ItemRegisterHead,
    ItemRegisterIcon,
    ItemRegisterTitle,
    NoResults,
    ProgressBarTask,
    ProgressBarTaskLine,
    ProgressBarTaskLineInside,
    ProgressBarTaskPercent,
    SearchInput
} from "./style";
import { FaExternalLinkAlt, FaPlus } from "react-icons/fa";
import { IconList, IconPickerItem } from 'react-fa-icon-picker'
import ItemRegister, { ComboItem } from "../../../../ItemRegister";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Card } from "../../../../../interfaces/Card";
import ContentLoader from "react-content-loader";
import ErrorDescription from "../../../../ErrorDescription";
import { Flow } from "../../../../../interfaces/Flow";
import { FormAnswer } from "../../../../../interfaces/FormAnswer";
import { FormAnswerApi } from "../../../../../interfaces/FormAnswerApi";
import { FormAnswerFieldApi } from "../../../../../interfaces/FormAnswerFieldApi";
import FormBuilder from "../../../FormBuilder";
import { FormHandles } from "@unform/core";
import { IoMdListBox } from "react-icons/io";
import { Link } from "react-router-dom";
import { TiFlowMerge } from "react-icons/ti";
import api from '../../../../../services/api';
import formAnswerToComboItemFromFlow from "../../../../../utils/formAnswerToComboItemFromFlow";
import { FieldProps, getItemComponentIcon } from "../../FieldBuilder";
import { Props as reacSelectProps } from "react-select";
import { useField } from "@unform/core";
import { useRouteMatch } from 'react-router-dom';
import { useToast } from '../../../../../hooks/toast';
import formAnswerToObjectFormInit from "../../../../../utils/formAnswerToObjectFormInit";
import getAutoCompleteRule from "../../../../../utils/getAutoCompleteRule";
import { useAuth } from "../../../../../hooks/auth";

interface RouteParams {
    hash: string;
    origin?: string;
    id?: string;
}

interface ComboOption {
    value: string;
    label: string;
}

interface objectInit {
    [x: string]: string | object
}

interface ComboBoxFlowProps extends reacSelectProps {
    name: string;
    variation?: string;
    placeholder?: string;
    flow_id?: number;
    options?: ComboOption[];
    max_length?: number;
    previewField?: boolean;
    flow_base_id?: number;
    card_current_id?: number;
    isPublicForm?: boolean;
    public_hash?: string;
    filter_schema?: string;
    isFromAutomation?: boolean;
    user_creator_id?: number;
    onForceBlur?: () => void;
}

const LoaderContainer = () => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={65}
        viewBox="0 0 500 65"
        backgroundColor="#ffffff"
        foregroundColor="#f3f3f3"
    >
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="50" />
    </ContentLoader>
)

const ComboBoxFlow: React.FC<ComboBoxFlowProps> = ({ variation, name, flow_id, placeholder, options, max_length, previewField, flow_base_id, card_current_id, onForceBlur, isPublicForm, public_hash, filter_schema, isFromAutomation, user_creator_id, ...rest }) => {

    //Variation
    //1 - Busca registros existentes no cadastro
    //2 - Criar novo registro no cadastro
    //3 - Buscar ou criar novo registro no cadastro

    //Max Lenght
    //1 - Único Item
    //2 - Múltiplos Itens

    //Possibilities (Mode)
    //1 - Busca registros + Único Item
    //2 - Busca registros + Múltiplos Itens
    //3 - Criar novo registro + Único Item
    //4 - Criar novo registro + Múltiplos Itens
    //5 - Busca ou criar novo registro + Único Item
    //6 - Busca ou criar novo registro + Múltiplos Itens

    //Function to define the possibilitie and set in a constant to work with
    function defineMode(variation: string | undefined, max_length: number | undefined) {

        let possibilitie: number = 1;

        //if max_length is undefined, set as 1
        if (max_length === undefined || max_length === null || max_length === 0) {
            max_length = 1;
        }

        if (variation === "1" && max_length === 1) {
            possibilitie = 1; //1 - Busca registros + Único Item
        } else if (variation === "1" && max_length === 2) {
            possibilitie = 2; //2 - Busca registros + Múltiplos Itens
        } else if (variation === "2" && max_length === 1) {
            possibilitie = 3; //3 - Criar novo registro + Único Item
        } else if (variation === "2" && max_length === 2) {
            possibilitie = 4; //4 - Criar novo registro + Múltiplos Itens
        } else if (variation === "3" && max_length === 1) {
            possibilitie = 5; //5 - Busca ou criar novo registro + Único Item
        } else if (variation === "3" && max_length === 2) {
            possibilitie = 6; //6 - Busca ou criar novo registro + Múltiplos Itens
        }

        return possibilitie;

    };

    //Function to define the place holder
    function definePlaceHolder(mode: number, placeholder: string | undefined) {

        let newPlaceHolder = placeholder;
        if (placeholder !== undefined && placeholder !== "" && placeholder !== null) {
            return newPlaceHolder;
        } else if (mode === 1 || mode === 2) {
            return "Selecione um cartão...";
        } else if (mode === 3 || mode === 4) {
            return "Crie um novo cartão...";
        } else if (mode === 5 || mode === 6) {
            return "Selecione ou crie um novo cartão...";
        }

    }

    //Function to compare and sort the items
    function compareItems(a: ComboItem, b: ComboItem): number {
        // Critério 1: Items com o campo completed igual a "S"
        if (a.complete === 'S' && b.complete !== 'S') {
            return 1; // a vem antes de b
        }
        if (a.complete !== 'S' && b.complete === 'S') {
            return -1; // a vem depois de b
        }

        // Critério 3: Items com o campo due_date_completed mais antigos
        if (a.due_date_completed && b.due_date_completed) {
            const dateA = new Date(a.due_date_completed).getTime();
            const dateB = new Date(b.due_date_completed).getTime();
            return dateA - dateB; // Ordenação ascendente por data
        }

        // Critério 2: Items com o campo due_date_completed igual a null ou undefined
        if (!a.due_date_completed && b.due_date_completed) {
            return 1; // a vem antes de b
        }
        if (a.due_date_completed && !b.due_date_completed) {
            return -1; // a vem depois de b
        }

        if (a.id_register !== undefined && b.id_register !== undefined) {
            return a.id_register - b.id_register;
        }

        // Se todos os critérios forem iguais, mantém a ordem original
        return 0;

    }

    //Define the mode
    const mode: number = defineMode(variation, max_length);

    placeholder = definePlaceHolder(mode, placeholder);
    max_length = max_length === undefined ? 1 : max_length;
    variation = variation !== "" && variation !== undefined && variation !== null ? variation : "1";

    const { fieldName, error, registerField, defaultValue } = useField(name);
    const { params } = useRouteMatch<RouteParams>();
    const { addToast } = useToast();
    const { user } = useAuth();

    const selectRef = useRef(null);
    const formRef = useRef<FormHandles>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const [searchValue] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingForm, setLoadingForm] = useState<boolean>(false);
    const [loadingAdding, setLoadingAdding] = useState<boolean>(false);
    const [items, setItems] = useState<ComboItem[]>();
    const [flow, setFlow] = useState<Flow>();
    const [itemSelected, setItemSelected] = useState<ComboItem[]>([]);
    const [itemsFiltered, setItemsFiltered] = useState<ComboItem[]>();
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [firstUpdate, setFirstUpdate] = useState<boolean>(false);
    const [percentage, setPercentage] = useState<number>();
    const [initValue, setInitValue] = useState({});
    const [loadedAutoComplete, setLoadedAutoComplete] = useState<boolean>(false);
    const [needBlur, setNeedBlur] = useState<boolean>(false);

    const [fields, setFields] = useState<FieldProps[]>();

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const handleSelect = useCallback(async (item: ComboItem) => {

        const haveInSelected = itemSelected.filter((itemSel) => itemSel.id_register === item.id_register);

        //Valid to not insert a duplicate item
        if (haveInSelected.length <= 0) {
            if (defaultValue === undefined) {
                setItemSelected(oldItems => [...oldItems, item]);
                setOpen(false);
                setNeedBlur(true);
            } else if (defaultValue !== undefined && flow_id !== undefined) { //If is a record reference
                setItemSelected(oldItems => [...oldItems, item]);
                setOpen(false);
                setNeedBlur(true);
            } else {
                addToast({
                    type: 'error',
                    title: 'Não é possível remover o fluxo atual!',
                    description: 'Para fazer esta alteração será necessário remover o campo e incluir um novo! Todos os dados poderão ser perdidos!',
                });
            }
        } else {
            addToast({
                type: 'error',
                title: "O Registro '" + item.title + "' já está na seleção!",
                description: 'Você deve escolher um registro que ainda não está na sua seleção!',
            });
        }

    }, [addToast, defaultValue, flow_id, itemSelected]);

    const handleRemove = useCallback(async (itemRemove: ComboItem) => {

        let oldItems: ComboItem[] = [];

        itemSelected.map((item) => {
            if (item.id_register !== itemRemove.id_register) {
                oldItems.push(item);
            }
            return item;
        });

        if (defaultValue === undefined) {
            setItemSelected(oldItems);
            setNeedBlur(true);
        } else if (defaultValue !== undefined && flow_id !== undefined) { //If is a record reference
            setItemSelected(oldItems);
            setNeedBlur(true);
        } else { //If is a Flow reference
            addToast({
                type: 'error',
                title: 'Não é possível remover o fluxo atual!',
                description: 'Para fazer esta alteração será necessário remover o campo e incluir um novo! Todos os dados poderão ser perdidos!',
            });
        }

    }, [addToast, defaultValue, flow_id, itemSelected]);

    const handleFilter = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {

        if (items !== undefined) {

            const searchText = e.currentTarget.value.toLowerCase();

            let itemsNew = items;

            //Create logic to dynamic content
            const itemsTitle = itemsNew.filter((item) => {

                //Filter by title
                if (item.title.toLowerCase().includes(searchText)) {
                    return true;
                }

                //Filter by description
                if (item.origin !== undefined) {
                    if (item.origin.toLowerCase().includes(searchText)) {
                        return true;
                    }
                }

                //Filter by details
                if (item.details !== undefined && item.details.length > 0) {
                    for (let index = 0; index < item.details.length; index++) {
                        const detail = item.details[index];

                        if (detail.value.toLowerCase().includes(searchText)) {
                            return true;
                        }

                    }
                }

                return false;

            });

            setItemsFiltered(itemsTitle);

        }

    }, [items]);

    const handleAddNewItem = useCallback(async () => {

        if (formRef.current !== null) {
            formRef.current.submitForm();
            //formRef.current.reset();
        }

    }, []);

    const handleSubmit = useCallback(async (data: object[]) => {

        //Variables
        const dataNormalized = data as unknown as FormAnswerFieldApi[];
        let objApi: FormAnswerApi | undefined = undefined;

        if (flow !== undefined && flow.form_init_id !== undefined) { //If is a Register

            objApi = {
                id_form: flow?.form_init_id,
                origin: isPublicForm ? "/PublicForm" : "/ComboBoxFlow",
                values: dataNormalized,
                flow_id: flow?.id_flow,
                deleted: "W",
                flow_parent_id: flow_base_id,
                public_hash: isPublicForm && public_hash ? public_hash : undefined
            }

        } else {
            addToast({
                type: 'error',
                title: 'Erro ao inserir um novo item [2]',
                description: 'Ocorreu um erro ao inserir o registro!',
            });
        }

        const endpoint = isPublicForm ? `/form-public/new-answer` : '/form/new-answer';
        const endpointGet = isPublicForm ? `/form-public/card` : `/card`;

        if (flow !== undefined && objApi !== undefined) {

            //setOpenForm(false);
            setLoadingAdding(true);

            await api
                .post(endpoint, objApi)
                .then(response => {

                    let retAnswer: FormAnswer = response.data;

                    //Renew the object and set as selected
                    api.get(endpointGet, {
                        params: {
                            id_card: retAnswer.card_id,
                            flow_id: flow.id_flow,
                            withWaitingAnswers: true,
                            public_hash: isPublicForm && public_hash ? public_hash : undefined
                        }
                    }).then(response => {

                        const retCard: Card = response.data;

                        const newComboItem: ComboItem = formAnswerToComboItemFromFlow(retCard, flow);

                        setItemSelected(oldItems => [...oldItems, newComboItem]);
                        setNeedBlur(true);

                        if (variation === "2") {
                            setOpenForm(false);
                        } else if (variation === "3") {
                            setOpenForm(false);
                            setOpen(false);
                        }

                        setLoadingAdding(false);
                    }).catch(error => {
                        setLoadingAdding(false);
                        console.log(error);
                        addToast({
                            type: 'error',
                            title: 'Erro ao inserir um novo item [4]',
                            description: 'Ocorreu um erro ao inserir o registro!',
                        });
                    });

                }).catch(error => {
                    setLoadingAdding(false);
                    console.log(error);
                    addToast({
                        type: 'error',
                        title: 'Erro ao inserir um novo item [1]',
                        description: 'Ocorreu um erro ao inserir o registro!',
                    });
                });


        } else {
            addToast({
                type: 'error',
                title: 'Erro ao inserir um novo item [3]',
                description: 'Ocorreu um erro ao inserir o registro!',
            });
        }

    }, [addToast, flow, variation, flow_base_id, isPublicForm, public_hash]);

    const getApiCard = useCallback(async (flowObj: Flow) => {

        let arrItems: string[] = [];

        if (typeof defaultValue === "string") {
            arrItems.push(String(defaultValue));
        } else if (typeof defaultValue === "object") {
            arrItems = defaultValue as unknown as string[];
        }

        if (flowObj !== undefined) {

            let newItemSelect: ComboItem[] = [];

            await api.post(`/card/by-cards`, {
                card_items: arrItems,
                flow_id: flowObj.id_flow,
                flow_parent_id: flow_base_id,
                isWithWaitingAnswers: isFromAutomation !== undefined && isFromAutomation ? true : undefined
            }).then(response => {

                const retCards: Card[] = response.data;

                for (let index = 0; index < retCards.length; index++) {
                    const c = retCards[index];

                    const newComboItem: ComboItem = formAnswerToComboItemFromFlow(c, flowObj);

                    newItemSelect.push(newComboItem);
                }

            }).catch(error => {
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Erro ao carregar os cartões conectados ao campo!',
                    description: error.response.data.message,
                });
            });

            setItemSelected(newItemSelect);
            setLoading(false);

        }

    }, [addToast, defaultValue, flow_base_id, isFromAutomation]);

    const getApiRegister = useCallback(async (flowId: number, withAnswers: boolean) => {

        setLoadingForm(true);
        setLoading(true);

        const endpoint = isPublicForm ? `/form-public/flow/` : `/flow/`;

        try {

            const responseFlow = await api.get(endpoint, {
                params: {
                    id_flow: flowId,
                    withCards: withAnswers, //variation === "2" ? false : true
                    withAutoComplete: mode === 3 || mode === 4 || mode === 5 || mode === 6 ? true : false,
                    flow_parent_id: flow_base_id,
                    public_hash: isPublicForm && public_hash ? public_hash : undefined,
                    filterSchema: filter_schema,
                    removeStepsCondicionals: true
                }
            })

            if (responseFlow !== undefined && responseFlow.data !== null) {
                const flowApi: Flow = responseFlow.data;

                let itemsNew: ComboItem[] = [];

                //Set the items in the combo
                if (flowApi.flow_steps !== undefined) {
                    for (let index = 0; index < flowApi.flow_steps.length; index++) {
                        const steps = flowApi.flow_steps[index];

                        if (steps.cards !== undefined) {
                            for (let idxB = 0; idxB < steps.cards.length; idxB++) {

                                const newComboItem: ComboItem = formAnswerToComboItemFromFlow(steps.cards[idxB], flowApi);

                                itemsNew.push(newComboItem);

                            }
                        }
                    }

                    setItems(itemsNew);
                    setItemsFiltered(itemsNew);

                }

                if (defaultValue !== undefined) {
                    getApiCard(flowApi);
                } else {
                    setLoading(false);
                }

                setFlow(flowApi);

                if (flowApi.form_init !== undefined && flowApi.form_init.fields !== undefined && flowApi.form_init.fields !== null) {
                    setFields(flowApi.form_init?.fields);
                }
            }

        } catch (error) {
            console.log(error);
            addToast({
                type: 'error',
                title: 'Erro ao carregar o Fluxo [3]',
                description: 'Ocorreu um erro ao carregar o fluxo vinculado!',
            });
        }

        setLoadingForm(false);

    }, [addToast, defaultValue, getApiCard, flow_base_id, mode, isPublicForm, public_hash, filter_schema]);

    const getApiRegisterSelect = useCallback(async () => {
        setLoading(true);
        api.get(`/flow/my-flows`)
            .then(response => {
                if (response.data !== null) {

                    const flowApi: Flow[] = response.data;
                    let itemsNew: ComboItem[] = [];

                    for (let index = 0; index < flowApi.length; index++) {

                        const title: string = flowApi[index].name === undefined || flowApi[index].name === null ? "Rascunho" : flowApi[index].name + "";

                        const itemNew: ComboItem = {
                            id_register: flowApi[index].id_flow,
                            title: title,
                            hash: flowApi[index].hash,
                            icon: flowApi[index].icon === null ? "FaWrench" : flowApi[index].icon,
                            color: flowApi[index].color === null ? "#795548" : flowApi[index].color,
                            origin: "Meus fluxos",
                            details: [
                                {
                                    title: "Total de Cartões",
                                    Icon: getItemComponentIcon("TEXT_SHORT_FIELD"),
                                    value: flowApi[index].total_cards !== undefined ? flowApi[index].total_cards + "" : "0"
                                }
                            ]
                        }

                        itemsNew.push(itemNew);

                    }

                    //Remove the actually flow
                    if (params.hash !== undefined) {
                        itemsNew = itemsNew.filter((f) => f.hash !== params.hash);
                    }

                    //If have already a selected value
                    if (defaultValue !== undefined) {
                        const selectValFil = itemsNew.filter((item) => item.id_register === defaultValue);
                        if (selectValFil !== undefined && selectValFil.length > 0) {
                            setItemSelected(selectValFil);
                        }
                    }

                    setItems(itemsNew);
                    setItemsFiltered(itemsNew);

                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }, [defaultValue, params.hash]);

    const getAutoComplete = useCallback(async () => {

        if (!isPublicForm) {
            if (flow !== undefined && flow.form_init !== undefined && flow.form_init.fields !== undefined && flow.form_init.fields !== null) {
                setLoadingForm(true);

                let userCardCreator: number = user_creator_id !== undefined ? user_creator_id : user.id_user;

                const formAnswerFields = await getAutoCompleteRule('combo', flow.form_init.fields, user.id_user, userCardCreator, card_current_id);

                const newObjData: objectInit = formAnswerToObjectFormInit(formAnswerFields);
                setInitValue(newObjData);
                setLoadedAutoComplete(true);
                setLoadingForm(false);
            }
        } else {
            setLoadedAutoComplete(true);
        }

    }, [flow, card_current_id, user, isPublicForm, user_creator_id]);

    useEffect(() => {

        //Just for the first open form
        if (!loadedAutoComplete && openForm) {
            getAutoComplete();
        }

    }, [openForm, loadedAutoComplete, getAutoComplete]);

    useEffect(() => {

        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }

        if (!open) {
            setItemsFiltered(items);
        }

    }, [open, items]);

    useEffect(() => {

        if (!firstUpdate) {

            //Creatind a new field (New Field)
            if (flow_id === undefined) {
                getApiRegisterSelect();
            }

            //Get the register items
            if (flow_id !== undefined && Number(flow_id) > 0) {

                if (defaultValue !== undefined) {
                    if (mode === 1 || mode === 2 || mode === 5 || mode === 6) { //There is the possibility to select a register
                        getApiRegister(flow_id, true);
                    } else {
                        getApiRegister(flow_id, false);
                    }
                } else if (mode === 3 || mode === 4) {
                    getApiRegister(flow_id, false);
                } else {
                    getApiRegister(flow_id, true);
                }

            }

            setFirstUpdate(true);
        }

    }, [getApiRegisterSelect, getApiRegister, mode, flow_id, defaultValue, firstUpdate]);

    useEffect(() => {

        registerField({
            name: fieldName,
            ref: selectRef.current,
            getValue: (ref: any) => {
                if (loading && firstUpdate) {
                    return defaultValue;
                } else {
                    return itemSelected.map(({ id_register }) => id_register);
                }
            },
            clearValue: ref => {
                setItemSelected([]);
            },
        });

    }, [fieldName, registerField, itemSelected, defaultValue, loading, firstUpdate]);

    useEffect(() => {

        const tasksTot = itemSelected.length;
        let percent = 0;

        if (tasksTot > 0) { //Just if there is more than one tasks

            const tasksCompleted = itemSelected.filter((i) => i.complete === "S");

            if (tasksCompleted.length > 0) { //There are %
                percent = tasksCompleted.length / tasksTot;
            } else {
                percent = 0;
            }

            setPercentage(percent);

        }

    }, [itemSelected]);

    useEffect(() => {

        if (firstUpdate && !loading && flow_id !== undefined && flow_id !== null && !isNaN(Number(flow_id)) && Number(flow_id) > 0) {
            getApiRegister(Number(flow_id), false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstUpdate, flow_id, getApiRegister]);

    useEffect(() => {

        if (needBlur && onForceBlur !== undefined) {
            onForceBlur();
            setNeedBlur(false);
        }

    }, [needBlur, onForceBlur]);

    return (
        <>
            <Container isErrored={!!error}>

                {!firstUpdate || loading ?
                    <>
                        <LoaderContainer />
                        <LoaderContainer />
                        <LoaderContainer />
                    </> :
                    <>

                        {/* If has a card selected, show the register */}
                        {!loading && itemSelected !== undefined ?
                            <>
                                {itemSelected.length > 1 ?
                                    <ProgressBarTask>
                                        <ProgressBarTaskLine>
                                            <ProgressBarTaskLineInside width={percentage !== undefined ? percentage * 100 : 0} />
                                        </ProgressBarTaskLine>
                                        <ProgressBarTaskPercent>{String(Math.round((Number(percentage) * 100)))}%</ProgressBarTaskPercent>
                                    </ProgressBarTask> :
                                    <></>
                                }
                                {itemSelected.sort(compareItems).map((item) => {
                                    return (
                                        <ItemRegister key={item.id_register} type={'Flow'} item={item} handleRemove={() => handleRemove(item)} />
                                    )
                                })}
                            </> :
                            <></>
                        }

                        {/* If can choose a new register, show the button */}
                        {(((mode === 1 || mode === 5) && itemSelected.length < 1) || (mode === 2 || mode === 6)) && !openForm && !loading ?
                            <Box>

                                {defaultValue !== undefined && loading ?
                                    <>
                                        <LoaderContainer />
                                    </> :
                                    <ButtonAddRegister type="button" onClick={handleClick}>
                                        <ButtonAddLeft>
                                            <FaPlus />
                                        </ButtonAddLeft>
                                        <ButtonAddRight>
                                            {placeholder}
                                        </ButtonAddRight>
                                    </ButtonAddRegister>
                                }

                                {open ? (
                                    <ClickAwayListener onClickAway={handleClickAway}>
                                        <BoxContainer>

                                            <BoxSearchContainer>
                                                <SearchInput
                                                    placeholder="Buscar Registros"
                                                    defaultValue={searchValue}
                                                    onChange={handleFilter}
                                                    ref={searchInputRef}
                                                >

                                                </SearchInput>
                                                {mode === 5 || mode === 6 ?
                                                    <BoxAddNew onClick={() => {
                                                        setOpenForm(true)
                                                    }}>+ Adicionar novo</BoxAddNew> :
                                                    <></>
                                                }
                                            </BoxSearchContainer>

                                            <BoxItemsContainer>

                                                {loading ?
                                                    <>
                                                        <LoaderContainer />
                                                        <LoaderContainer />
                                                        <LoaderContainer />
                                                    </> :
                                                    <></>
                                                }

                                                {!loading && itemsFiltered !== undefined && itemsFiltered?.length > 0 ?
                                                    itemsFiltered?.map((item) => {
                                                        return (
                                                            <ItemRegisterContainer key={item.id_register + item.title} color={item?.color} onClick={() => handleSelect(item)}>
                                                                <ItemRegisterHead
                                                                    key={item.id_register}
                                                                    color={item?.color}
                                                                    selected={false}
                                                                >
                                                                    <ItemRegisterIcon color={item.color}>
                                                                        {item !== undefined && item.icon !== undefined ?
                                                                            <IconPickerItem
                                                                                icon={item.icon as IconList}
                                                                                color={item.color}
                                                                            /> :
                                                                            <IoMdListBox />
                                                                        }
                                                                    </ItemRegisterIcon>
                                                                    <ItemRegisterTitle>
                                                                        <h1>{item.title}</h1>
                                                                        <span>{item.origin}</span>
                                                                    </ItemRegisterTitle>
                                                                    <Link to={"/flow/" + item.hash} style={{ position: 'absolute', right: '10px', textDecoration: 'none' }} target="_blank">
                                                                        <FaExternalLinkAlt style={{ marginLeft: '10px', fontSize: '11px', color: item.color }} />
                                                                    </Link>
                                                                </ItemRegisterHead>
                                                                {item.details !== undefined && item.details?.length > 0 ?
                                                                    <ItemRegisterDetailContainer>
                                                                        {item.details?.map((detail) => {
                                                                            return (
                                                                                <ItemRegisterDetail key={detail.title}>
                                                                                    <ItemRegisterDetailHead>
                                                                                        {detail !== undefined && detail.Icon !== undefined ?
                                                                                            <detail.Icon /> :
                                                                                            <IoMdListBox />
                                                                                        }
                                                                                        <h3>{detail.title}</h3>
                                                                                    </ItemRegisterDetailHead>
                                                                                    <ItemRegisterDetailBody>{detail.value}</ItemRegisterDetailBody>
                                                                                </ItemRegisterDetail>
                                                                            )
                                                                        })}
                                                                    </ItemRegisterDetailContainer> :
                                                                    <></>}
                                                            </ItemRegisterContainer>
                                                        )
                                                    }) :
                                                    !loading && (
                                                        <NoResults>Sem Registros</NoResults>
                                                    )}
                                                {flow_id === undefined ?
                                                    <Link to={"/flow-list/"} style={{ textDecoration: 'none' }} target="_blank">
                                                        <ItemRegisterContainer color={"#f23b5c"}>
                                                            <ItemRegisterHead
                                                                color={"#f23b5c"}
                                                                selected={false}
                                                            >
                                                                <ItemRegisterIcon color={"#f23b5c"}>
                                                                    <TiFlowMerge />
                                                                </ItemRegisterIcon>
                                                                <ItemRegisterTitle>
                                                                    <h1>Crie um novo fluxo agora!</h1>
                                                                    <span>Clique aqui</span>
                                                                </ItemRegisterTitle>
                                                                <FaExternalLinkAlt style={{ marginLeft: '10px', fontSize: '11px', color: "#f23b5c" }} />
                                                            </ItemRegisterHead>
                                                        </ItemRegisterContainer>
                                                    </Link> :
                                                    <></>
                                                }
                                            </BoxItemsContainer>

                                        </BoxContainer>
                                    </ClickAwayListener>
                                ) : null}

                            </Box> :
                            <></>
                        }

                        {/* If can add a new register, show the form */}
                        {((mode === 3 && itemSelected.length < 1) ||
                            (mode === 4 && (openForm || itemSelected.length < 1)) ||
                            (mode === 5 && openForm && itemSelected.length < 1) ||
                            (mode === 6 && openForm)) &&
                            !loading ?

                            <>
                                {defaultValue !== undefined && loadingForm ?
                                    <LoaderContainer /> :
                                    <>
                                        {!openForm ?
                                            <ButtonAddRegister type="button" onClick={() => setOpenForm(true)}>
                                                <ButtonAddLeft>
                                                    <FaPlus />
                                                </ButtonAddLeft>
                                                <ButtonAddRight>
                                                    {placeholder}
                                                </ButtonAddRight>
                                            </ButtonAddRegister> :
                                            <>
                                                <FormItemsContainer color={flow?.color}>
                                                    <FormItemsHeader color={flow?.color}>

                                                        <ItemRegisterIcon color={flow?.color}>
                                                            {flow !== undefined && flow.icon !== undefined ?
                                                                <IconPickerItem
                                                                    icon={flow.icon as IconList}
                                                                    color={flow.color}
                                                                /> :
                                                                <IoMdListBox />
                                                            }
                                                        </ItemRegisterIcon>
                                                        <ItemRegisterTitle style={{ width: '100%' }}>
                                                            <h1>{flow?.name}</h1>
                                                            <span>Adicionando novo cartão</span>
                                                        </ItemRegisterTitle>

                                                        {max_length === 2 ?
                                                            <FormItemsCounter color={flow?.color}>
                                                                Item {itemSelected.length + 1}
                                                            </FormItemsCounter> :
                                                            <></>}
                                                    </FormItemsHeader>
                                                    <FormItemsForm>
                                                        {!loadingForm ?
                                                            flow !== undefined && flow.form_init !== undefined && fields !== undefined && loadedAutoComplete ?
                                                                <FormBuilder
                                                                    id="registerComboRegister"
                                                                    hideContainer={true}
                                                                    formRef={formRef}
                                                                    fields={fields}
                                                                    flow_id={flow.id_flow}
                                                                    flow_parent_id={flow_base_id}
                                                                    handleSubmit={handleSubmit}
                                                                    initialValue={initValue}
                                                                    typeUser={flow?.typeUserAccess}
                                                                    isPublicForm={isPublicForm}
                                                                    public_hash={public_hash}
                                                                    activeHiddenFields={true}
                                                                    setFields={setFields}
                                                                /> :
                                                                <></> :
                                                            <>
                                                                <LoaderContainer />
                                                                <LoaderContainer />
                                                                <LoaderContainer />
                                                            </>
                                                        }
                                                    </FormItemsForm>
                                                </FormItemsContainer>
                                                <div style={{ height: '56px' }}>
                                                    {max_length === 2 ?
                                                        <ButtonNew type="button" onClick={handleAddNewItem} color={flow?.color} isLoading={loadingAdding}>
                                                            {flow !== undefined && flow.button_add_name !== undefined ?
                                                                "Adicionar " + flow.button_add_name :
                                                                "Adicionar novo item"
                                                            }
                                                        </ButtonNew> :
                                                        <ButtonNew type="button" onClick={handleAddNewItem} color={flow?.color} isLoading={loadingAdding}>
                                                            {flow !== undefined && flow.button_add_name !== undefined ?
                                                                "Criar " + flow.button_add_name :
                                                                "Criar novo item"
                                                            }
                                                        </ButtonNew>
                                                    }
                                                    {openForm ?
                                                        <ButtonBack type="button" onClick={() => setOpenForm(false)} isLoading={loadingAdding}>Fechar</ButtonBack> :
                                                        <></>
                                                    }
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                            </> :
                            max_length === 2 && (mode === 3 || mode === 4) ?
                                <div style={{ height: '25px' }}>
                                    <ButtonNew type="button" onClick={() => setOpenForm(true)} style={{ marginTop: "0px" }} color={flow?.color} isLoading={loadingAdding}>
                                        {flow !== undefined && flow.button_add_name !== undefined ?
                                            "Adicionar " + flow.button_add_name :
                                            "Adicionar novo item"
                                        }
                                    </ButtonNew>
                                </div> :
                                <></>
                        }

                    </>
                }
            </Container>
            {error && (<ErrorDescription title={error} />)}
        </>
    );

}

export default ComboBoxFlow;