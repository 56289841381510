import styled, { css } from 'styled-components';

import ToolTip from "../../../../ToolTip";
import { Box } from '@material-ui/core';

interface ContainerProps {
    isFocused: boolean;
    isErrored: boolean;
    isDisabled?: boolean;
}

interface OptionItemIconProps {
    color: string;
}

interface OptionItemProps {
    color: string;
}

export const Container = styled.div<ContainerProps>`

    background-color: transparent;    
    width: 100%;

    color: black;
    border: 1px solid #e0dfdf;
    border-radius: 5px;
    padding: 10px;

    display: flex;
    align-items: center;

    & + div {
        margin-top: 30px;
    }

    input {
        flex: 1;
        background: transparent;
        border: 0;
        font-size: 14px;
            
        ::placeholder {
            color: gray;
        }

        
    }

    svg {
        margin-right: 16px;
        color: gray;
    }

    ${props => props.isErrored && css`
        //color: red;        
        border-color: red;

        /*
        svg {
            color: red;
        }
        */
    `}
  

    ${props => props.isFocused && css`
        color: #f23b5c;
        border-color: #f23b5c;

        svg {
            color: #f23b5c;
        }
    `}

    ${props => props.isDisabled && css`     
        background-color: rgba(188, 188, 188, 0.1);
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    `}  

    title {
        color: red;
        text-size-adjust: 10px;
    }
`;

export const ButtonDynamicItem = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0px !important;
    background-color: #f23b5d;
    border: none;
    color: white;
    padding: 3px;
    border-radius: 5px; 

    svg {
        margin: 0;
        font-size: 14px;
        color: white;
    }
`;

export const Error = styled(ToolTip)`
    height: 20px;
    margin-left: 16px;

    svg {
        margin: 0;
    }

    span {
        background: red;

        &::before {
            border-color: red transparent;
        }
    }
`;

export const BoxContainer = styled(Box)`
    position: absolute;
    z-index: 9;
    right: 0px;
    top: 25px;
    min-width: 350px;
    max-width: 100%;
    width: 100%;
    max-height: 300px;
    overflow: auto;
    border-radius: 5px;
    padding: 5px;
    margin-top: 15px;
    padding-bottom: 5px;
    padding-top: 10px;
    background-color: white;
    box-shadow: 0 0 6px rgb(0 0 0 / 20%);
    margin-top: 0px !important;

    @media(max-width: 800px){
        margin-right: 0px;
    }
`;

export const OptionTitle = styled.h3`
    color: gray;
    font-size: 12px;
    text-align: left;
    font-weight: 500;
    user-select: none;
    margin-bottom: 10px;
    margin-left: 5px;
`;

export const OptionItem = styled.div<OptionItemProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px;
    margin-bottom: 10px;
    margin-left: 15px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;

    :hover {
        background-color: #f23b5d1e;

        ${props => props.color && css`
            background-color: ${props.color}1e;
        `}
    }
`;

export const OptionItemIcon = styled.div<OptionItemIconProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    min-height: 40px;
    background-color: #f23b5d;
    color: white;
    border-radius: 5px;
    font-size: 13px;

    div {
        display: flex;
        font-size: 16px !important;

        svg {
            margin-right: 0px !important;
        }
    }

    ${props => props.color && css`
        background-color: ${props.color + "1e"};

        svg {
            color: ${props.color};
            margin-right: 0px !important;
        }
    `}    
`;

export const OptionItemDescription = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h3 {
        font-size: 12px;
        color: black;
        text-align: left;
        margin-bottom: 3px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            background-color: #00bcd4;
            color: white;
            border-radius: 10px;
            margin-left: 5px;
            padding: 3px 7px;
            font-size: 10px;            
        }
    }

    span {
        font-size: 11px;
        color: grey;
        text-align: left;
        white-space: nowrap;
        overflow: hidden; 
        text-overflow: ellipsis;
        max-width: 230px;
    }
`;

export const ContainerRight = styled.div`

`;

export const ContainerLeft = styled.div`
    width: calc(100% - 20px);

    input {
        width: 100%;
    }
`;

export const ItemsSelected = styled.div`    
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;    
    flex-direction: column;
    gap: 5px;
`;

export const OptionItemSelected = styled.div`
    display: flex;
    background-color: #f23b5d;
    width: auto;
    padding: 3px 10px;
    border-radius: 5px;
    margin-right: 10px;
    user-select: none;
`;

export const OptionItemSelectedTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 13px;
    font-weight: 400;
`;

export const OptionItemSelectedBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;

    svg {
        color: white;
        margin-left: 7px;
        margin-right: 0px;
        font-size: 12px;
    }
`;

export const OptionItemSelectedIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 5px;
    font-size: 13px;

    div {
        display: flex;
        font-size: 13px !important;
        padding: 0px;
    }


    svg {
        color: white !important;
        margin-right: 7px;
    }
`;

export const ContainerRichText = styled.div<ContainerProps>`

    margin-top: 5px;

    .ql-editor {
        padding: 10px;


        .field-dynamic-tag {
            position: relative;
            background-color: #f23b5d;
            border-radius: 5px;
            color: white;
            text-decoration: auto;
            font-size: 12px;
            font-weight: 50;
            padding: 3px 5px;
            user-select: all;
        }

        .field-dynamic-tag:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        .field-dynamic-tag:after {
            //content: attr(field-title);
        }
    }

    .ql-toolbar.ql-snow {
        border-radius: 5px;
        margin-bottom: 10px;
    }

    .ql-container.ql-snow {
        border: 1px solid #e0dfdf !important;
        border-radius: 5px;
    }

    .ql-editor.ql-blank::before {
        color: gray;
        left: 10px;
        font-style: normal;
        font-size: 14px;
    }

    .ql-toolbar.ql-snow {
        border: 1px solid #e0dfdf;
    }

    ${props => props.isErrored && css`           
        .ql-container.ql-snow {
            border-color: red !important;
        }
    `}
  

    ${props => props.isFocused && css`
        .ql-container.ql-snow {        
            border-color: #f23b5c !important;
        }
    `} 
`;

export const OptionItemSelectedPlaceHolder = styled.div`
    color: gray;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
`;

export const ContainerDynamic = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border: 1px solid #e0dfdf;
    border-radius: 5px;
    margin-bottom: 10px;
`;

export const BtnAddDynamicField = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0px !important;
    background-color: #f23b5d;
    border: none;
    color: white;
    padding: 3px 10px;
    border-radius: 5px;
    font-size: 13px;

    svg {
        margin-right: 5px;
    }
`;

export const BtnExpandDialog = styled.button`
    position: absolute;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px !important;
    border: none;
    color: white;
    padding: 3px 12px !important;
    border-radius: 5px;
    font-size: 13px;
    background-color: transparent;
    color: gray;
    border: 1px solid gray;
`;

export const CopyBtn = styled.button`    
    font-weight: 500;
    padding: 3px 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: gray;
    border: none;
    background-color: #f4f5f7;
    border-radius: 10px;

    svg {
        margin-right: 5px;
        color: gray;
    }

    :hover {
        background-color: #9e37ed;
        color: white;

        svg {
            color: white;
        }
    }
`;