import React, { useCallback, useEffect, useRef } from "react";

import {
    Content,
    ContentBody,
    DialogContainer,
    DialogTop,
    DialogTopLeft,
    DialogTopCenter,
    DialogTopRight,
    DialogFooter,
    DialogFooterCenter,
    BtnInsertNewAnswer
} from "./style";

import { AiOutlineClose } from "react-icons/ai";
import { DatePickerRecurProps } from "../../components/Forms/Fields/Components/DatePickerRecur";
import { Card as CardInterface } from "../../interfaces/Card";
import { FieldProps, Fields } from "../../components/Forms/Fields/FieldBuilder";
import { FormHandles } from "@unform/core";
import FormBuilder from "../../components/Forms/FormBuilder";
import api from "../../services/api";
import { format } from "date-fns";
import { useToast } from "../../hooks/toast";

const fieldsNewLabel: FieldProps[] = [
    {
        name: "date_due_recur",
        type: Fields.DATE_PICKER_RECUR_FIELD,
        index: 1,
        title: "",
        description: "",
        validation_type: "object",
        validations: [
            {
                type: "required",
                params: ["É necessário escolher uma data!"]
            }
        ]
    },
]

interface CardDatePickerProps {
    open: boolean;
    selectedValue: CardInterface | undefined;
    onClose: () => void;
}

interface FormDatePickerProps {
    date_due_recur: DatePickerRecurProps;
}

const dateRecurDefault: FormDatePickerProps = {
    date_due_recur: {
        dt_due: new Date(new Date().setHours(0, 0, 0, 0))
    }
}

const CardDatePicker: React.FC<CardDatePickerProps> = ({ onClose, open, selectedValue }) => {

    const formRef = useRef<FormHandles>(null);
    const [dateRecur, setDateRecur] = React.useState<FormDatePickerProps>();
    const [runLoad, setRunLoad] = React.useState<boolean>(false);
    const { addToast } = useToast();

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    const handleSubmitForm = useCallback(async (data: object[]) => {

        if (formRef.current !== null && selectedValue !== undefined) {

            const dataNormalized = formRef.current.getData().date_due_recur as unknown as DatePickerRecurProps;

            //Formata a data            
            if (dataNormalized !== undefined && dataNormalized !== null) {

                let dateDueFormated;
                if (dataNormalized.dt_due !== undefined && dataNormalized.dt_due !== null) {
                    const dateDueType = new Date(dataNormalized.dt_due);
                    dateDueFormated = format(
                        dateDueType,
                        "yyyy-MM-dd HH:mm"
                    );
                }

                let dateStartFormated;
                if (dataNormalized.dt_start !== undefined && dataNormalized.dt_start !== null) {
                    const dateStartType = new Date(dataNormalized.dt_start);
                    dateStartFormated = format(
                        dateStartType,
                        "yyyy-MM-dd HH:mm"
                    );
                }

                let dateNextRecurrenceFormated;
                if (dataNormalized.next_occurrence !== undefined && dataNormalized.next_occurrence !== null) {
                    const dateNextRecurrenceType = new Date(dataNormalized.next_occurrence);
                    dateNextRecurrenceFormated = format(
                        dateNextRecurrenceType,
                        "yyyy-MM-dd HH:mm"
                    );
                }

                await api
                    .put('/card', {
                        flow_id: selectedValue?.flow_id,
                        id_card: selectedValue.id_card,
                        dt_due: dateDueFormated !== undefined ? dateDueFormated : null,
                        dt_start: dateStartFormated !== undefined ? dateStartFormated : null,
                        recurrence: dataNormalized.recurrence !== undefined ? dataNormalized.recurrence : null,
                        on_recurrence: dataNormalized.on_recurrence !== undefined ? dataNormalized.on_recurrence : null,
                        next_recurrence: dateNextRecurrenceFormated,
                        behavior: dataNormalized.behavior !== undefined ? dataNormalized.behavior : null,
                    })
                    .then(response => {

                        onClose();

                    }).catch(error => {
                        addToast({
                            type: 'error',
                            title: 'Erro ao salvar informações da data',
                            description: 'Ocorreu um erro ao inserir as informações na base de dados!',
                        });
                    });

            }
        }

    }, [onClose, selectedValue, addToast]);

    useEffect(() => {

        if (selectedValue !== undefined) {

            const newObject: DatePickerRecurProps = {
                dt_due: selectedValue.dt_due !== undefined && selectedValue.dt_due !== null ? new Date(selectedValue.dt_due) : undefined,
                dt_start: selectedValue.dt_start !== undefined && selectedValue.dt_start !== null ? new Date(selectedValue.dt_start) : undefined,
                recurrence: selectedValue.recurrence !== undefined && selectedValue.recurrence !== null ? selectedValue.recurrence : undefined,
                next_occurrence: selectedValue.next_recurrence !== undefined && selectedValue.next_recurrence !== null ? new Date(selectedValue.next_recurrence) : undefined,
                on_recurrence: selectedValue.on_recurrence !== undefined && selectedValue.on_recurrence !== null ? selectedValue.on_recurrence : undefined,
                behavior: selectedValue.behavior !== undefined && selectedValue.behavior !== null ? selectedValue.behavior : undefined,
            }

            const newObjectData = {
                date_due_recur: newObject
            }

            setDateRecur(newObjectData);

            if (formRef?.current !== null) {
                formRef?.current.setData(newObjectData);
            }

        } else {
            setDateRecur(dateRecurDefault);
        }

        setRunLoad(true);

    }, [selectedValue, open]);

    return (
        <DialogContainer
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
        >
            <DialogTop>
                <DialogTopLeft>
                    <h1>
                        {"Adicionar uma data ao cartão"}
                    </h1>
                </DialogTopLeft>

                <DialogTopCenter />

                <DialogTopRight>
                    <button onClick={handleClose}><AiOutlineClose /></button>
                </DialogTopRight>
            </DialogTop>
            <Content>
                <ContentBody container>

                    {runLoad && (
                        <FormBuilder
                            id="formAddLabel"
                            formRef={formRef}
                            fields={fieldsNewLabel}
                            initialValue={dateRecur}
                            handleSubmit={handleSubmitForm}
                            hideContainer={true}
                        />
                    )}

                </ContentBody>
            </Content>
            <DialogFooter>
                <DialogFooterCenter>
                    <BtnInsertNewAnswer color={"#9337ed"} style={{ width: '130px', height: '36px' }} onClick={() => formRef.current?.submitForm()}>
                        Salvar
                    </BtnInsertNewAnswer>
                </DialogFooterCenter>
            </DialogFooter>
        </DialogContainer >
    );

}

export default CardDatePicker;