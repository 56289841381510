import styled, { css } from "styled-components";

interface RightPanelProps {
    isOpen: boolean;
}

interface FlowConfigItemIconProps {
    color: string;
}

interface FlowConfigItemProps {
    color: string;
}

export const RightPanelContainer = styled.div<RightPanelProps>`
    //position: fixed;
    position: absolute;
    z-index: 9999;
    top: 0;
    height: 100%;
    width: 360px;
    right: ${props => (props.isOpen ? "0px" : "-350px")};
    display: ${props => (props.isOpen ? "block" : "none")};;
    background-color: white;
    transition: right 0.4s cubic-bezier(0, 0, 0, 1);
    overflow-x: hidden;
    overflow-y: overlay;
    text-align: left;
    box-shadow: 0 0 1px rgba(0,0,0,0.24), 0 0 2px rgba(0,0,0,0.16), 0 3px 4px rgba(0,0,0,0.06), 0 6px 8px rgba(0,0,0,0.06), 0 12px 16px rgba(0,0,0,0.08), 0 18px 32px rgba(0,0,0,0.06);
    border-radius: 10px 0px 0px 10px;
`;

export const TopContainer = styled.div`
    display: flex;
    height: 0px;
    //border-bottom-color: #8181815e;
    //border-bottom-style: solid;
    //border-bottom-width: 1px;
`;

export const TitleContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    

    h1 {
        font-size: 18px;
        font-weight: 500;
        color: black;
    }
`;

export const CloseContainer = styled.div`
    position: absolute;
    right: 5px;
    top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        margin: 15px;
        margin-left: 20px;
        width: 30px;
        border: none;
        background-color: transparent;
        z-index: 99999;
        

        svg {
            font-size: 15px;
        }
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    height: 66px;
    width: 100%;
    position: absolute;
    bottom: 0;
    border-top-color: #8181815e;
    border-top-style: solid;
    border-top-width: 1px;
`;

export const BottomItems = styled.div`
    right: 0;
    margin-right: 15px;
    margin-left: 15px;
    width: 100%;
`;

export const FlowConfigGroup = styled.div``;

export const FlowConfigTitle = styled.div`
    font-size: 15px;
    color: #4d4d4d;
    margin-bottom: 2px;
    font-weight: 500;
    padding-bottom: 5px;
    border-bottom: 2px solid #f4f5f7;
    margin-bottom: 15px;
`;

export const FlowConfigItems = styled.div`
    
`;

export const FlowConfigItem = styled.div<FlowConfigItemProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;

    :hover {
        background-color: #f23b5d1e;

        ${props => props.color && css`
            background-color: ${props.color}1e;
        `}
    }
`;

export const FlowConfigItemIcon = styled.div<FlowConfigItemIconProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 45px;
    min-height: 45px;
    background-color: #f23b5d;
    color: white;
    border-radius: 5px;
    font-size: 18px;

    ${props => props.color && css`
        background-color: ${props.color};
    `}
`;
export const FlowConfigItemDescription = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h3 {
        font-size: 14px;
        color: black;
        margin-bottom: 3px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            background-color: #f23b5c;
            color: white;
            border-radius: 10px;
            margin-left: 5px;
            padding: 3px 7px;
            font-size: 10px;
        }
    }

    span {
        font-size: 12px;
        color: grey;
    }
`;