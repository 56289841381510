import {
    ButtonEmptyState,
    Content,
    ContentBody,
    DialogContainer,
    DialogFooter,
    DialogFooterLeft,
    DialogFooterRight,
    DialogTop,
    DialogTopLeft,
    DialogTopRight,
    IconHeader,
    SpanField,
    HelpContainer,
    HelpItems,
    HelpItem,
    HelpIcon,
    HelpText,
    SubHeaderLeft,
    ButtonGroupLeft,
    ButtonGroupRight,
    DynamicFieldContainer,
    LabelInput,
    SelectInput,
    OptionItem,
    DynamicFieldForm,
    TitleResume,
    DialogResume,
    ResumeDescription,
    MappingFlow,
    MappingFlowTitle,
    MappingFlowItemOrigin,
    MappingFlowIcon,
    MappingFlowItemTarget,
    ContainerAutoComplete
} from "./style";
import React, { useCallback, useState, useRef, useEffect } from "react";
import { FormHandles } from "@unform/core";
import { AiOutlineClose } from "react-icons/ai";
import { BsLightningChargeFill } from "react-icons/bs";
import WelcomePageFieldAutomation from "./WelcomePageFieldAutomation";
import { FieldProps } from "../../components/Forms/Fields/FieldBuilder";
import FormBuilder from "../../components/Forms/FormBuilder";
import { FormAnswerFieldApi } from "../../interfaces/FormAnswerFieldApi";
import { useToast } from '../../hooks/toast';
import api from "../../services/api";
import { FormAnswer } from "../../interfaces/FormAnswer";
import { RiLightbulbFlashFill } from "react-icons/ri";
import formAnswerToObjectFormInit from "../../utils/formAnswerToObjectFormInit";
import { Flow } from "../../interfaces/Flow";
import { BiRightArrowAlt } from "react-icons/bi";
import { FieldMapAc } from "../../interfaces/FieldMapAc";
import { Register } from "../../interfaces/Register";
import { TiArrowLeftThick } from "react-icons/ti";
import AddConditionalField from "./AddConditionalField";
import { Conditional } from "../../interfaces/Conditional";
import ConditionalField from "./ConditionalField";

interface objectInit {
    [x: string]: string | object
}

interface AutomationFieldProps {
    open: boolean;
    field: FieldProps;
    flow_id?: number;
    register_id?: number;
    flow_parent_id?: number; //If there is a parent sync
    register_parent_id?: number; //If there is a parent sync
    isNewAnswer?: boolean;
    hideAutoComplete?: boolean;
    onClose: (open: boolean) => void;
}

interface AutoCompleteDynamicFieldProps {
    ac_parent_field_id: number,
    ac_child_field_id?: number,
}
interface AutoCompleteDynamicFieldTitleProps {
    ac_parent_field_title?: string,
    ac_child_field_title?: string
}

const AutomationField: React.FC<AutomationFieldProps> = ({ open, field, flow_id, register_id, flow_parent_id, register_parent_id, isNewAnswer, hideAutoComplete, onClose }) => {

    const formRef = useRef<FormHandles>(null);
    const { addToast } = useToast();

    const [dynamicFields, setDynamicFields] = useState<FieldProps[]>([]);
    const [typeAutomation, setTypeAutomation] = useState<number | undefined>(); // 1 - AutoComplete / 2 - Conditional / 3 - Conditional List
    const [completeType, setCompleteType] = useState<number>(0); // 0 - Dynamic Field / 1 - Static Field
    const [dynamicFieldSelected, setDynamicFieldSelected] = useState<AutoCompleteDynamicFieldProps>();
    const [dynamicFieldTitleSelected, setDynamicFieldTitleSelected] = useState<AutoCompleteDynamicFieldTitleProps>();
    const [loading, setLoading] = useState<boolean>(false);
    const [initValue, setInitValue] = useState({});
    const [fieldSelected, setFieldSelected] = useState<number>(0);
    const [isMapping] = useState<boolean>(flow_parent_id !== undefined || register_parent_id !== undefined);
    const [flow, setFlow] = useState<Flow>();
    const [register, setRegister] = useState<Register>();
    const [flowParent, setFlowParent] = useState<Flow>();
    const [registerParent, setRegisterParent] = useState<Register>();
    const [isActive, setIsActive] = useState<boolean>(false);

    const [conditionals, setConditionals] = useState<Conditional[]>([]);
    const [selectedConditional, setSelectedConditional] = useState<Conditional>();

    const getConditionalObject = useCallback(async () => {

        if (field.id_field !== undefined && flow_id !== undefined) {

            api.get(`/conditional/by-field`, {
                params: {
                    field_id: field.id_field,
                    flow_id: flow_id,
                }
            }).then(response => {
                if (response.data !== null) {
                    let retConditionals: Conditional[] = response.data;
                    setConditionals(retConditionals);
                }
            }).catch(error => {
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Não foi possível carregar as regras condicionais',
                    description: 'Ocorreu um erro ao carregar as regras condicionais!',
                });
            });

        }

    }, [addToast, field.id_field, flow_id]);

    const getFieldObject = useCallback(async () => {

        if (field.id_field !== undefined) {

            await api.get(`/field`, {
                params: {
                    id_field: field.id_field,
                    flow_id: flow_id,
                    register_id: register_id,
                    flow_map_id: flow_parent_id !== undefined ? flow_parent_id : undefined,
                    register_map_id: register_parent_id !== undefined ? register_parent_id : undefined
                }
            }).then(response => {
                if (response.data !== null) {

                    let retField: FieldProps = response.data;
                    let retFormAnswer: FormAnswer;

                    if (!isMapping) {
                        setIsActive(retField.ac_type !== undefined && retField.ac_type !== null);
                        setCompleteType(retField.ac_type !== undefined && retField.ac_type !== null ? retField.ac_type : 0);
                        setFieldSelected(
                            retField.ac_child_field_id !== undefined && retField.ac_child_field_id !== null ?
                                retField.ac_child_field_id :
                                retField.ac_parent_field_id !== undefined && retField.ac_parent_field_id !== null ?
                                    retField.ac_parent_field_id : 0
                        );

                        //Adjust the type Dynamic
                        if (retField.ac_type === 0 && retField.ac_parent_field_id !== undefined && retField.ac_parent_field_id !== null) {
                            setDynamicFieldSelected({
                                ac_parent_field_id: retField.ac_parent_field_id,
                                ac_child_field_id: retField.ac_child_field_id
                            });
                        }

                        //Adjust the type static
                        if (retField.ac_type === 1 && retField.auto_complete !== undefined) {
                            retFormAnswer = retField.auto_complete;

                            if (retFormAnswer.form_answer_fields !== undefined) {
                                const newObjData: objectInit = formAnswerToObjectFormInit(retFormAnswer.form_answer_fields);

                                setInitValue(newObjData);
                            }
                        }
                    } else {
                        if (retField.map_ac !== undefined && retField.map_ac.length > 0) {

                            const mapAc: FieldMapAc = retField.map_ac[0];

                            setIsActive(mapAc.ac_type !== undefined && mapAc.ac_type !== null);

                            setCompleteType(mapAc.ac_type !== undefined && mapAc.ac_type !== null ? mapAc.ac_type : 0);
                            setFieldSelected(
                                mapAc.ac_child_field_id !== undefined && mapAc.ac_child_field_id !== null ?
                                    mapAc.ac_child_field_id :
                                    mapAc.ac_parent_field_id !== undefined && mapAc.ac_parent_field_id !== null ?
                                        mapAc.ac_parent_field_id : 0
                            );

                            //Adjust the type Dynamic
                            if (mapAc.ac_type === 0 && mapAc.ac_parent_field_id !== undefined && mapAc.ac_parent_field_id !== null) {
                                setDynamicFieldSelected({
                                    ac_parent_field_id: mapAc.ac_parent_field_id,
                                    ac_child_field_id: mapAc.ac_child_field_id
                                });
                            }

                            //Adjust the type static
                            if (mapAc.ac_type === 1 && mapAc.auto_complete !== undefined) {
                                retFormAnswer = mapAc.auto_complete;

                                if (retFormAnswer.form_answer_fields !== undefined) {
                                    const newObjData: objectInit = formAnswerToObjectFormInit(retFormAnswer.form_answer_fields);

                                    setInitValue(newObjData);
                                }
                            }
                        }
                    }

                }
            }).catch(error => {
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Não foi possível carregar a regra de auto completar',
                    description: 'Ocorreu um erro ao carregar o registro!',
                });
            });

        }

    }, [addToast, field.id_field, flow_id, register_id, isMapping, flow_parent_id, register_parent_id]);

    const getRegisterObject = useCallback(async (register_id?: number, register_parent_id?: number) => {

        //Get the main Register
        if (register_id !== undefined) {
            api.get(`/register/`, {
                params: {
                    id_register: register_id,
                }
            }).then(response => {
                if (response.data !== null) {
                    let retRegister: Register = response.data;

                    setRegister(retRegister);
                }
            }).catch(error => {
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Não foi possível carregar o cadastro',
                    description: 'Ocorreu um erro ao carregar o cadastro!',
                });
            });
        }

        //Get the parent Register
        if (register_parent_id !== undefined) {
            api.get(`/register/`, {
                params: {
                    id_register: register_parent_id,
                }
            }).then(response => {
                if (response.data !== null) {
                    let retRegister: Register = response.data;

                    setRegisterParent(retRegister);
                }
            }).catch(error => {
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Não foi possível carregar o cadastro',
                    description: 'Ocorreu um erro ao carregar o cadastro!',
                });
            });
        }

    }, [addToast]);

    const getFlowObject = useCallback(async (flow_id?: number, flow_parent_id?: number) => {

        //Get the main flow
        if (flow_id !== undefined) {
            api.get(`/flow/`, {
                params: {
                    id_flow: flow_id,
                    removeStepsCondicionals: true
                }
            }).then(response => {
                if (response.data !== null) {
                    let retFlow: Flow = response.data;

                    setFlow(retFlow);
                }
            }).catch(error => {
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Não foi possível carregar o fluxo',
                    description: 'Ocorreu um erro ao carregar o registro!',
                });
            });
        }

        //Get the parent flow
        if (flow_parent_id !== undefined) {
            api.get(`/flow/`, {
                params: {
                    id_flow: flow_parent_id,
                    removeStepsCondicionals: true
                }
            }).then(response => {
                if (response.data !== null) {
                    let retFlow: Flow = response.data;

                    setFlowParent(retFlow);
                }
            }).catch(error => {
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Não foi possível carregar o fluxo',
                    description: 'Ocorreu um erro ao carregar o registro!',
                });
            });
        }

    }, [addToast]);

    function sortDynamicFields(fields: FieldProps[]): FieldProps[] {
        return fields.sort((a, b) => {
            if (a.form_id !== undefined && b.form_id !== undefined) {
                if (a.form_id < b.form_id) {
                    return -1;
                }
                if (a.form_id > b.form_id) {
                    return 1;
                }
            }

            if (a.index < b.index) {
                return -1;
            }
            if (a.index > b.index) {
                return 1;
            }

            return 0;
        });
    }

    const getDynamicFields = useCallback(async () => {

        if (field !== undefined) {

            //Type 
            // 1 - When is inside of a flow (just flow id)
            // 2 - When is inside of a register (just register id)
            // 3 - When is mapping from a flow (just flow parent id)
            // 4 - When is mapping from a register (just register parent id)
            let typeDynamicFields;
            if (isMapping) {
                if (flow_parent_id !== undefined) {
                    typeDynamicFields = 3;
                } else if (register_parent_id !== undefined) {
                    typeDynamicFields = 4;
                }
            } else {
                if (flow_id !== undefined) {
                    typeDynamicFields = 1;
                } else if (register_id !== undefined) {
                    typeDynamicFields = 2;
                }
            }

            if (typeDynamicFields === 1 || typeDynamicFields === 3) {
                api.get(`/field/by-flow`, {
                    params: {
                        flow_id: typeDynamicFields === 1 ? flow_id : flow_parent_id,
                        withChildren: 'true'
                    }
                }).then(response => {
                    if (response.data !== null) {

                        let retFields: FieldProps[] = response.data;

                        if (retFields !== undefined) {

                            //Filter the same type of field
                            retFields = retFields.filter((fieldItem) => {

                                if (fieldItem.id_field !== field.id_field && fieldItem.flow_id !== flow_id && fieldItem.register_id !== register_id) {

                                    //Remove if is from the same form_id
                                    if (fieldItem.form_id === field.form_id) {
                                        return false;
                                    }

                                    //Remove if is from the newAnswer 
                                    if (isNewAnswer) {
                                        return false;
                                    }

                                    if (field.type === 'COMBO_BOX_REGISTER_FIELD' || field.type === 'COMBO_BOX_FLOW_FIELD') {

                                        if (fieldItem.flow_id !== null && field.flow_id !== null && fieldItem.flow_id === field.flow_id) {
                                            return true;
                                        } else if (fieldItem.register_id !== null && field.register_id !== null && fieldItem.register_id === field.register_id) {
                                            return true;
                                        } else if (fieldItem.register_id !== null && field.register_id !== null && fieldItem.field_children !== undefined) {
                                            const fieldChildrenType = fieldItem.field_children.filter((fc) => fc.type === field.type);
                                            return fieldChildrenType !== undefined && fieldChildrenType.length > 0;
                                        } else {
                                            return false;
                                        }

                                    } else {

                                        if (fieldItem.type === 'COMBO_BOX_REGISTER_FIELD' || fieldItem.type === 'COMBO_BOX_FLOW_FIELD') {

                                            //Valid if has a field children with the same type of the field
                                            const fieldChildrenType = fieldItem.field_children?.filter((fc) => fc.type === field.type);

                                            return fieldChildrenType !== undefined && fieldChildrenType.length > 0;

                                        } else {

                                            if ((fieldItem.type === 'DATE_PICKER_FIELD' || fieldItem.type === 'DUE_DATE_FIELD') &&
                                                (field.type === 'DATE_PICKER_FIELD' || field.type === 'DUE_DATE_FIELD')) { //Because the datepicker and due date are the same type

                                                return true;

                                            } else if (fieldItem.type === 'FORMULA_FIELD' && (field.type === 'NUMBER_FIELD' || field.type === 'CURRENCY_FIELD')) { //Because the formula can be a NUMBER_FIELD or CURRENCY_FIELD
            
                                                return true;

                                            } else if (fieldItem.options !== undefined && fieldItem.options.length > 0) {

                                                return fieldItem.type === field.type;

                                            } else {

                                                return fieldItem.type === field.type;

                                            }

                                        }

                                    }

                                } else {
                                    return false;
                                }

                            });

                            //Filter the same type of field inside the children
                            retFields = retFields.map((fieldItem) => {

                                if (fieldItem.type === 'COMBO_BOX_REGISTER_FIELD') {
                                    const fieldChildrenType = fieldItem.field_children?.filter((fc) => fc.type === field.type && fc.register_id === field.register_id);
                                    fieldItem.field_children = fieldChildrenType;

                                } else if (fieldItem.type === 'COMBO_BOX_FLOW_FIELD') {
                                    const fieldChildrenType = fieldItem.field_children?.filter((fc) => fc.type === field.type && fc.flow_id === field.flow_id);
                                    fieldItem.field_children = fieldChildrenType;

                                }

                                return fieldItem;
                            })

                            //If no have fields, set the complete type to static, just if is empty
                            if (retFields.length === 0 && (field.ac_type === undefined || field.ac_type === null)) {
                                setCompleteType(1)
                            }

                            retFields = sortDynamicFields(retFields);
                            setDynamicFields(retFields);

                        } else {
                            setDynamicFields([]);
                        }

                    }
                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Não foi possível carregar os campos dinâmicos disponíveis',
                        description: 'Ocorreu um erro ao carregar os campos dinâmicos disponíveis!',
                    });
                });

            } else if (typeDynamicFields === 2 || typeDynamicFields === 4) {
                api.get(`/field/by-register`, {
                    params: {
                        register_id: typeDynamicFields === 2 ? register_id : register_parent_id,
                        withChildren: 'true'
                    }
                }).then(response => {
                    if (response.data !== null) {

                        let retFields: FieldProps[] = response.data;

                        if (retFields !== undefined) {

                            //Filter the same type of field
                            retFields = retFields.filter((fieldItem) => {

                                if (fieldItem.id_field !== field.id_field && fieldItem.flow_id !== flow_id && fieldItem.register_id !== register_id) {

                                    //Remove if is from the same form_id
                                    if (fieldItem.form_id === field.form_id) {
                                        return false;
                                    }

                                    //Remove if is from the newAnswer 
                                    if (isNewAnswer) {
                                        return false;
                                    }

                                    if (field.type === 'COMBO_BOX_REGISTER_FIELD' || field.type === 'COMBO_BOX_FLOW_FIELD') {

                                        if (fieldItem.flow_id !== null && field.flow_id !== null && fieldItem.flow_id === field.flow_id) {
                                            return true;
                                        } else if (fieldItem.register_id !== null && field.register_id !== null && fieldItem.register_id === field.register_id) {
                                            return true;
                                        } else {
                                            return false;
                                        }

                                    } else {

                                        if (fieldItem.type === 'COMBO_BOX_REGISTER_FIELD' || fieldItem.type === 'COMBO_BOX_FLOW_FIELD') {

                                            //Valid if has a field children with the same type of the field
                                            const fieldChildrenType = fieldItem.field_children?.filter((fc) => fc.type === field.type);

                                            return fieldChildrenType !== undefined && fieldChildrenType.length > 0;

                                        } else {

                                            return fieldItem.type === field.type;

                                        }

                                    }

                                } else {
                                    return false;
                                }

                            });

                            //Filter the same type of field inside the children
                            retFields = retFields.map((fieldItem) => {

                                if (fieldItem.type === 'COMBO_BOX_REGISTER_FIELD' || fieldItem.type === 'COMBO_BOX_FLOW_FIELD') {

                                    //Filter if there is a field children with the same type of the field
                                    const fieldChildrenType = fieldItem.field_children?.filter((fc) => fc.type === field.type);
                                    fieldItem.field_children = fieldChildrenType;

                                }

                                return fieldItem;
                            })

                            //If no have fields, set the complete type to static, just if is empty
                            if (retFields.length === 0 && (field.ac_type === undefined || field.ac_type === null)) {
                                setCompleteType(1)
                            }

                            retFields = sortDynamicFields(retFields);
                            setDynamicFields(retFields);

                        } else {
                            setDynamicFields([]);
                        }

                    }
                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Não foi possível carregar os campos dinâmicos disponíveis',
                        description: 'Ocorreu um erro ao carregar os campos dinâmicos disponíveis!',
                    });
                });
            }

        }

    }, [addToast, field, flow_id, register_id, isMapping, flow_parent_id, register_parent_id, isNewAnswer]);

    const onSelectDynamicField = useCallback(async (id_field: string) => {

        let fieldId: number;
        let fieldChildId: number;
        let isChild: boolean = false;

        //Check if id_field has a : or not and make the split and set into the variables
        if (id_field.includes(":")) {
            const splitField = id_field.split(":");

            fieldId = parseInt(splitField[0]);
            fieldChildId = parseInt(splitField[1]);
            isChild = true;
        } else {
            fieldId = parseInt(id_field);
        }

        //If not found, try to find inside the children
        if (fieldId !== undefined && fieldId < 0) { //Formula

            setDynamicFieldSelected({
                ac_parent_field_id: fieldId,
                ac_child_field_id: undefined
            });

            setFieldSelected(fieldId);

        } else if (isChild) {

            dynamicFields.forEach((field) => {

                if (field.id_field === fieldId) {
                    if (field.field_children !== undefined && field.field_children.length > 0) {

                        const fieldChildSelected = field.field_children.filter((fieldChild) => fieldChild.id_field === fieldChildId);

                        if (fieldChildSelected !== undefined && fieldChildSelected.length > 0) {

                            if (field.id_field !== undefined && fieldChildSelected[0].id_field !== undefined) {
                                setDynamicFieldSelected({
                                    ac_parent_field_id: field.id_field,
                                    ac_child_field_id: fieldChildSelected[0].id_field
                                });

                                setFieldSelected(fieldChildSelected[0].id_field);
                            }

                        }
                    }
                }
            });

        } else {

            setDynamicFieldSelected({
                ac_parent_field_id: fieldId,
                ac_child_field_id: undefined
            });

            setFieldSelected(fieldId);

        }

    }, [dynamicFields]);

    const handleClose = useCallback(async () => {
        onClose(false);
    }, [onClose]);

    const handleCleanRule = useCallback(async () => {

        setLoading(true);
        await api
            .post('/form/clean-auto-complete-rule', {
                id_field: field.id_field,
                form_id: field.form_id,
                flow_id: flow_id,
                register_id: register_id,
                flow_map_id: flow_parent_id !== undefined ? flow_parent_id : undefined,
                register_map_id: register_parent_id !== undefined ? register_parent_id : undefined
            })
            .then(response => {

                addToast({
                    type: 'success',
                    title: 'Limpeza de regras concluída com sucesso',
                    description: 'A regra que você decidiu limpar, foi limpa :)',
                });

                setLoading(false);

                onClose(false);
            }).catch(error => {
                const errMessage: string = error.response.data.message;
                setLoading(false);
                addToast({
                    type: 'error',
                    title: 'Erro ao limpar a regra de Auto Completar',
                    description: errMessage,
                });
            });

    }, [addToast, field, flow_id, register_id, onClose, flow_parent_id, register_parent_id]);

    const handleSubmit = useCallback(async (data: object[]) => {

        const dataNormalized = data as unknown as FormAnswerFieldApi[];

        //Valid the ac logic
        let validAcType = false;
        if (completeType === 0) {  //0 = Dynamic Field
            if (dynamicFieldSelected !== undefined) {
                validAcType = dynamicFieldSelected.ac_parent_field_id !== undefined && dynamicFieldSelected.ac_parent_field_id !== null;
            } else {
                validAcType = false;
            }
        } else if (completeType === 1) { //1 = Static Field
            validAcType = dataNormalized !== undefined;
        }

        if (!validAcType) {
            addToast({
                type: 'error',
                title: 'Verifique as informações preenchidas/selecionadas!',
                description: 'Ocorreu um erro alterar o registro, verifique as informações preenchidas/selecionadas, por favor',
            });
            return;
        }

        let apiObject = undefined;

        if (completeType === 0) { //0 = Dynamic Field

            apiObject = {
                id_field: field.id_field,
                form_id: field.form_id,
                flow_id: flow_id,
                register_id: register_id,
                ac_type: completeType,
                ac_child_field_id: dynamicFieldSelected?.ac_child_field_id !== undefined && dynamicFieldSelected?.ac_child_field_id !== null ? dynamicFieldSelected?.ac_child_field_id : undefined,
                ac_parent_field_id: dynamicFieldSelected?.ac_parent_field_id !== undefined && dynamicFieldSelected?.ac_parent_field_id !== null ? dynamicFieldSelected?.ac_parent_field_id : undefined,
                values: undefined,
                id_form_answer: undefined,
                flow_map_id: flow_parent_id !== undefined ? flow_parent_id : undefined,
                register_map_id: register_parent_id !== undefined ? register_parent_id : undefined
            }

        } else if (completeType === 1) { //1 = Static Field

            apiObject = {
                id_field: field.id_field,
                form_id: field.form_id,
                flow_id: flow_id,
                register_id: register_id,
                ac_type: completeType,
                ac_child_field_id: undefined,
                ac_parent_field_id: undefined,
                values: dataNormalized !== undefined && completeType === 1 ? dataNormalized : undefined,
                id_form_answer: field.ac_form_answer_id !== undefined && field.ac_form_answer_id !== null ? field.ac_form_answer_id : undefined,
                flow_map_id: flow_parent_id !== undefined ? flow_parent_id : undefined,
                register_map_id: register_parent_id !== undefined ? register_parent_id : undefined
            }

        }

        if (apiObject !== undefined) {
            setLoading(true);
            await api
                .post('/form/auto-complete-rule', apiObject)
                .then(response => {

                    addToast({
                        type: 'success',
                        title: 'Adicionado regra de Auto Completar',
                        description: 'Congrats! Você está tornando o seu trabalho mais eficiente :)',
                    });

                    setLoading(false);
                    handleClose();
                }).catch(error => {
                    console.log(error.reponse);
                    setLoading(false);
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar a regra de Auto Completar',
                        description: 'Ocorreu um erro alterar o registro!',
                    });
                });

        } else {
            addToast({
                type: 'error',
                title: 'Erro ao salvar a regra de Auto Completar [2]',
                description: 'Ocorreu um erro alterar o registro!',
            });
        }

    }, [addToast, field.form_id, field.id_field, flow_id, register_id, field.ac_form_answer_id, handleClose, completeType, dynamicFieldSelected, flow_parent_id, register_parent_id]);

    const handleSaveAutoComplete = useCallback(async () => {

        if (completeType === 0) {
            handleSubmit([]);
        } else if (completeType === 1) {
            if (formRef.current !== null) {
                formRef.current.submitForm();
            }
        }

    }, [completeType, handleSubmit]);

    const handleSelectTypeAutomation = useCallback(async (type: number) => {

        setSelectedConditional(undefined);

        if (type === 2 && (conditionals === undefined || conditionals.length <= 0)) {

            setTypeAutomation(2);

        } else if (type === 2) {

            setTypeAutomation(3);

        } else {
            setTypeAutomation(type);
        }

    }, [conditionals]);

    const handleBackToConditionals = useCallback(async () => {

        setTypeAutomation(3);
        getConditionalObject();

    }, [getConditionalObject]);

    const handleOpenConditional = useCallback(async (conditional: Conditional) => {

        setTypeAutomation(2);
        setSelectedConditional(conditional);

    }, []);

    const handleAddNewConditional = useCallback(async () => {

        setTypeAutomation(2);
        setSelectedConditional(undefined);

    }, []);

    useEffect(() => {

        getFlowObject(flow_id, flow_parent_id);

        getRegisterObject(register_id, register_parent_id);

        getDynamicFields();

        getFieldObject();

        getConditionalObject();

    }, [getFieldObject, getConditionalObject, getDynamicFields, getFlowObject, getRegisterObject, isMapping, flow_id, flow_parent_id, register_id, register_parent_id]);

    useEffect(() => {

        //Find the field selected and set the title to the field
        if (dynamicFieldSelected !== undefined && dynamicFieldSelected.ac_parent_field_id < 0) { //Fórmula
            if (dynamicFieldSelected.ac_parent_field_id === -1) {
                setDynamicFieldTitleSelected({
                    ac_parent_field_title: "Data atual",
                    ac_child_field_title: undefined
                });
            } else if (dynamicFieldSelected.ac_parent_field_id === -2) {
                setDynamicFieldTitleSelected({
                    ac_parent_field_title: "Usuário atual",
                    ac_child_field_title: undefined
                });
            } else if (dynamicFieldSelected.ac_parent_field_id === -3) {
                setDynamicFieldTitleSelected({
                    ac_parent_field_title: "Criado por",
                    ac_child_field_title: undefined
                });
            }
        } else if (dynamicFieldSelected !== undefined && dynamicFields !== undefined && dynamicFields.length > 0) {
            let newDynamicFieldTitleSelected: AutoCompleteDynamicFieldTitleProps = {
                ac_parent_field_title: undefined,
                ac_child_field_title: undefined
            }

            dynamicFields.forEach((field) => {
                if (field.id_field === dynamicFieldSelected.ac_parent_field_id) {
                    newDynamicFieldTitleSelected.ac_parent_field_title = field.title;
                }

                if (dynamicFieldSelected.ac_child_field_id !== undefined && field.field_children !== undefined && field.field_children.length > 0) {
                    field.field_children.forEach((fieldChild) => {
                        if (fieldChild.id_field === dynamicFieldSelected.ac_child_field_id) {
                            newDynamicFieldTitleSelected.ac_child_field_title = fieldChild.title;
                        }
                    });
                }
            });

            setDynamicFieldTitleSelected(newDynamicFieldTitleSelected);
        } else {
            setDynamicFieldTitleSelected(undefined);
        }

    }, [dynamicFields, dynamicFieldSelected]);

    return (
        <DialogContainer
            id="auto-complete-dialog"
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
            style={{ zIndex: '999999' }}
        >
            <DialogTop>
                <DialogTopLeft>
                    <IconHeader color={"#f23b5d"}>
                        {typeAutomation === undefined ?
                            <div>
                                <BsLightningChargeFill />
                            </div> :
                            <div
                                onClick={() => {

                                    if (typeAutomation === 1) { //AutoComplete

                                        setTypeAutomation(undefined);

                                    } else if (typeAutomation === 2) { //Conditional

                                        if (conditionals !== undefined && conditionals.length > 0) {
                                            setTypeAutomation(3);
                                        } else {
                                            setTypeAutomation(undefined);
                                        }

                                    } else if (typeAutomation === 3) { //Conditional List

                                        setTypeAutomation(undefined);

                                    }

                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                <TiArrowLeftThick />
                            </div>
                        }
                    </IconHeader>
                    <h1>
                        {typeAutomation === 1 ? "Auto Completar" : typeAutomation === 2 ? "Regra Condicional" : typeAutomation === 3 ? "Regras Condicionais" : "Automações de Campos"}
                    </h1>
                    <SpanField>
                        {field.title}
                    </SpanField>
                </DialogTopLeft>
                <DialogTopRight>
                    <button onClick={(e) => {
                        e.stopPropagation()
                        handleClose()
                    }}><AiOutlineClose /></button>
                </DialogTopRight>
            </DialogTop>

            <Content style={{ padding: '0px' }}>
                <ContentBody>

                    {typeAutomation === undefined ?
                        <WelcomePageFieldAutomation
                            setTypeAutomation={handleSelectTypeAutomation}
                            hideAutoComplete={hideAutoComplete}
                            isAutoCompleteActive={isActive}
                            isConditionalActive={conditionals !== undefined && conditionals.length > 0}
                        /> : typeAutomation === 2 ?
                            <AddConditionalField
                                flow={flow}
                                field={field}
                                handleBackToConditionals={handleBackToConditionals}
                                selectedConditional={selectedConditional}
                            /> : typeAutomation === 3 ?
                                <ConditionalField
                                    conditionals={conditionals}
                                    handleAddNewConditional={handleAddNewConditional}
                                    handleOpenConditional={handleOpenConditional}
                                    field={field}
                                    flow_id={flow_id}
                                /> :
                                <ContainerAutoComplete>
                                    {isMapping ?
                                        <MappingFlow>
                                            <MappingFlowTitle>
                                                Mapeamento
                                            </MappingFlowTitle>
                                            <MappingFlowItemOrigin>
                                                {flowParent !== undefined ? flowParent.name : registerParent !== undefined ? registerParent.name : ""}
                                            </MappingFlowItemOrigin>
                                            <MappingFlowIcon>
                                                <BiRightArrowAlt />
                                            </MappingFlowIcon>
                                            <MappingFlowItemTarget>
                                                {flow !== undefined ? flow.name : register !== undefined ? register.name : ""}
                                            </MappingFlowItemTarget>
                                        </MappingFlow> :

                                        <HelpContainer>
                                            <HelpItems>
                                                <HelpItem>
                                                    <HelpIcon>
                                                        <RiLightbulbFlashFill />
                                                    </HelpIcon>
                                                    <HelpText>
                                                        <b>{"Dica: "}</b>
                                                        {"Preencha abaixo as informações que você deseja que o Cange " +
                                                            "insira para você atuomaticamente toda a vez que você for adicionar uma " +
                                                            "nova resposta para o campo "}
                                                        <b>{field.title}</b>
                                                    </HelpText>
                                                </HelpItem>
                                            </HelpItems>
                                        </HelpContainer>
                                    }

                                    <SubHeaderLeft>
                                        <ButtonGroupLeft active={completeType === 0} onClick={() => setCompleteType(0)}>
                                            Valor dinâmico
                                        </ButtonGroupLeft>
                                        <ButtonGroupRight active={completeType === 1} onClick={() => setCompleteType(1)}>
                                            Valor fixo
                                        </ButtonGroupRight>
                                    </SubHeaderLeft>


                                    {completeType === 0 ?
                                        <DynamicFieldContainer>

                                            <DynamicFieldForm>
                                                <LabelInput>
                                                    Campos do fluxo
                                                </LabelInput>
                                                <SelectInput value={fieldSelected} onChange={(e) => onSelectDynamicField(e.currentTarget.value)}>
                                                    <OptionItem disabled key={0} value={0}>Selecione um campo...</OptionItem>
                                                    {dynamicFields.length > 0 && (
                                                        <>
                                                            {dynamicFields.filter((f) => {

                                                                if (field.type === 'COMBO_BOX_REGISTER_FIELD') {
                                                                    if (f.type === field.type && f.register_id === field.register_id) {
                                                                        return true;
                                                                    } else {
                                                                        return false;
                                                                    }
                                                                } else if (field.type === 'COMBO_BOX_FLOW_FIELD') {
                                                                    if (f.type === field.type && f.flow_id === field.flow_id) {
                                                                        return true;
                                                                    } else {
                                                                        return false;
                                                                    }
                                                                } else if (f.field_children !== undefined && f.field_children.length > 0) {
                                                                    return false;
                                                                } else {
                                                                    return true;
                                                                }

                                                            }).length > 0 && (
                                                                    <optgroup label="Campos do fluxo atual">
                                                                        {dynamicFields.filter((f) => {

                                                                            if (field.type === 'COMBO_BOX_REGISTER_FIELD' || field.type === 'COMBO_BOX_FLOW_FIELD') {
                                                                                if (f.type === field.type) {
                                                                                    return true;
                                                                                } else {
                                                                                    return false;
                                                                                }
                                                                            } else if (f.field_children !== undefined && f.field_children.length > 0) {
                                                                                return false;
                                                                            } else {
                                                                                return true;
                                                                            }

                                                                        }).map((field) => {
                                                                            return (
                                                                                <OptionItem key={field.id_field} value={field.id_field}>{field.title}</OptionItem>
                                                                            )
                                                                        })}
                                                                    </optgroup>
                                                                )
                                                            }

                                                            {dynamicFields.filter((field) => field.field_children !== undefined && field.field_children.length > 0).map((field) => {
                                                                return (
                                                                    <optgroup label={field.title}>
                                                                        {field.field_children?.map((fieldChild) => {

                                                                            const key = field.id_field + ":" + fieldChild.id_field;

                                                                            return (
                                                                                <OptionItem key={key} value={key}>{fieldChild.title}</OptionItem>
                                                                            )
                                                                        })}
                                                                    </optgroup>
                                                                )
                                                            })}
                                                        </>
                                                    )}

                                                    {field.type === 'DUE_DATE_FIELD' || field.type === 'DATE_PICKER_FIELD' ?

                                                        <optgroup label="Fórmulas">
                                                            <OptionItem key={-1} value={-1}>{"Data Atual"}</OptionItem>
                                                        </optgroup> :

                                                        field.type === 'COMBO_BOX_USER_FIELD' ?
                                                            <optgroup label="Fórmulas">
                                                                <OptionItem key={-2} value={-2}>{"Usuário Atual"}</OptionItem>
                                                                <OptionItem key={-3} value={-3}>{"Criado por"}</OptionItem>
                                                            </optgroup> :
                                                            <></>
                                                    }
                                                </SelectInput>
                                            </DynamicFieldForm>

                                        </DynamicFieldContainer> :
                                        <FormBuilder
                                            id="formEdit"
                                            formRef={formRef}
                                            fields={[field]}
                                            handleSubmit={handleSubmit}
                                            initialValue={initValue}
                                            hideAutoComplete={true}
                                            typeUser={"A"}
                                        />
                                    }
                                </ContainerAutoComplete>
                    }

                </ContentBody>
            </Content>

            {typeAutomation === 1 && ((completeType === 0 && dynamicFieldSelected !== undefined && dynamicFieldTitleSelected !== undefined) ||
                (completeType === 1)) ?
                <DialogResume>
                    <TitleResume>
                        Resumo da sua escolha
                    </TitleResume>

                    <ResumeDescription>
                        Auto completar o campo <b>{field.title}</b>

                        {isMapping && flow !== undefined ?
                            <> do fluxo <b>{flow?.name}</b> </> :
                            isMapping && register !== undefined ?
                                <> do cadastro <b>{register?.name}</b> </> :
                                <></>
                        }

                        {completeType === 0 ?
                            dynamicFieldSelected?.ac_parent_field_id !== undefined && dynamicFieldSelected.ac_parent_field_id < 0 ?
                                <> com a informação gerada pela fórmula de <b>{dynamicFieldTitleSelected?.ac_parent_field_title}</b></> :
                                dynamicFieldSelected?.ac_child_field_id !== undefined && dynamicFieldSelected?.ac_child_field_id !== null ?
                                    <> com a informação contida no campo <b>{dynamicFieldTitleSelected?.ac_child_field_title}</b> do cadastro <b>{dynamicFieldTitleSelected?.ac_parent_field_title}</b> </> :
                                    isMapping && flowParent !== undefined ?
                                        <> com a informação contida no campo <b>{dynamicFieldTitleSelected?.ac_parent_field_title}</b> do fluxo <b>{flowParent?.name}</b></> :
                                        isMapping && registerParent !== undefined ?
                                            <> com a informação contida no campo <b>{dynamicFieldTitleSelected?.ac_parent_field_title}</b> do cadastro <b>{registerParent?.name}</b></> :
                                            <> com a informação contida no campo <b>{dynamicFieldTitleSelected?.ac_parent_field_title}</b> </> :
                            <> com a informação <b> inserida no campo acima</b></>
                        }
                    </ResumeDescription>
                </DialogResume> :
                <></>
            }

            {typeAutomation === 1 ?
                <DialogFooter>
                    <DialogFooterLeft>
                        {isActive ?
                            <ButtonEmptyState color="#4680b8" style={{ width: '140px' }} onClick={() => handleCleanRule()} isLoading={loading}>Limpar regra</ButtonEmptyState> :
                            <></>
                        }
                    </DialogFooterLeft>
                    <DialogFooterRight>

                        <ButtonEmptyState onClick={() => handleSaveAutoComplete()} isLoading={loading}>Salvar</ButtonEmptyState>

                    </DialogFooterRight>
                </DialogFooter > :
                <></>}
        </DialogContainer >
    );

}

export default AutomationField;